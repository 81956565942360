import { v4 as uuidv4 } from 'uuid';
export class SurveyApiDto{
  id : string = uuidv4();
  name: string  = "";
  description: string  = "";
  url: string  = "";
  property: string  = "";
  payload: string  = "";
  globalVars: string  = "";
  customVars: string  = "";
  headers: string  = "";
  testData: string  = "";
  clientId: string  = "";
  method: "POST"|"GET"  = "GET";
  isNew: boolean  = true;
  response?: string  = "";  //AÑADIDO PARA PROBAR API.
  callResult?: string  = ""; //AÑADIDO PARA PROBAR API.
}
export class SurveyApiListItemDto{
  id: string = "";
  name: string = "";
  description: string = "";
  url: string = "";
  method: string = "";
}

export class SurveyApiParms{
    url: string;
    method: "POST"|"GET"  = "GET";
    property: string;
    globals: {var: string, description: string, value: string}[] = [];
    custom:  {var: string, description: string, value: string }[] = [];
    header:  {var: string, value: string }[] = [];
}

export class ResultTest{
  returnText: string; 
  value: string;
  url: string;
}