import { default } from './surveyItem.vue';
<script setup lang="ts">
  import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
  import { SurveyResponseItemDto, SurveyResponseValueFile } from '@/core/store/survey/surveyResponseDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import { computed, CSSProperties, PropType, Ref, ref, watch } from 'vue';

import { Sh } from '@/core/sharedService';
import { apiRoutes } from '@/core/apiRouters';
  const {
      displayMode, 
      isDesign,
      } = Survey();

   const props  = defineProps({
    item: {
      type: Object as () => FormDesignItemDto,
      required: true
    },
    response: {
      type: Object as () => SurveyResponseItemDto,
      default: null,
      required: false
    },
    onChange: {
      type: Function as PropType<( value: any) => void>,
      default: null,
      required: false,
    },
  });
  const fileRef: Ref<HTMLInputElement | null> = ref(null);
  const currentStyles = computed<PropertyDisplayType>(()=>{
      switch(displayMode.value){
        case DisplayType.DISPLAY: 
          return  props.item.properties.screen;
        case DisplayType.EDIT: 
          return  props.item.properties.input;
        case DisplayType.PDF: 
          return  props.item.properties.print;
      }
  });

  const cssInput = computed<CSSProperties>(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return {
      backgroundColor:  displayProp.contentStyle.backgroundColor,
      borderRadius: '9999px',
      border: 'none',
      fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
      color: displayProp.contentStyle.color,
      width: '100%'
    }
  });

  const value = ref<SurveyResponseValueFile>(props.response  && props.response.value ? props.response.value : new SurveyResponseValueFile());
  
  watch(value, (newValue: SurveyResponseValueFile) => {
    if(props.response){
      if(JSON.stringify(newValue.toJSON()) !== JSON.stringify((<SurveyResponseValueFile>props.response.value).toJSON())){
        props.response.value = newValue;
        props.onChange(newValue);
      }
    }
  });

  const isCurrentVisible = computed(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return displayProp.visible || isDesign;
  });

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file)
    try{
      const result = await Sh.api<{id: string, url: string, originalName: string}>({
      method: 'POST',
      route: apiRoutes.files,
      data:formData, 
      type: "multipart"
    });
    const fileData = new SurveyResponseValueFile();
    fileData.id = result.id;
    fileData.originalName = result.originalName;
    fileData.url = result.url;
    value.value = fileData;
    }catch(e){
      console.log("ERror", e)
    }
    
  }
  const loadFile = () => {
    fileRef.value.click();
  }
  const changeFile =($event: Event) =>{
    const target = $event.target as HTMLInputElement;
    const file = target.files?.[0];
    // SOLO SI EL ARCHIVO EXISTE Y TIENE UN MAX DE 10Mb exactos.
    if(file && file.size > 10485760){
      Sh.showToast("Archivo demasiado grande, máximo 10Mb", 10)
      return;
    }
    
    if(file){
      uploadFile(file);
    }
  }
  const deleteFile = () => {
    value.value = new SurveyResponseValueFile();
  }
</script>
<template>
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible ">
    <input type="file" :hidden="true" ref="fileRef" @change="($event ) => changeFile($event)" :readonly="item.inheritItemId!=null && item.inheritItemId.trim().length!=0">
    <button class="btn-green-sm mR30px" v-if="!value.url" v-on:click="loadFile" :disabled="item.inheritItemId!=null && item.inheritItemId.trim().length!=0">Examinar...</button>
    <label  v-if="!value.url"> Adjuntar fichero</label>
    <a target="_blank" v-if="value.url" :href="value.url">{{ value.originalName }}</a>
    <img v-if="value.url" class="button-component-icon" v-on:click="deleteFile" src="/assets/trash.svg"/>
  </div>  
  <div v-if="displayMode == DisplayType.DISPLAY  && isCurrentVisible" >
    <a target="_blank" :href="value.url">{{ isDesign ? "fichero subido.pdf" :  value.originalName }}</a>
  </div>  
  <div v-if="displayMode == DisplayType.PDF  && isCurrentVisible">
    <a target="_blank" :href="value.url">{{ isDesign ? "fichero subido.pdf" :  value.originalName }}</a>
  </div>  
</template>

<style lang="scss">
  @import url(../survey.scss);
</style>

<script lang="ts">
  export default {
    name: "SurveyItemFile"
  }
</script>