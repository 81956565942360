import { default } from './surveyItem.vue';
<script setup lang="ts">
import { Sh } from '@/core/sharedService';
import { DisplayType, FieldType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
import Survey from '@/core/store/survey/surveyStore';
import { id } from 'bootstrap-vue-next';
import { computed, CSSProperties, PropType, ref, toRef, toRefs, watch } from 'vue';
const {
  displayMode,
  isDesign,
  currentDesign,
} = Survey();

const props = defineProps({
  item: {
    type: Object as () => FormDesignItemDto,
    required: true
  },
  response: {
    type: Object as () => SurveyResponseItemDto,
    default: null,
    required: false
  },
  onChange: {
    type: Function as PropType<(value: any) => void>,
    default: null,
    required: false,
  },
});
const { item } = toRefs(props);
const currentStyles = computed<PropertyDisplayType>(() => {
  switch (displayMode.value) {
    case DisplayType.DISPLAY:
      return props.item.properties.screen;
    case DisplayType.EDIT:
      return props.item.properties.input;
    case DisplayType.PDF:
      return props.item.properties.print;
  }
});

const cssInput = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return {
    backgroundColor: displayProp.contentStyle.backgroundColor,
    borderRadius: '9999px',
    border: 'none',
    fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
    color: displayProp.contentStyle.color,
    width: isDesign.value && displayMode.value === DisplayType.EDIT ? '344px' : '100%',
  }
});

const cssSuffix = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return {
    color: displayProp.contentStyle.color,
    fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize), 
  };
});


const value = ref<number>(props.response && props.response.value ? props.response.value : parseFloat("0"));
if (props.item.type == FieldType.FORMULE && !isDesign.value) {
  watch(currentDesign, (newValue, oldValue) => {
    const it = newValue.items.find(i => i.id == props.item.id);
    if (it.response.value !== value.value) value.value = it.response.value;
  })
}
watch(value, (newValue) => {
  if (props.response) {
    if (newValue !== props.response.value) {
      props.response.value = newValue;
      props.onChange(newValue);
    }
  }
});

const isCurrentVisible = computed(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return displayProp.visible || isDesign;
});

</script>

<template>
  <!-- Edit Mode -->
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible" class="input-container">
    <input type="number" class="survey-item-input" :style="cssInput" v-model="value"
      :readonly="(item.inheritItemId != null && item.inheritItemId.trim().length != 0) || item.type == FieldType.FORMULE">
    <span v-if="item.sufix" class="input-suffix" :style="cssSuffix">{{ item.sufix }}</span>
  </div>

  <!-- Display Mode with Suffix inside the input -->
  <div v-if="displayMode == DisplayType.DISPLAY && isCurrentVisible" class="input-container">
    <input type="number" :style="cssInput" v-model="value" class="survey-item-input survey-item-input-disabled" readonly
      tabindex="-1">
    <span v-if="item.sufix" class="input-suffix" :style="cssSuffix">{{ item.sufix }}</span>
  </div>

  <!-- PDF Mode with Suffix inside the input -->
  <div v-if="displayMode == DisplayType.PDF && isCurrentVisible" class="input-container">
    <input type="number" :style="cssInput" v-model="value" class="survey-item-input survey-item-input-disabled" readonly
      tabindex="-1">
    <span v-if="item.sufix" class="input-suffix" :style="cssSuffix">{{ item.sufix }}</span>
  </div>
</template>




<style lang="scss">
@import url(../survey.scss);

.input-container {
  position: relative;
  // display: inline-block;
}

.survey-item-input {
  padding-right: 2rem;
  width: 100%;
}

.input-suffix {
  position: absolute;
  right: 10px;
  top: 28%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #555;
  pointer-events: none;
}

</style>

<script lang="ts">
export default {
  name: "SurveyItemNumber"
}
</script>