<template >
  <DPDialog :zIndex="'9999999999999'" v-if="Sh.genericDialogVisible" :show="Sh.genericDialogVisible.value" :closeLostFocus="true" :onClose="close">
    <div class="generic-dialog-container">
      <div class="generic-dialog-header">
        <h4 v-if="Sh.genericDialogOptions.value && Sh.genericDialogOptions.value.title">
          {{ Sh.genericDialogOptions.value.title }}
        </h4>
      </div>
      <hr v-if="Sh.genericDialogOptions.value && Sh.genericDialogOptions.value.title" />
      <span v-if="Sh.genericDialogOptions.value && Sh.genericDialogOptions.value.message" v-html="Sh.genericDialogOptions.value.message">
      </span>
      <div v-if="!Sh.genericDialogOptions.value.confirmOnly" class="generic-dialog-buttons">
        <button class="btn-orange" v-on:click="confirm">
          {{ Sh.genericDialogOptions.value.confirmText || 'Aceptar' }}
        </button>
        <button class="btn-green" v-on:click="close">
          {{ Sh.genericDialogOptions.value.cancelText || 'Cancelar' }}
        </button>
      </div>
      <div v-else class="generic-dialog-buttons">
        <button class="btn-orange" v-on:click="confirm" style="margin: 0 auto">
          {{ Sh.genericDialogOptions.value.confirmText || 'Aceptar' }}
        </button>
      </div>
    </div>
  </DPDialog>
</template>

<script setup lang="ts">
import DPDialog from '../dialog.vue';
import { Sh } from '@/core/sharedService';

const close = () => {
  if (Sh.genericDialogOptions.value && Sh.genericDialogOptions.value.onCancel) {
    Sh.genericDialogOptions.value.onCancel();
  }
  Sh.closeGenericDialog(false);
}
const confirm = () => {
  if (Sh.genericDialogOptions.value && Sh.genericDialogOptions.value.onConfirm) {
    Sh.genericDialogOptions.value.onConfirm();
  }
  Sh.closeGenericDialog(true);
}


</script>

<style scoped>
.generic-dialog-container {
  background-color: var(--compositor-color);
  padding: 25px;
  border-radius: 20px;
  width: 400px;
  max-height: 300px;
  align-items: center;
  justify-self: center;
  text-align: center;
}

.generic-dialog-header h4 {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--compositor-text);
  margin-bottom: 10px;
}

.generic-dialog-container span {
  font-size: 15px;
  color: var(--compositor-text);
}

.generic-dialog-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

hr {
  height: 2px;
  color: var(--primary-color);
  margin-bottom: 20px;
}
</style>

<script lang="ts">
export default {
  name: "DPGenericDialog"
}
</script>
