<script setup lang="ts">
import { Sh } from '@/core/sharedService';
import { SurveyApiParms } from '@/core/store/survey/surveyApiDtos';
import { DisplayType, FieldType, FormDesignItemDto, surveyButtonsLeftBar } from '@/core/store/survey/surveyDesignDtos';
import Survey from '@/core/store/survey/surveyStore';
import { storeToRefs } from 'pinia';
import { computed, CSSProperties, ref, watch } from 'vue';

const props = defineProps({
  item: {
    type: Object as () => FormDesignItemDto,
    required: true
  }
});

const {
  displayMode,
  selectedItemId,
  currentDesign,
  isDesign,
  state,
  moveTo,
  lasItemGroup,
  deleteItem,
  selectItem,
  getGroupOfItem,
} = Survey();

const { setErrors, removeErrors } = state;
const { errors } = storeToRefs(state);

// computed properties:
const icon = computed(() => {
  return surveyButtonsLeftBar.find(b => b.type == props.item.type).icon;
})
const isSelected = computed(() => props.item.id == selectedItemId.value)
watch(isDesign, (newValue) => {
  let clss: string[] = ["survey-base-content"];
  if (newValue) clss.push("survey-base-content-design");
  return classContent.value = clss.join(" ");
})
const classComponent = computed<string>(() => {
  let clss: string[] = ["survey-base-component"];
  if (isDesign.value) clss.push("survey-base-design-mode");
  if (isSelected.value && isDesign.value) clss.push("survey-base-selected-item");
  return clss.join(" ");
})
const classContent = ref<string>("survey-base-content");
const contentStyle = computed<CSSProperties>(() => {
  const curConfig = currentDesign.value.cab.config;
  return {
    marginLeft: (curConfig.marginLeft ?? 0) + "px",
    marginRight: (curConfig.marginRight ?? 0) + "px",
  }
})

const isFirstItem = computed(() => props.item.order === 1);
const isLastItem = computed(() => {
  const lastOrder = lasItemGroup(getGroupOfItem(props.item));
  return props.item.order === lastOrder;
})

const classForBorderRadius = computed(() => {
  let classes = [];
  if (isFirstItem.value) classes.push('first-item');
  if (isLastItem.value) classes.push('last-item');
  return classes.join(' ');
});
const error = computed<string | null>(() => {
  if (isDesign.value) {
    const e = errors.value.find(e => e.itemId === props.item.id);
    if (e) return e.message;
    return null;
  }
  return null;
})

// methods:
const select = () => {
  if (!isDesign.value) return;
  selectItem(props.item.id);
}
watch(selectedItemId, (newValue, oldValue) => {
  if (oldValue == props.item.id && newValue !== props.item.id) {
    checkError();
  }
});

const itemErrors = computed<string[]>(() => {
  if (isDesign.value) {
    return errors.value.filter(e => e.itemId === props.item.id).map(e => e.message);
  }
  return [];
});


const checkError = () => {
  const item = props.item;
  let messages: string[] = [];

  // Chequeo para label vacío
  if (![FieldType.GRAPHIC, FieldType.NEWPAGE, FieldType.HTML].includes(item.type) &&
    (!item.label || item.label.trim().length === 0)) {
    messages.push("El item debe llevar una etiqueta válida");
  }

  switch (item.type) {
    case FieldType.HTML:
    case FieldType.FORMULE:
      if (!item.content || item.content.trim().length == 0) {
        messages.push("El componente debe tener contenido");
      }
      break;
    case FieldType.DROPDOWN:
    case FieldType.MULTYDROPDOWN:
    case FieldType.GRID:
      if (!item.items || item.items.length == 0) {
        messages.push("Debe añadir al menos un item");
      }
      if (item.type == FieldType.GRID && !item.itemsType) {
        messages.push("Debe indicar el tipo de datos de los items");
      }
      break;
    case FieldType.GRAPHIC:
      if (!item.content || item.content.trim().length == 0) {
        messages.push("Debe seleccionar una imagen");
      }
      break;
    case FieldType.APIREST:
      if (item.content && item.content.trim().length != 0) {
        const params: SurveyApiParms = JSON.parse(item.content);
        let isOk = true;
        for (const p of params.custom) {
          if (Sh.isNullOrEmptyOrZero(p.value)) {
            isOk = false;
            break;
          }
        }
        if (!isOk) {
          messages.push("Debe cumplimentar los parámetros necesarios");
        }
      }
      break;
      case FieldType.FORMREF:
        if (!item.inheritItemId) {
          messages.push("Debe añadir una referencia de formulario válida");
        }
      break;
  }

  if (messages.length > 0) {
    setErrors(item.id, messages);
  } else {
    removeErrors(item.id);
  }
}

/**
 *  Mueva arriba o abajo el componente dentro de su grupo
 * @param direction "up"|"down"
 */
const _moveTo = (direction: string) => {
  if (direction == "up") {
    if (props.item.order == 1) {
      return;
    }
    moveTo(props.item, props.item.order - 1);
    return;
  }

  if (direction == "down") {
    const last = lasItemGroup(getGroupOfItem(props.item));
    if (props.item.order == last) {
      return;
    }
    moveTo(props.item, props.item.order + 1);
  }
}

const _deleteItem = () => {
  deleteItem(props.item);
}
</script>

<template>
  <div class="sectionContentContainer" v-on:click="select()" :class="[classComponent, classForBorderRadius]">
    <div v-if="isDesign && displayMode == DisplayType.EDIT" class="survey-base-edit-button-item-left">
      <img class="button-component-icon" :src="icon" />
      <img class="button-component-icon" v-on:click.stop="_deleteItem()" src="/assets/trash.svg" />
    </div>

    <div :class="classContent" :style="contentStyle">
      <slot></slot>
      <div class="itemTagContainer" v-if="isDesign && displayMode == DisplayType.EDIT">
        <div class="xtraInfoContainer">
          <span class="itemTag">{{ item.tag }}</span>
          <div class="errorContainer">
            <span class="errorSpan" v-for="error in errors.filter(e => e.itemId === item.id)" :key="error.message">
              {{ error.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDesign && displayMode == DisplayType.EDIT" class="survey-base-edit-button-item-right">
      <img class="survey-base-icons" :class="{ disabled: isFirstItem }" v-on:click="_moveTo('up')"
        src="/assets/dark_up_button.svg" width="28" />
      <img class="survey-base-icons" :class="{ disabled: isLastItem }" v-on:click="_moveTo('down')"
        src="/assets/dark_down_button.svg" width="28" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url(../survey.scss);

.itemTagContainer {
  margin-top: 10px;
}

.itemTag {
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-color);
  opacity: 0.4;
}

.itemTag:hover {
  opacity: 1;
}

.xtraInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.errorSpan {
  background-color: var(--red);
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  animation: blinkAnimation 1.2s infinite;
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.first-item {
  border-top-left-radius: -10px;
  border-top-right-radius: -10px;
}

.last-item {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>

<script lang="ts">
export default {
  name: 'SurveyItemBase'
}
</script>
