import { SharedService } from "@/core/sharedService";
import { Item_type } from "@/core/store/forms/dtos";

export enum typesItem {
  input= "input",
  label="label",
  number="number",
  text="text",
  check="check",
  date="date",
  hr="hr",
  image="image",
  h2="h2",
  h5="h5",
  fieldForm="fieldForm",
  selector="selector",

}
export enum errorItems{
  NONE="NONE",
  WARNING="WARNING",
  ERROR="ERROR"
}
export interface FormItem {
  field?: string;
  type: typesItem;
  label: string;
  maxText?: number;
  error?: errorItems;
  message?: string;
  hidden?: string;
  hasError?: boolean;
  hasWarning?:boolean;
  extraValid?: boolean;
  getItems?: () => {id: string, title: string}[];

}
type FormItems = {
  [key in Item_type]: FormItem[];
};
export const FieldsItems:FormItems = {
  [Item_type.FORM_COMBOBOX]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "value", type: typesItem.text, label: "Lista de selección (introduzca los textos separados por coma)", error: errorItems.ERROR, message: "Debe introudir elementos en la lista"},
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
  ],
  [Item_type.FORM_COMBOBOX_MULTIPLE]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "value", type: typesItem.text, label: "Lista de selección (introduzca los textos  separados por coma)", error: errorItems.ERROR, message: "Debe introudir elementos en la lista"},
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
  ],
  [Item_type.FORM_DATE]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "required", type: typesItem.check, label: "Requerido", error: errorItems.NONE},
      {field: "weightEmpty", type: typesItem.number, label: "Peso vacio", hidden: "required", error: errorItems.WARNING, message: "si no se introduce peso, tampoco tendrá un valor por defecto"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],
    [Item_type.FORM_DATE_RANGE]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "required", type: typesItem.check, label: "Requerido", error: errorItems.NONE},
      {field: "weightEmpty", type: typesItem.number, label: "Peso vacio", hidden: "required", error: errorItems.WARNING, message: "si no se introduce peso, tampoco tendrá un valor por defecto"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],
    [Item_type.FORM_END]: [
      {type: typesItem.hr, label: "fin de formulario", error: errorItems.NONE},
    ],
    [Item_type.FORM_START]: [
      {type: typesItem.hr, label: "Inicio de formulario", error: errorItems.NONE},
    ],
    [Item_type.FORM_FINHAVA]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
      {field: "finhavaMetric", type: typesItem.selector, label: "Métrica:", error: errorItems.NONE, getItems: () =>{
        return null;
        return SharedService.getMetricsFinhava()} },
      {field: "finhavaYear", type: typesItem.number, label: "Año", error: errorItems.ERROR, message: "El año es obligatorio"}, 
      {field: "finhavaMonth", type: typesItem.number, label: "Mes", error: errorItems.ERROR, message: "El mes es obligatorio"}, 
      {field: "required", type: typesItem.check, label: "Visible", error: errorItems.NONE},

    ],
    [Item_type.FORM_FORMULE]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "value", type: typesItem.input, label: "Formula", error: errorItems.ERROR, message: "Fórmula obligatoria", extraValid: true},
      {field: "required", type: typesItem.check, label: "Visible", error: errorItems.NONE},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],
    [Item_type.FORM_NUMBER]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "required", type: typesItem.check, label: "Requerido", error: errorItems.NONE},
      {field: "weightEmpty", type: typesItem.number, label: "Peso vacio", hidden: "required", error: errorItems.WARNING, message: "si no se introduce peso, tampoco tendrá un valor por defecto"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],
    [Item_type.FORM_TEXT]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "required", type: typesItem.check, label: "Requerido", error: errorItems.NONE},
      {field: "weightEmpty", type: typesItem.number, label: "Peso vacio", hidden: "required", error: errorItems.WARNING, message: "si no se introduce peso, tampoco tendrá un valor por defecto"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],      
    [Item_type.FORM_YES_NO]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "weight", type: typesItem.number, label: "Peso SI", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "weightEmpty", type: typesItem.number, label: "Peso NO", hidden: "required", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],   
    [Item_type.FROM_IMAGE]: [
      {field: "label", type: typesItem.text,  maxText: 1900,label: "Etiqueta", error: errorItems.ERROR, message: "Etiqueta obligatoria"},  
      {field: "weight", type: typesItem.number, label: "Peso", error: errorItems.WARNING, message: "Va a dejar el peso a 0"},
      {field: "required", type: typesItem.check, label: "Requerido", error: errorItems.NONE},
      {field: "weightEmpty", type: typesItem.number, label: "Peso vacio", hidden: "required", error: errorItems.WARNING, message: "si no se introduce peso, tampoco tendrá un valor por defecto"},
      {field: "varNameId", type: typesItem.fieldForm, label: "Campo:", error: errorItems.NONE},
    ],   
    [Item_type.STATIC_IMAGE]: [
      {field: "value", type: typesItem.image, label: "Seleccione una imagen", error: errorItems.ERROR, message: "Para el tipo imagen estática debe proporcinar una imagen"},
      {field: "weight", type: typesItem.number, label: "Ancho de la imagen", error: errorItems.ERROR, message: "Indique el ancho de la imagen en px"},
      {field: "weightEmpty", type: typesItem.number, label: "Posición (1 izquierda, 2 central, 3 derecha)", error: errorItems.ERROR, message: "debe indicar la posición"},
    ],   
    
    [Item_type.STATIC_SUBTITLE]: [
      {field: "value", type: typesItem.input, label: "Subtitulo", error: errorItems.ERROR,message: "El texto del subtítulo es obligatorio"},  
    ],   
    [Item_type.STATIC_TITLE]: [
      {field: "value", type: typesItem.input, label: "Título", error: errorItems.WARNING,message: "El texto del título es obligatorio"},  

    ],   
    [Item_type.STATIC_TEXT]: [
      {field: "value", type: typesItem.text, label: "Título", error: errorItems.WARNING,message: "Debe indicar un texto"},  
      {field: "required", type: typesItem.check, label: "Visible solo al imprimir", error: errorItems.NONE},
    ],
}



