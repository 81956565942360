import { Sh } from '@/core/sharedService';
import { LocalStorageService } from '@/core/localStorage.service';
import { roles } from '@/shared/globaEnums';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { LoginResponseDto, UserListItemDto } from './dtos';
import { User } from '../entities/dtos';

const initialState: LoginResponseDto = {
  token: "",
  id : "",
  email: "",
  name: "",
  surname1: "",
  entityId: "",
  entityTitle: "",
  groupId: "",
  groupTitle: "",
  clientId: "",
  clientTitle: "",
  role: roles.USER,
}

export const UserStore = defineStore('user', () =>{
  const local = LocalStorageService.getValue("user");
  if(local){
    Sh.token = local.token;
  }
  const user = ref<LoginResponseDto>(!local ? {...initialState}: {...local});
  const userList = ref<UserListItemDto[]>([]);
  const error = ref<string>("");
  const errors = ref<string[]>([])
  const loged = ref<boolean>(user.value.id.trim().length!==0);
  const userEditing = ref<User>(null);

  return {
    // Properties
    userList,
    user,
    error,
    errors,
    loged,
    userEditing,
      // Actions
    login(userLogin: LoginResponseDto){
      user.value = userLogin;
      error.value = "";  
      loged.value = true;
    },
    errorLogin(err: string){
      error.value = err;
      loged.value = false;
    }, 
    logout(){
      user.value = {...initialState};
      error.value = "";
      loged.value = false;
    }, 
    setListUser(users: UserListItemDto[]){
      userList.value = users;
    },
    reset(){
      user.value = {...initialState} ;
      userList.value = [];
      error.value = "";
      loged.value = false;
    },
      
    
  }

}, {persist: true});