import { defineStore, type _UnwrapAll, type Store } from 'pinia';
import { computed, ref, type Ref } from 'vue';
import router from '@/router/index';
import { GlobalState, type Context } from '../global/gobalState';
import useUsers from '../user/useUsers';
import { entityTypes, roles } from '@/shared/globaEnums';
import { Sh } from '@/core/sharedService';


export interface RouteTreeItem{
  route: string,
  context: Context
}

export const RouterEstate = defineStore("router", () => {
  const {treeContext} = GlobalState();
  const {user} = useUsers();
  const route = ref<RouteTreeItem[]>([]);
  const currentComponent = computed(() => {
    const { matched } = router.currentRoute.value;
    if (matched.length > 0) {
      return matched[matched.length - 1].components.default.name;
    }
    return '';
  });
  
  /* const breadCrumb = computed(() => {
    const role = user.value.role;
    const crumbs: {type: entityTypes, name: string}[] = [];
      let curContex = treeContext;
      
      do{
        if(curContex.entity && curContex.entity.id.trim().length){
          crumbs.push({type: <entityTypes>curContex.entity.entityType, name: curContex.entity.name});
        }
        
        curContex = curContex.child ? curContex.child : null;
      }while(curContex && curContex.child)
      if(crumbs.length){
        let breadC =  crumbs.map((c) => {
          let bread: string;
          switch(c.type){
            case entityTypes.CLIENT:
              bread = "Entorno: ";
              break;
            case entityTypes.GROUP:
              bread = "Empresa-Grupo: ";
              break;
            case entityTypes.ENTITY:
              bread = "Entidad: ";
          }
          bread+= c.name;
          return bread;
        }).join(" / ");
        if(role=== roles.ROOT){
          breadC = "Root / " + breadC;
        }
        return breadC
      }
        
    
    return ""
  }); */
  const removeRoute = (routePath: string) => {
    if(route.value.length===0 ) return;
    const idx = route.value.findIndex(r => r.route.trim().toUpperCase() === routePath.trim().toUpperCase())
    if(idx>-1){
      route.value = route.value.splice(idx);
    }
  }
  const setRoute = (routePath: string, context: Context) => {
    const idx = route.value.findIndex(r => r.route.trim().toUpperCase() === routePath.trim().toUpperCase());
    if(idx === - 1){
      route.value.push({
        route: routePath,
        context: context
      })
    }else{
      if(idx+1!== route.value.length){
        route.value = route.value.splice(idx+1);
      }
    }

  }
  const reset = () => {
    route.value = [];
  }
  return {
    currentComponent, 
  //  breadCrumb, 
    route, 
    removeRoute, 
    setRoute,
    reset,
  }

}, {persist: true});


export class RouterFacade{
  route:  Store<"router", any>;
  context: Ref<Context>;
  setRoute: (routePath: string, context: Context) => void;
  reset: () => void
  constructor() {
    const {route, setRoute, reset} = RouterEstate();
    const {currentContext} = GlobalState();  
    this.route = route;
    this.context = ref(currentContext);
    this.setRoute= setRoute;
    this.reset = reset;
  }
  navigate = (routePath: string) =>  {
    const routeExists = Sh.router.getRoutes().some(route => route.path === routePath);

      if (routeExists) {
        Sh.router.replace(routePath);
      } else {
        Sh.router.push(routePath);
      }
      this.setRoute(routePath, Sh.deepCopy(this.context) );
    }

}
