import { entityTypes } from '@/shared/globaEnums';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Entity, User } from '../entities/dtos';

export interface Context{
  user: User,
  parent?: Context,
  entity: Entity,
  child?: Context,
}

 const initialContext: Context={
  user: new User(),
  entity: new Entity(),
} 

export const  GlobalState = defineStore("context",  () => {
  const getInitialContext = () => ({ user: new User(), entity: new Entity()});
  const treeContext = ref<Context>(getInitialContext());
  const currentContext = ref<Context>(getInitialContext());
  const hasError = ref<boolean>(false);
  const errorTitle = ref<string>("");
  const errorMessage = ref<string>("");
  const isLoading = ref<boolean>(false);
  const isGlobalDialog = ref<boolean>(false);
  const globalDialogType = ref<'upload'|'html'>('html');
  const globalDialogContent = ref<string>("");
  const globalDialogAction = ref<(result: boolean|string|null) => void>(null);

  const validateMenu = ref<() => boolean>(null);
  
  const pushContext = (context: Context, parent?: Context)=> {
    if(!treeContext.value || !treeContext.value.entity.id){
      treeContext.value = context;
      currentContext.value = context;
    }else{
      if(parent){

        parent.child = context;
      }else{
        currentContext!.value.child = context;
        currentContext.value= context;
      }
    }
  }

  const resetGlobal= () => {
    treeContext.value = getInitialContext();
    currentContext.value = getInitialContext();
    hasError.value = false;
    errorTitle.value = "";
    errorMessage.value = "";
    isLoading.value = false;
  }
  const  popContext = () =>{
    const cur = 
    currentContext.value = currentContext!.value.parent!;
    delete currentContext.value.child;
  }
  const setContext = (context: Context) => {
    currentContext.value  = context;
    delete currentContext.value.child
  }
  const loading = (value: boolean = true) => {
    isLoading.value = value;
  }
  const error = (title?: string, message?: string) => {
    hasError.value = title ? true : false;
    errorTitle.value = title ? title: "";
    errorMessage.value = message ? message : "";
  }
  const getCurClientId = (): string|null => {
    if(!currentContext) return null;
    if(currentContext.value.entity.entityType === entityTypes.CLIENT) return currentContext.value.entity.id;
    return currentContext.value.entity.clientId;
  }
  const showDialog = (type: 'html'| 'upload', action: ( result: boolean|string|null)  => void, html?: string ) => {
    globalDialogType.value = type;
    globalDialogContent.value = html;
    globalDialogAction.value = action;
    isGlobalDialog.value = true;

  }
  return{
    treeContext,
    currentContext,
    isLoading,
    hasError,
    errorTitle,
    errorMessage,
    isGlobalDialog,
    globalDialogType,
    globalDialogContent,
    globalDialogAction,
    
    loading,
    error,
    pushContext,
    popContext,
    setContext,
    getCurClientId,
    resetGlobal,
    showDialog,
    
  }

}, {persist: true});
