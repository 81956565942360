<template>
  <div class="modal">
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Agrega scroll si el contenido es mayor que la pantalla */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 90%; /* Ajusta el ancho del contenido */
  max-height: 90%; /* Ajusta la altura del contenido */
  overflow: auto; /* Agrega scroll si el contenido es mayor que el modal */
  z-index: 9999999999999;
}
</style>
<script lang="ts">
export default {name: "ModalInnerPage"}

</script>
