<script setup lang="ts">
  import { FormCabPostDto, FormDto, FormItemDto, Form_type, Item_type } from "@/core/store/forms/dtos";
import { useForms } from "@/core/store/forms/useForms";
import formItem from '@/views/components/formItem.vue';
import formOptions from '@/views/components/formOptions.vue';
import { watch, onBeforeUnmount, ref } from "vue";
import { Sh } from '@/core/sharedService';
import { useRoute } from 'vue-router';
import Dialog from '@/views/components/dialog.vue';
import { FormListStore, UseFormListStore } from '../formStore';
import { storeToRefs } from 'pinia';
  const {currentForm, isEditing, updateForm, newForm,addNewItem, removeItem, saveComponentForm,chageItemsPos, saveForm, resetCurrent, getForm, checkFormulare, canDelete, deleteForm} = useForms();
  const { isVisibleMenu } =  storeToRefs(FormListStore());

  const cab = ref<FormCabPostDto>(currentForm.value.cab);
  const cabEd = ref<FormCabPostDto>(currentForm.value.cab);
  const canDel = ref<boolean>(false);
  const isNew = ref<boolean>(false);
  const cabEditing = ref<boolean>(false);
  const isValidating = ref<boolean>(false);
  const viewFormulare = ref<boolean>(false);
  const itemsRefs = ref([]);
  const route = useRoute();
  const isChecking = ref<boolean>(false);
  const isModalComponentModel = ref<boolean>(false);
  const componentCreated = ref<FormDto>(new FormDto())
  let id  = route.params.id;
  let edit = ref<string>(<string>route.params.edit);

 const loadDelete = async () => {
    canDel.value = await canDelete(id.toString());
  }
  if(id){
    id = id.toString().toLowerCase();
    loadDelete();
  } 

  const dialogShow = ref<boolean>( id=== "new");
  const dialogShowDelete = ref<boolean>(false);

  const load= async () =>{
    isVisibleMenu.value = false;
    if(id!=="new"){
      if(currentForm.value.cab.id === id) return;
      if(await getForm(<string>id)){
        isEditing.value = true;
        isNew.value = false;
        cabEditing.value = false;
        cab.value = currentForm.value.cab;
        return;
      }
    }
    isNew.value = true;
    cabEditing.value = true;
    newForm();

    cab.value = currentForm.value.cab;
    cabEd.value = currentForm.value.cab;
    
    const wathched = watch(() => currentForm.value.cab.id, (newValue) => {
      if(newValue.trim().length){
        isNew.value = false;
        cabEditing.value = false;
        wathched();
      }
    });
  }
  //if(id!== currentForm.value.cab.id){
    load()
  //}
  const unload = (reload: boolean = false) => {
    window.removeEventListener('beforeunload', () =>unload(true));
  }
  onBeforeUnmount(unload);
  const unm = window.addEventListener('beforeunload', () => unload(true));
  const errors = ref<string[]>([]);
  let isValid = false;
  
  const addComponent = (type: Item_type | "COMPONENT", id: string )  => {
      addNewItem(type, id);
    
  }
  const onTrash =(item: FormItemDto) => {
    removeItem(item);
  }
  const moveUp =(item: FormItemDto) => {
    chageItemsPos(item, item.order - 1);
  }
  const moveDown =(item: FormItemDto) => {
    chageItemsPos(item, item.order + 1);
  }
  const editCab = () => {
    cabEd.value = currentForm.value.cab; 
    cabEditing.value = true
    isChecking.value = false;
    isModalComponentModel.value = false;
    dialogShow.value = true;
   }
  const RequiredAll = () => {
    for(const c of currentForm.value.items){
      if(c.itemType.includes("STATIC") 
        || c.itemType.includes("START")
        || c.itemType.includes("END")
        || c.itemType.includes("FORMULE")
        || c.itemType.includes("FINHAVA")
      ) continue;
      c.required = true;
    }
  }
  const save = async () => {
   try{
    let isValid = true;
     for(const c of itemsRefs.value){
      if(!c.valid()) isValid = false;
      }

    if(!isValid) return;
    const errors = checkFormulare();
    
    if(errors.length){
      alert("Error en fórmulas" + "\n" + errors.join("\n") + "\n" + "Por favor, revise las fórmulas)");
      return;
    } 
    const isSaved = await saveForm();
    if(isSaved) {
      isVisibleMenu.value = true;
      Sh.router.push("/forms");
      resetCurrent();

      return;
    }else{
      isEditing.value = true;
    }
   }catch(e){
     console.log(e);
   } 

  }
  const exit = () => {
    if(confirm("¿Desea salir?")){
      isVisibleMenu.value = true;
      Sh.router.push("/forms");
      resetCurrent();

    }

  }
  const Select = async () => {
   isChecking.value = true;

  }
  const CreateComponent = async () => {
    let isValid = true;
     for(const c of itemsRefs.value){
      if(!c.valid()) isValid = false;
      }

    if(!isValid){
      alert("Por favor, revise los campos antes de crear un componente")
      return;
    } 
    componentCreated.value = new FormDto();

    let x = 0;
    for(const c of itemsRefs.value){
      if(c.isCheck) {
        x++;
        const d = {...c.getData(), id: "", formId: "", order: x};
        componentCreated.value.items.push(d);
      }
    }
    componentCreated.value.cab.name = "";
    componentCreated.value.cab.formType = Form_type.COMPONENT;
    componentCreated.value.cab.description = "";
    componentCreated.value.cab.endDate = null;
    componentCreated.value.cab.id = "";
    componentCreated.value.cab.clientId = currentForm.value.cab.clientId;
    cabEd.value = componentCreated.value.cab;
    cabEditing.value = true
    isChecking.value = false;
    isModalComponentModel.value = true;
    dialogShow.value = true; 

    //addNewItem(Item_type.TEXT);
  }
  const updateDescription = (event) => {
    currentForm.value.cab.description = (event.target as HTMLTextAreaElement).value;
  }
  const editForm = () =>{
    edit.value = "true";
  }
  const saveCab = async () => {
    if(!isModalComponentModel.value){
      currentForm.value.cab = cabEd.value;
      cab.value = cabEd.value;
      await updateForm(); 
    }else{
      componentCreated.value.cab = cabEd.value;
      const isSaved = await saveComponentForm(componentCreated.value);

      if(isSaved) {
        Sh.router.push("/forms");
        resetCurrent();
        return;
      }else{
        isEditing.value = true;
      }

    }
    dialogShow.value = false; 
    cabEditing.value = false;
    
    isChecking.value = false;
    isModalComponentModel.value = true;
    editForm()
  }
  const del = async () => {
    const  idDelete:boolean = await deleteForm(id.toString());
    dialogShowDelete.value = false;
    if(idDelete) Sh.router.push("/forms");

  }
  //load();
</script>

<template>
  <div class="container-fluid formPage editFrontPage">
    <div class="row">

      <div v-if="edit" class="col-4" v-bind:hidden="cabEditing">
        <small v-if="!viewFormulare" style="cursor: pointer;" @click="viewFormulare=true">Ver variables de formulas</small>
        <small v-if="viewFormulare" style="cursor: pointer;" @click="viewFormulare=false">Ver menú</small>
        <formOptions :externalAction="addComponent" v-if="!viewFormulare"/>
        <div v-if="viewFormulare" class="row scroll">
          <h4>Variables de formulas</h4>
          <hr>
          <template v-for="item in currentForm.items" :key="item.order">
            <template v-if="!item.itemType.includes('STATIC') && !item.itemType.includes('START') && !item.itemType.includes('END')">
              <div class="col-3">
                {{ item.varNameId }}
              </div>
              <div class="col-8">
                {{ item.label }}
              </div>
              
            </template>  
          </template>
        </div>
      </div>

      <div class="containerEditForm" :class="{ 'col-8 offset-lg-4' : !edit, 'col-8': edit}">
        <div class="header-container">
          <div class="row">
            <template v-if="!cabEditing">
              <div class="col-6">
                <h4>{{ "Nombre : " + cab.name}}</h4>
                <label class="labelHeader" v-if="currentForm.cab.formType ===Form_type.FROM">Fecha fin: {{ Sh.toShortDate(cab.endDate) }}</label>
                <label class="labelHeader">Tipo: {{ cab.formType }}</label>
              </div>
          </template>
            <div class="col-6" v-if="edit">
              <div class="row">
                <div class="col-3" v-if="!cabEditing">
                  <label class="labelHeader"><i class="bi bi-pencil-square cursor  small" @click="() => editCab()">Editar Cab.</i></label>
                </div>
                <div class="col-3" v-if="!cabEditing && !isNew">
                  <label class="labelHeader" ><i class="bi bi-floppy cursor  small" @click="async () => save()">Guardar</i></label>
                </div>
                <div class="col-3" v-if="!isNew && currentForm.items.length && !isChecking">
                  <label class="labelHeader" ><i class="bi bi-card-checklist cursor small"  @click="async () => Select()">Seleccionar</i></label>
                </div>
                <div class="col-3"  v-if="isChecking">
                  <label class="labelHeader"><i class="bi bi-gear-wide-connected cursor  small"  @click="async () => CreateComponent()">Crear Componete</i></label>
                </div>
                <div class="col-3"  v-if="!cabEditing">
                  <label class="labelHeader"><i class="bi bi-check2-all cursor  small"  @click="async () => RequiredAll()">Todos Requeridos</i></label>
                </div>
                <div class="col-3">
                  <label class="labelHeader"><i class="bi bi-box-arrow-left cursor  small"  @click="async () => exit()">Salir</i></label>
                </div>
                <div class="col-3" v-if="canDel && edit" style="z-index: 999999">
                  <label class="labelHeader"><i class="bi bi-trash3  small"  @click="() => dialogShowDelete=true">Borrar</i></label>
                  <!-- <button class="btn btn-danger small" @click="dialogShowDelete = true" style="padding: 0px; margin: 0px;" ><i class="bi bi-trash3" style="color: white; margin: 0px"></i></button> -->
              </div>
              </div>
              
              
              
              
             <!--    <button v-if="!cabEditing" class="edit-button mr" @click="() => editCab() ">Editar cabecera</button> 
                <button v-if="!cabEditing && !isNew" class="edit-button mr" @click="async () => await save()">Guardar Formulario</button> 
                <button v-if="!isNew && currentForm.items.length && !isChecking" class="edit-button mr" @click="async () => Select()">Seleccionar items</button> 
                <button v-if="isChecking" class="edit-button mr" @click="async () => CreateComponent()">Crear componente</button>  -->
                <!-- <button v-if="cabEditing" class="edit-button mr" @click="async () => {await updateForm() ? cabEditing = false : null} ">{{ !isNew ? "Guardar Cabecera" : "Crear Formulario" }}</button>  -->
            </div>
            <div class="col-6" v-if="!edit">
              <div class="row">
                <div class="col-3" v-if="!cabEditing">
                  <label class="labelHeader"><i class="bi bi-pencil-square cursor  small" @click="editForm ">Editar</i></label>
                </div>
              </div>
             </div>


        </div>
          

          

        </div>
        <div class="contentForm mt-4" v-if="!isNew && currentForm.items && currentForm.items.length">
          
          <div class="create-component-box ajustForm" v-bind:hidden="cabEditing">
            <formItem v-for="item in currentForm.items" :key="item.order" :data="item" :onTrash="onTrash" :moveDown="moveDown" :moveUp="moveUp" :checking="isChecking" ref="itemsRefs" :can-edit="!!edit"/>
          </div>
        </div>
      </div>


  </div>

  <!-- Diálogos -->


  <Dialog :on-close="() =>{
                              dialogShow = false
                              if(id === 'new') Sh.router.push('/forms');
                              cabEditing = false;
                            }" 
      :show="dialogShow"
      :on-confirm="async () => saveCab()">


      <div class="editing row">
      <div class="col-lg-5">
        <label>Nombre</label>
        <input type="text"  class="w100"
        v-model="cabEd.name" 
        v-bind:class="{'edit': cabEd.id, 'edit error': errors.includes('entity_name')}">
      </div>
      <div class="col-lg-7">
        <label>Descripción</label>
        <textarea v-model="cabEd.description"  class="w-100" @input="updateDescription"></textarea>
      </div>
      <div v-if="!isModalComponentModel" class="col-lg-5">
        <label>Tipo</label>
        <select v-model="cabEd.formType">
          <option value="FORM" selected>Formulario</option>
          <option value="COMPONENT">Componente</option>
        </select>
      </div>
      <div v-if="isModalComponentModel" class="col-lg-5">
        <label>Tipo Componente</label>
      </div>
      <div v-if="!isModalComponentModel" class="col-lg-7">
        <label>Fecha fin</label>
        <input type="date" v-model="cabEd.endDate">
      </div>
    </div>
  </Dialog>
  <Dialog :on-close="() =>{ 
          dialogShowDelete = false}" 
      :show="dialogShowDelete"
      :on-confirm="async () => del()">
      <h3>Está a punto de borrar el formulario actual</h3>
      <p>¿Está totalmente seguro de querer borrarlo?</p>
    </Dialog>
  <!-- Diálogos FIN -->
</div>
<div v-if="edit" class="opaque">


</div>  
</template>


<style>
.ajustForm{
  margin-bottom: 100px;
}
.editFrontPage{
  z-index: 999999;
}

.leftBar {
  position: fixed;
  top: 50px;
  left: 0;
  padding-left: 0px;
  z-index: 9999;
  height: --webkit-fill-available;
  overflow-y: hidden;
}

.formPage .header-container textarea,
.formPage .header-container input[type="text"] {
  border: 0px;
  position: relative;
  border: 3px solid #81B4AB;
  border-radius: 5px;
  padding-left: 10px;
}

.formPage .header-container input[type="datetime-local"],
.formPage .header-container select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  height: 42px;
}

.formPage .header-container input,
.formPage .header-container textarea,
.formPage .header-container select,
.formPage .header-container label {
  width: 100%;
  display: block;
  margin-top: 5px;
  margin-bottom: 15px;
}
.formPage .header-container {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #0000002b;
}
.edit-button{
  padding: 5px 5px !important;
  min-width: 100px;
}
.mr{
  margin-right: 10px;
}
body{
  position: relative;
}

.formPage {
  position: fixed;
  padding: 0;
  height: -webkit-fill-available;
}

.formPage .row {
  padding: 0;
  margin: 0;
}

.create-component{
  font-weight: lighter;
  background-color: #005060;
  color: #fff;
  padding: 8px 45px;
  border-radius: 0;
  cursor: pointer;
  width: fit-content;
  font-size: 18px;
}
.labelHeader{
  display: inline-block !important;
  margin: 0px !important;
}
.containerEditForm{
  z-index: 9999999;
}

.scroll{
  overflow-y: scroll;
  height: 80%;
  max-height: 600px;
}
</style>
<script lang="ts">
  export default {
    name: 'formItem',
  }
</script>@/core/sharedService