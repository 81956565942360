<script setup lang='ts'>

import { Sh } from '@/core/sharedService';
import { GlobalState } from '@/core/store/global/gobalState';
import { storeToRefs } from 'pinia';
import { type Ref, ref, watch, computed } from 'vue';
import { SearchResult, UseFormListStore } from '@/views/forms/formStore';
import { form_state, roles } from '@/shared/globaEnums';
import { UserStore } from '@/core/store/user/userState';
import { useRoute, useRouter } from 'vue-router';
import DataTableDP, { DataTableColumn } from '@/views/components/dataTableDP.vue';
import { FormResponseCompletedDto, Form_status, Form_status_descriptions } from '@/core/store/forms/dtos';
import BadgeComponent from "../../components/badge.vue";
import Survey from '@/core/store/survey/surveyStore';


const icons = {
  formsAsignedIcon: "/assets/forms-asigned-icon.svg",
  formsIncourseIcon: "/assets/forms-inCourse-icon.svg",
  formsCompleteIcon: "/assets/forms-complete-icon.svg"
}

const { currentContext } = storeToRefs(GlobalState());
const { user } = UserStore();

const { init,
  forms,
  search,
  hasMore,
  clearSearch,
  getChild,
  clearForms,
  getTocompleteForm,
} = UseFormListStore();

const {} = Survey()

const route = useRoute();
const router = useRouter();
const selected = ref<SearchResult>(new SearchResult())
const toList = ref<SearchResult[]>([]);
let type = route.params.type; // incourse | complete (empty all)

// AÑADIR LOGICA PARA ICONOS Y ETIQUETAS DINÁMICAS
const dynamicIcon = computed(() => {
  if (route.path.includes('/listAssigneds/incourse')) {
    console.log("In course");
    return icons.formsIncourseIcon;
  } else if (route.path.includes('/listAssigneds/complete')) {
    console.log("Complete");
    return icons.formsCompleteIcon;
  } else {
    console.log("Default");
    return icons.formsAsignedIcon;
  }
});

const dynamicLabel = computed(() => {
  if (route.path.includes('/listAssigneds/incourse')) {
    console.log("In course");
    return 'Formularios en curso';
  } else if (route.path.includes('/listAssigneds/complete')) {
    console.log("Complete");
    return 'Formularios completados';
  } else {
    console.log("Default");
    return 'Formularios asignados';
  }
});


watch(() => route.params.type, (newValue) => {
  type = newValue;
  selected.value.child = [];
  if (type && type === "incourse") {
    search.value.states = ["DRAFT"];
  } else if (type && type === "complete") {
    //   toList.value = forms.value.filter(f => f.child && f.child.filter(f2 => [ Form_status.CLOSED.toString(), form_state.VALIDATED.toString()].includes(f2.state)));
    search.value.states = ["CLOSED", "VALIDATED"];
  } else {
    // toList.value = forms.value;
    search.value.states = [];
  }
  initialize(search.value.states)
  
})
const childs = computed(() => {
  return selected.value.child
});

const viewStatus = (data: any, field: string): string => {
  let text = Form_status_descriptions[data[field]];
  let style = "color:";
  const percent = data.questionsEnded ? ((data.questionsEnded / data.questionsRequireds) * 100) : 0;
  const textPercent = data.questionsRequireds && data[field] == Form_status.DRAFT ? ` ( ${Sh.round(percent).toString()} %) ` : " (n/a) ";
  switch (data[field]) {
    case Form_status.ASSIGN:
      style += "var(--red)";
      break;
    case Form_status.CLOSED:
      style += "green";
      break;
    case Form_status.DRAFT:
      style += "orange";
      text += textPercent;
      break;
  }

  return `<label style="${style}">${text}</label>`;
}
const renderResult = (data: any, field: string): string => {

  let result = data[field];
  if (result && result != 0) {
    return (Math.floor(result * 100) / 100).toString();
  }
  return "-";
}
const colsInit: DataTableColumn[] = [
  {
    name: "name",
    field: "name",
    header: "Formulario",
    orderable: true,
    width: "600px"
  },
  {
    name: "entityName",
    field: "entityName",
    header: "Asignado a",
    orderable: true,
    width: "200px"
  },
  {
    name: "endDate",
    field: "endDate",
    header: "Fecha fin",
    isDate: true,
    orderable: true,
  },
  {
    name: "state",
    field: "state",
    render: viewStatus,
    header: "Estado",
    orderable: true,
  },
];
const cols: Ref<DataTableColumn[]> = ref<DataTableColumn[]>([]);

const initialize = async (states?: string[]) => {


  clearSearch();
  let type = route.params.type;
  selected.value.child = [];

  if (type && type === "incourse") {
    search.value.states = ["DRAFT"];
  } else if (type && type === "complete") {
    //   toList.value = forms.value.filter(f => f.child && f.child.filter(f2 => [ Form_status.CLOSED.toString(), form_state.VALIDATED.toString()].includes(f2.state)));
    search.value.states = ["CLOSED", "VALIDATED"];
  } else {
    // toList.value = forms.value;
    search.value.states = [];
  }


  /* if(states){
    search.value.states = states;
  } */
  clearForms();
  if (currentContext.value && currentContext.value.entity && currentContext.value.entity.id.trim().length) {
    search.value.entityId = currentContext.value.entity.id;
  } else if (user.role === roles.ROOT) {
    search.value.entityId = "";
  } else {
    search.value.entityId = user.entityId;
  }
  cols.value = [...colsInit];
  if (user.role === roles.ROOT || user.role === roles.CLIENT) {

    cols.value.push({
      name: "count",
      field: "count",
      header: "asignados",
      orderable: true,
    })
  } else {
    cols.value.push({
      name: "result",
      field: "result",
      header: "Resultado",
      render: renderResult
    })
  }
  await init();
  toList.value = forms.value;

}
initialize();

const sending = ref<boolean>(false);
let colsChild = ref<DataTableColumn[]>([]);

const clickRow = async (data: SearchResult) => {
  if (sending.value) return;
  if (user.role === roles.ENTITY || user.role === roles.GROUP) {
    // Survey
    const param: 'design'|'input'|'display'|'pdf' = data.state == Form_status.CLOSED ? 'display' : user.role !== roles.ENTITY ? 'display': 'input';
    view(param, data.id, data.entityId);
    return;
    // Previo Survey
    try {
      await getTocompleteForm(data.id, data.entityId);
      Sh.router.push(`/forms/complete${data.state == Form_status.CLOSED ? "" : user.role !== roles.ENTITY ? "" : "/edit"}`)

    } catch (e: any) {

      if ((<string>e.response!.data).includes("no encontrado")) {
        const form = new FormResponseCompletedDto();
        form.formId = data.id;
        form.entityId = data.entityId;

        return;
      }
    }

    return;
  }

  if (data.count) {
    sending.value = true;
    colsChild.value = Sh.deepCopy(colsInit);
    colsChild.value.push({
      name: "result",
      field: "result",
      header: "Resultado",
      render: renderResult
    })

    await getChild(data.id, data.entityId);
    selected.value = data;
    sending.value = false;
  } else {
    selected.value = new SearchResult();
  }


}
const clickSecond = async (data: SearchResult) => {
  // survey
  view(data.state == Form_status.CLOSED ? 'display' : 'input', data.id, data.entityId );
  return;
  // Previo survey
  const param = data.state == Form_status.CLOSED ? "" : "/edit";
  if (data.state == Form_status.ASSIGN) return;
  await getTocompleteForm(data.id, data.entityId);
  Sh.router.push(`/forms/complete` + param)
}

// Survey

const view = async (param: 'design'|'input'|'display'|'pdf', formDesignId: string, entityId: string) => {
  const {getResponse} = Survey();
  await getResponse(formDesignId, entityId);
  router.push(`/forms/view/` + param.toString());
}


</script>
<template>
  <div class="container-client">
    <div class="header-container">
      <BadgeComponent :icon="dynamicIcon" :label="dynamicLabel" />
    </div>

    <div class="table-responsive">
      <DataTableDP :columns="cols" :data="toList" :rowClick="clickRow" />
    </div>

  </div>
  <div v-if="childs && childs.length !== 0" class="container-client secondTable">
    <div class="table-responsive">
      <h5>Formulario: {{ selected.name }}</h5>
      <DataTableDP :columns="colsChild" :data="childs" :rowClick="clickSecond" />

    </div>
  </div>
</template>
<style scoped>
.dataTables_length {
  display: none;
}

* {
  background-color: #fff;
}

.container-client {
  padding: 50px;
  border-radius: 22px;
  max-width: 80%;
  width: 100%;
  margin: -56px auto;
  box-shadow: 0px 0px 15px #0000002e;
}

.header-container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  width: 100%;
  text-align: left;
}

.table-responsive th {
  padding: 5px;
  text-align: left;
}

.secondTable {
  margin-top: 120px;
  margin-bottom: 50px;
}
</style>