import { v4 as uuidv4 } from 'uuid';
import {coverHtml, footer, header} from '../../../resources/templates';
import { SurveyResponseItemDto } from './surveyResponseDtos';
export enum DisplayType{
  EDIT = 'EDIT',
  DISPLAY = 'DISPLAY',
  PDF = 'PDF'
}
export enum SelectedItemType{
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  ITEM = 'ITEM',
  SECTION = 'SECTION',
  PAGE = 'PAGE',
  NONE = 'NONE',
  SURVEY = 'SURVEY'
}
export interface ButtonComponent{
  label: string;
  icon: string;
  type: FieldType
}
export interface ErrorItem{
  itemId: string;
  message: string;
}
export class FormDesignDto{
  cab: FormCabDesigntDto = new FormCabDesigntDto();
  items: FormDesignItemDto[] = [];
}
export class FormSectionDesignDto{
  order: number;
  section: FormDesignItemDto;
  break: boolean;
  items: FormDesignItemDto[];
}
export class FormCabDesigntDto {
  id: string = uuidv4();
  name: string = "";
  clientId: string = "";
  description: string = "";
  endDate: string = "";
  initDate: string = "";
  formType: Form_type = Form_type.FROM;
  fieldCounter: number = 0;
  config: FormConfig = new FormConfig();  
  isNew: boolean = true;
  userEmail: string = "";
}
export enum Form_type{
  FROM = "FORM",
  COMPONENT = "COMPONENT"
}
export class FormDesignItemDto{
  id : string = uuidv4();
  content? : string;
  type: FieldType;
  tag : string = "";
  label: string = "Etiqueta";
  inheritItemId? : string;
  order: number;
  weight? : number;
  required: boolean = true;
  weightEmpty? : number;
  defaultValue? : number;
  sufix? : string;
  format : string = "";
  formId: string;
  parentItemId? : string;
  items? : ItemMultiDrawarer[];
  itemsType?: ItemsType;
  properties: PropertyItem = new PropertyItem();
  isNew: boolean = true;
  response?: SurveyResponseItemDto;
}

export enum  ItemsType{
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
}
export class ItemMultiDrawarer{ // Item de un selector o de una grilla
  label:string  = "Item";
  value:any = null!;
  tag:string = ""; // formula
}

export class PropertyItem{ 
  input:   PropertyDisplayType = new PropertyDisplayType();
  screen:  PropertyDisplayType = new PropertyDisplayType();
  print:   PropertyDisplayType = new PropertyDisplayType();
}

export class PropertyDisplayType{
  visible:boolean = true;
  itemOnlySelected:boolean = true;// En el caso de listas, si se mostraran todos los items y se marcarán los seleccionados o solo aparecerán en el informe final los seleccionados.
  contentStyle:PropertyStyle = new PropertyStyle();
  labelStyle:PropertyStyle  = new PropertyStyle();
}
export class PropertyStyle{
  width? : number = 0; // Opcional para imagenes y graficos
  heignt? : number = 0;
  fontSize? : number = 16;
  backgroundColor : string = "#D3EDE6";
  color: string = "#126192";
  bold: boolean = false;
  align: TextAlign = 'left'; 
  underline: boolean = false;
}


type TextAlign = 'left' | 'right' | 'center' | 'justify';
export enum  FieldType
{
    SECTION = "SECTION",
    NEWPAGE = "NEWPAGE",
    HTML = "HTML",
    TEXT = "TEXT",
    FORMREF = "FORMREF",
    NUMBER = "NUMBER",
    DATE = "DATE",
    DATERANGE = "DATERANGE",
    DROPDOWN = "DROPDOWN",
    MULTYDROPDOWN = "MULTYDROPDOWN",
    GRID = "GRID",
    YESNO = "YESNO",
    FILE = "FILE",
    FORMULE = "FORMULE",
    IMAGE = "IMAGE",
    APIREST = "APIREST",
    GRAPHIC = "GRAPHIC",
    

}
class PropertyFlagDisplay{
  contentStyle:PropertyDisplayTypeExtend;;
  labelStyle:PropertyStyle;
}
export const surveyButtonsLeftBar: ButtonComponent[] = [
  {label: "Sección",            icon: "/assets/objectButtonsIcons/01_seccion_icon.svg",            type: FieldType.SECTION},
  {label: "Salto de página",    icon: "/assets/objectButtonsIcons/02_saltoPagina_icon.svg",        type: FieldType.NEWPAGE},
  {label: "Texto libre",        icon: "/assets/objectButtonsIcons/03_textoLibre_icon.svg",         type: FieldType.HTML},
  {label: "Campo de texto",     icon: "/assets/objectButtonsIcons/04_campoTexto.svg",              type: FieldType.TEXT},
  {label: "Campo Número",       icon: "/assets/objectButtonsIcons/06_campoNum_icon.svg",           type: FieldType.NUMBER},
  {label: "Selector de fecha",  icon: "/assets/objectButtonsIcons/07_selectorFecha_icon.svg",      type: FieldType.DATE},
  {label: "Rango de fechas",    icon: "/assets/objectButtonsIcons/08_rangoFechas_icon.svg",        type: FieldType.DATERANGE},
  {label: "Desplegable",        icon: "/assets/objectButtonsIcons/09_desplegable_icon.svg",        type: FieldType.DROPDOWN},
  {label: "Selección múltiple", icon: "/assets/objectButtonsIcons/10_seleccionMultiple_icon.svg",  type: FieldType.MULTYDROPDOWN},
  {label: "Datos tabulados",    icon: "/assets/objectButtonsIcons/11_datosTabulados_icon.svg",     type: FieldType.GRID},
  {label: "Sí / No",            icon: "/assets/objectButtonsIcons/12_siNo_icon.svg",               type: FieldType.YESNO},
  {label: "Adjuntar fichero",   icon: "/assets/objectButtonsIcons/13_adjuntarFichero_icon.svg",    type: FieldType.FILE},
  {label: "Imagen",             icon: "/assets/objectButtonsIcons/16_grafico_icon.svg",            type: FieldType.GRAPHIC},
  {label: "Referencia form.",   icon: "/assets/objectButtonsIcons/05_refForm_icon.svg",            type: FieldType.FORMREF},
  {label: "Fórmula",            icon: "/assets/objectButtonsIcons/14_formula_icon.svg",            type: FieldType.FORMULE},
  {label: "API",                icon: "/assets/objectButtonsIcons/15_API_icon.svg",                type: FieldType.APIREST},

]
export const FieldTypeLabels = {
  SECTION : "Sección",
  NEWPAGE : "Nueva página",
  HTML : "Texto enriquecido",
  TEXT : "Campo de texto",
  FORMREF : "Referencia a formulario",
  NUMBER : "Campo numérico",
  DATE : "Campo fecha",
  DATERANGE : "Campo Rango de fecha",
  DROPDOWN : "Desplegable",
  MULTYDROPDOWN : "Selección múltiple",
  GRID : "Datos tabulados",
  YESNO : "Campo SI/NO",
  FILE : "Adjuntar fichero",
  FORMULE : "Fórmula",
  IMAGE : "Imagen estática",
  APIREST : "Campo Api-Rest",
  GRAPHIC : "Grafico Superset",
}

export const NOTINPUTITEM = [
  FieldType.SECTION,
  FieldType.NEWPAGE,
  FieldType.HTML,
  FieldType.GRAPHIC,
  FieldType.FORMREF,
  FieldType.FORMULE,
  FieldType.APIREST,
  
]
export const ITEMSWITHTAGS = {
  [FieldType.TEXT] : false,
  [FieldType.NUMBER] : true,
  [FieldType.DATE] : false,
  [FieldType.DATERANGE] : true,
  [FieldType.DROPDOWN] : true,
  [FieldType.MULTYDROPDOWN] : true,
  [FieldType.GRID] : true,
  [FieldType.YESNO] : false,
  [FieldType.FILE] : false,
  [FieldType.FORMREF] : true,
  [FieldType.FORMULE] : true,
  [FieldType.APIREST] : true,
};

export class FormulaAssignableItem{
  label: string;
  tag: string;
  items?: FormulaAssignableItem[];
  hasValue: boolean;
  type: FieldType;
}

class PropertyDisplayTypeExtend extends PropertyStyle{
  weight? : number;
  required: boolean = true;
  weightEmpty? : number;
  sufix? : string;
  format? : string;
  tag? : string;
  items: string;
  itemsType: string;
  itemOnlySelected: boolean;

}
export interface InputTypeDisplay{
  label: string,
  type: "text"|"number"|"date"|"check"|"color"|"select"|"format"|"items",
  sufix?: any,
  typeBind:  'item'|'style'|'display',
  editOnlyDesign?: DisplayType[]
}


 export class PropertiesConfigurationDisplay{
  width:           InputTypeDisplay = {typeBind: 'style', label: "Ancho", type: 'number', sufix: "px"};
  heignt:          InputTypeDisplay = {typeBind: 'style', label: "Alto",  type: 'number', sufix: "px"};
  fontSize:        InputTypeDisplay = {typeBind: 'style', label: "Tamaño fuente", type: 'number', sufix: "px"};
  bold:            InputTypeDisplay = {typeBind: 'style', label: "Negrita", type: 'check', sufix: ""};
  underline:       InputTypeDisplay = {typeBind: 'style', label: "Subrayado", type: 'check', sufix: ""};
  color:           InputTypeDisplay = {typeBind: 'style', label: "Color fuente", type: 'color', sufix: ""};
  backgroundColor: InputTypeDisplay = {typeBind: 'style', label: "Fondo", type: 'color', sufix: ""};
  align:           InputTypeDisplay = {typeBind: 'style', label: "Alineación", type: 'select', sufix: [{value: 'left', label: 'Izquierda'}, {value: 'right', label: 'Derecha'}, {value: 'center', label: 'Centrado'}]};
  weight:          InputTypeDisplay = {typeBind: 'item', label: "Peso", type: 'number', sufix: "", editOnlyDesign: [DisplayType.EDIT] };
  weightEmpty:     InputTypeDisplay = {typeBind: 'item', label: "Peso vacío", type: 'text', sufix: "", editOnlyDesign: [DisplayType.EDIT] };
  required:        InputTypeDisplay = {typeBind: 'item', label: "Requerido", type: 'check', sufix: "", editOnlyDesign: [DisplayType.EDIT] };
  sufix:           InputTypeDisplay = {typeBind: 'item', label: "Sufijo", type: 'text', sufix: ""};
  format:          InputTypeDisplay = {typeBind: 'item', label: "Formato", type: 'format', sufix: {NUMBER: ["999", "9.999,99"],APIREST: ["999", "9.999,99"] , DATE: ["dd-mm-yyyy", "yyyy-mm-dd"], DATERANGE: ["dd-mm-yyyy", "yyyy-mm-dd"] } , editOnlyDesign: [DisplayType.PDF] };
  tag:             InputTypeDisplay = {typeBind: 'item', label: "Tag", type: 'text', sufix: "", editOnlyDesign: [DisplayType.EDIT] };
  items:           InputTypeDisplay = {typeBind: 'item', label: "Elementos", type: 'items', sufix: "", editOnlyDesign: [DisplayType.EDIT] };
  itemsType:       InputTypeDisplay = {typeBind: 'item', label: "Tipo elementos", type: 'select', sufix: [{value: 'TEXT', label: 'Texto'},  {value: 'NUMBER', label: 'Número'},{value: 'DATE', label: 'Fecha'}], editOnlyDesign: [DisplayType.EDIT] };
  visible:         InputTypeDisplay = {typeBind: 'display', label: "Visible", type: 'check', sufix: ""};
  itemOnlySelected:InputTypeDisplay = {typeBind: 'display', label: "Ver seleccionados", type: 'check', sufix: "", editOnlyDesign: [DisplayType.DISPLAY, DisplayType.PDF] };
}

export const propertiesConfigurationDisplay:PropertiesConfigurationDisplay = new PropertiesConfigurationDisplay();

type PropertyStyleFlags = {
  [K in keyof PropertyDisplayTypeExtend]?: boolean;
};
type PropertyDisplayTypeFlags = {
  [K in keyof PropertyFlagDisplay]?: PropertyStyleFlags;
};




// Define el tipo para el objeto principal
type FieldStyleFlags = {
  [K in FieldType]?: PropertyDisplayTypeFlags;
};

const defaultLabelStyle = {
    fontSize: true,
    color: true,
    bold: true,
    underline: false,
    align: true,
}

const defaultContentStyle= {
  fontSize: true,
  backgroundColor: true,
  color: true,
  align: true,
  weight: true,
  weightEmpty: true,
  required: true,
  tag: true,
}



export const PropertiesDisplayComponents: FieldStyleFlags = {
  [FieldType.SECTION]:        {labelStyle: {...defaultLabelStyle, backgroundColor: true,}},
  [FieldType.HTML]:           {},
  [FieldType.NEWPAGE]:        {contentStyle: {}},
  [FieldType.TEXT]:           {contentStyle: defaultContentStyle,                                                 labelStyle: defaultLabelStyle},
  [FieldType.NUMBER]:         {contentStyle: { ...defaultContentStyle, format: false,sufix: true},                 labelStyle: defaultLabelStyle},
  [FieldType.DATE]:           {contentStyle: { ...defaultContentStyle, format: true},                             labelStyle: defaultLabelStyle},
  [FieldType.DATERANGE]:      {contentStyle: { ...defaultContentStyle, format: true},                             labelStyle: defaultLabelStyle},
  [FieldType.DROPDOWN]:       {contentStyle: { ...defaultContentStyle,items: true},                               labelStyle: defaultLabelStyle},
  [FieldType.MULTYDROPDOWN]:  {contentStyle: { ...defaultContentStyle,items: true, itemOnlySelected: true},       labelStyle: defaultLabelStyle},
  [FieldType.GRID]:           {contentStyle: { ...defaultContentStyle, itemsType: true,sufix: true,items: true},  labelStyle: defaultLabelStyle},
  [FieldType.YESNO]:          {contentStyle: {...defaultContentStyle, required: false},                           labelStyle: defaultLabelStyle},
  [FieldType.FILE]:           {contentStyle: { tag: true, weight: true, weightEmpty: true, required: true},        labelStyle: defaultLabelStyle},
  [FieldType.FORMULE]:        {contentStyle: { ...defaultContentStyle, format: true,sufix: true, required: false},labelStyle: defaultLabelStyle},
  [FieldType.APIREST]:        {contentStyle: { ...defaultContentStyle, format: true,sufix: true, required: false},labelStyle: defaultLabelStyle},
  [FieldType.GRAPHIC]:        {contentStyle: {width: true, align: true},                             labelStyle: defaultLabelStyle},
  [FieldType.IMAGE]:          {contentStyle: {width: true, align: true},                             labelStyle: defaultLabelStyle},
  [FieldType.FORMREF]:        {},
}

export class FormConfig{
  marginTop: number  = 150;
  marginLeft: number  = 10;
  marginBottom: number  = 100;
  marginRight: number  = 10;
  header: string  = header;
  footer: string  = footer;
  firstPage: string  = coverHtml;
  pageNumber: boolean = true;
}


/**
 * Devuelve un nuevo item con los valores por defecto según el tipo.
 * @param type tipo de item FieldType
 * @param form Formulario
 * @param parentOrOrder Sección a la que pertenece o el orden que se le va a asignar.
 * @returns 
 */
export const defaultItem = (type: FieldType, form: FormDesignDto,  parentOrOrder: FormSectionDesignDto | number, tag: string = null): FormDesignItemDto => {
    const item = new FormDesignItemDto();
    
    if(typeof parentOrOrder != 'number'){
      let num = 0; 
      parentOrOrder.items.map(i => {
        if(i.order > num){
          num = i.order;
        }
      });
      item.order = num+1;
      item.tag =  tag ;
      item.parentItemId = parentOrOrder.section.id;
    }else{
      item.order = parentOrOrder;
    }
    item.type = type;
    const labelFind = surveyButtonsLeftBar.find(b => b.type == type);

    item.label = labelFind ? `Etiqueta de ${labelFind.label}` : "Etiqueta";
    item.formId = form.cab.id;
    if(![FieldType.SECTION, FieldType.NEWPAGE].includes(type)){
      item.properties.print.labelStyle.backgroundColor = "";
    }
    switch(type){
      case FieldType.NUMBER:
          item.format = "999";
          item.sufix = "";
          break;
      case FieldType.DATE:
      case FieldType.DATERANGE:
          item.format = "dd-mm-yyyy";
          item.sufix = "";
          break;
      case FieldType.MULTYDROPDOWN:
      case FieldType.GRID:
          item.items = [];        
          break;
      case FieldType.FORMREF:
      case FieldType.FORMULE:
      case FieldType.APIREST:
      case FieldType.YESNO:
          item.required = false;
    }
    return item;
}


/**
 * Comprueba que los items tengan los valores por defecto.
 * @param items listado de items
 * @returns 
 */
export const fromDBItems = (items: FormDesignItemDto[]): FormDesignItemDto[] => {
    for(let item of items){
      item = fromDBItem(item);
    }
  return items;
}


/**
 * comprueba un item que los valores no informados tengan los datos por defecto.
 * @param item 
 * @returns 
 */
export const fromDBItem = (item: FormDesignItemDto): FormDesignItemDto => {
  switch(item.type){
    case FieldType.SECTION:
      
      break;

  }
  return item;
}

export enum SurveySystemVar{
  FORMNAME=         '<span>{{nombre-formulario}}        </span>',
  FORMDESCRIPTION=  '<span>{{descripcion-formulario}}   </span>',
  GROUPNAME=        '<span>{{nombre-grupo}}             </span>',
  ENTITYNAME=       '<span>{{nombre-entidad}}         </span>',
  USERNAME=         '<span>{{nombre-usuario}}            </span>',
  GROUPADDRESS=     '<span>{{direccion-grupo}}          </span>',
  ENTITYADDRESS=    '<span>{{direccion-entidad}}      </span>',
  ENTITYCIF=        '<span>{{cif-entidad}}                  </span>',
  EITITYFINHAVA=    '<span>{{id-finhava}}                  </span>',
  GROUPCIF=         '<span>{{cif-grupo}}                      </span>',
  FORMINIT=         '<span>{{fecha-ini}}                    </span>',
  FORMEND=          '<span>{{fecha-fin}}                    </span>',
  FORMCLOSE=        '<span>{{fecha-cierre}}                 </span>',
  FORMEMIT=         '<span>{{fecha-emision}}                </span>',

  GROUPLOGO=        '<img  width="100px" class="nav_logo" src="/assets/grupo-entidades-logo.png">',
  ENTITYLOGO=       '<img  width="100px" class="nav_logo" src="/assets/entity-logo.png">',
  CLIENTLOGO=       '<img  width="100px" class="nav_logo" src="/assets/client-logo.png">',
  LOOPOLOGO=        '<img  width="100px" class="nav_logo" src="/assets/loopo-logo_small.png">',
  
  PAGE=             '<span> {{num-pagina}}     </span>',
  PAGES=            '<span> {{num-paginas}}     </span>',
}

export interface SectionComponent{
  deploy: (deploy: boolean) => void;
}