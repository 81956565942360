<script setup lang="ts">
import { Sh } from "@/core/sharedService";
import { EntityListItemDto } from "@/core/store/entities/dtos";
import { useEntity } from "@/core/store/entities/useEntity";
import useUsers from "@/core/store/user/useUsers";
import {
  routesPoperties,
  type PropsRoute,
} from "@/shared/globaEnums";
import DataTablesCore from "datatables.net";
import DataTable from "datatables.net-vue3";
import { ref, computed } from 'vue';
import DataTableDP, {
  type DataTableColumn,
} from "@/views/components/dataTableDP.vue";
import BadgeComponent from "../components/badge.vue";
import entitiesIcon from "../../assets/entities-icon.svg";
import plusButton from "../components/plusButton.vue";
// Definición de las props

DataTable.use(DataTablesCore);
const { entities, loadEntities, currentContext } = useEntity();
const isInit = ref<boolean>(true);
const { user } = useUsers();
const propsRoute: PropsRoute = (<any>routesPoperties)[user.value.role];


const load = async () => {
  const result = await loadEntities();
};
if (!entities.value.length && isInit.value) {
  load();
  isInit.value = false;
  console.trace();
}
//loadEntities();

const buttonLabel = computed(() => {
  const title = propsRoute && propsRoute.title ? propsRoute.title : '';
  const isCliente = title.toLowerCase() === 'grupo de entidades' || title.toLowerCase() === 'cliente';
  const article = isCliente ? 'Nuevo' : 'Nueva';
  return `${article} ${title}`;
});

const newEntity = () => {
  Sh.navigate(`/entity/new`);
};

const Actions = (param1: any, param2: any, param3: any) => {
  const html = `
      <a href="/entity/${param3.id}"><i class="bi bi-eye"></i></a>
      <a href="/entity/${param3.id}"><i class="bi bi-pencil"></i></a> 
      
    `;
  return html;
};
const columns = [
  { data: "name" },
  { data: "vatNumber" },
  { data: "phone" },
  { data: "address" },
  { data: "childrenCount" },
  { render: Actions },
];
const view = (data: EntityListItemDto) => {
  Sh.navigate("/entity/" + data.id);
};
const edit = (data: EntityListItemDto) => {
  Sh.navigate("/entity/" + data.id + "/true");
};
const trash = (data: EntityListItemDto) => {
  //Sh.navigate("/entity/"+data.id+"/true");
};
const cols: DataTableColumn[] = [
  {
    name: "name",
    field: "name",
    header: "Descripción",
    width: "30%",
    orderable: true,
  },
  {
    name: "address",
    field: "address",
    header: "Dirección",
    width: "40%",
    orderable: true,
  },
  {
    name: "phone",
    field: "phone",
    header: "Teléfono",
    width: "15%",
    orderable: true,
  },
  {
    name: "childrenCount",
    field: "childrenCount",
    header: "Entidades",
    width: "10%",
    orderable: true,
  },
  {
    name: "view",
    field: "",
    header: "",
    action: view,
    icon: '<img class="actionIcon" src="/assets/eye_action_icon.png" alt="eye_icon">',
    width: "5%",
    orderable: false,
  },
    //    {
    //   name: "edit",
    //   field: "",
    //   header: "",
    //   action: edit,
    //   icon: '<i class="bi bi-pencil"></i>',
    //   width: "20px"
    // }, 
];
load();
</script>

<template>
  <div class="container-client">
    <div class="header-container">
      <!-- <h2>{{propsRoute && propsRoute.pluralTitle ? propsRoute.pluralTitle  : ""}}</h2> -->
      <BadgeComponent :icon="entitiesIcon" :label="propsRoute && propsRoute.pluralTitle ? propsRoute.pluralTitle : ''" />
      <plusButton :label="buttonLabel" @click="newEntity()"/>
    </div>

    <div v-if="entities" class="table-responsive">
      <DataTableDP :columns="cols" :data="entities" />
    </div>
  </div>
</template>

<style scoped>

* {
  background-color: #fff;
}

.container-client {
  padding: 50px;
  border-radius: 22px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0px 0px 15px #0000002e;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

i {
  cursor: pointer !important;
}

th, td {
  padding: 10px;
}

</style>
