
<script setup lang="ts">
import { type PropType, ref } from 'vue';
import { useForms } from '@/core/store/forms/useForms';
import { useEntity } from '@/core/store/entities/useEntity';

const props = defineProps({
  isOpen: Boolean,
  onClose: Function as PropType<() => void>,
  onSave: Function as PropType<() => void>,
})
const {currentFormItem,selectedEntitiesToForm} = useForms();
const {entities} = useEntity();

const closeDialog = () => {
  
  props.onClose()
};


const saveEntitiesToForm = () => {
  props.onSave()
};
const changeCheck = (event, id) =>{
  const checked = event.target.checked;
  
  if(checked){
    const exist = selectedEntitiesToForm.value?.includes(id);
    if(exist) return;
    selectedEntitiesToForm.value.push(id);
  } else{
    selectedEntitiesToForm.value = selectedEntitiesToForm.value.filter(i => i!== id);
  }

}

</script>
<template>
  <div v-if="isOpen" class="dialog-overlay">
    <div class="dialog">
      <div class="dialogContainer">
        <h4>¿A qué entidades deseas asignar el formulario?</h4>
          <div class="content">
            <div class="item" v-for="group in entities" :key="group.id"   >
              <div class="checkBoxContainer">
                <input type="checkbox" class="formsCustomCheckbox" :value="group.id" :id="group.id" :checked="selectedEntitiesToForm.includes(group.id)" @change="changeCheck($event, group.id)"/>
                <label class="checkBoxLabel" :for="group.id">{{ group.name }}</label>
              </div>
            </div>
          </div>
          <span style="text-align: center; display: inline-block; margin-bottom: 20px; font-size: 12px;">* Si se desasigna un formulario a un Grupo de Entidades, se desasignará a todas las entidades que pertenezcan a ese grupo.</span>
        </div>
        <div class="buttons">
          <button class="btn-orange" @click="closeDialog">Cerrar</button>
          <button class="btn-green" @click="saveEntitiesToForm">Guardar</button>
        </div>
    </div>
  </div>
</template>

<style>

.buttons button {
  float: initial;
}

.buttons button:first-child {
  margin-right: 10px;
}

.buttons {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.dialog .item {
  text-align: left;
}

.dialog input {
  margin-right: 10px;
  width: fit-content;
  display: inline-block;
}

.dialog label {
  display: inline-block;
  width: fit-content;
}


.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialogContainer > h4{
  margin-bottom: 20px;
}

.dialog {
  background-color: #FFF;
  padding: 20px;
  border-radius: 20px;
  width: 600px;
}

.dialogContainer{
  padding: 20px;
  background-color: #D3EDE6;
  border-radius: 20px;
}

.content{
  background-color: #FFF;
  padding: 20px;
  border-radius: 20px;
  column-count: 2;
  margin-bottom: 20px;
}

.checkBoxContainer{
  margin-left: 40px;
}

.checkBoxLabel{
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
}

.formsCustomCheckbox {
  accent-color: var(--primary-color);
}

button {
  margin: 10px;

}
</style>
<script lang="ts">
  export default {
    name: 'assignForm'
  }
</script>