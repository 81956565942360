import CryptoJS from 'crypto-js';
import environment from '@/environments/environments';

export class LocalStorageService {
  // Metodo mediante el cual encriptamos un string y lo guardamos en localStorage encriptado
  static setValue(key: string, value: string|object) {
    if(typeof value === 'object'){
      value = JSON.stringify(value);
    }
    let encrypted =  CryptoJS.AES.encrypt(value, environment.VITE_CRIPTO);
    localStorage.setItem(key, encrypted.toString());
  }
  static remove(key: string) {
    localStorage.removeItem(key);
  }
  // Metodo para obtener la variable encriptada de localStorago desencriptarla y devolverla sin encriptar
  static getValue(key: string): string|any|null {
    let encryptedToken = localStorage.getItem( key );

    try{
      if(encryptedToken){

        let decryptedToken = CryptoJS.AES.decrypt(encryptedToken, environment.VITE_CRIPTO)

        let returned = decryptedToken.toString(CryptoJS.enc.Utf8);
        try{
          returned = JSON.parse(returned);
        }catch{
          return null;
        }
        return returned;
      } else {
        return null;
      }
    }catch(err){
      return null;
    }
  }


}
