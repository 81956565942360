<script setup lang="ts">
import { DisplayType, FieldType, FormDesignItemDto, NOTINPUTITEM, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
import Survey from '@/core/store/survey/surveyStore';
import SurveyItemBase from './surveyItemBase.vue';
import SurveyItemNumber from './surveyItemNumber.vue';
import { computed, CSSProperties, ref } from 'vue';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
import SurveyItemFreeText from './surveyItemFreeText.vue';
import SurveyItemText from './surveyItemText.vue';
import SurveyItemDate from './surveyItemDate.vue';
import SurveyItemDateRange from './surveyItemDateRange.vue';
import SurveyItemYesNo from './surveyItemYesNo.vue';
import SurveyItemDropDown from './surveyItemDropDown.vue';
import SurveyItemGrid from './surveyItemGrid.vue';
import SurveyItemAPIselector from './surveyItemAPIselector.vue';
import SurveyItemFile from './surveyItemFile.vue';
import SurveyItemGraphic from './surveyItemGraphic.vue';
import { Sh } from '@/core/sharedService';
type TextAlign = 'left' | 'right' | 'center' | 'justify';

/**
 * unica propiedad el item 
 */
const props = defineProps({
  item: {
    type: Object as () => FormDesignItemDto,
    required: true
  },
  response: {
    type: Object as () => SurveyResponseItemDto,
    default: null,
    required: false
  }
});
const isEditingLabel = ref<boolean>(false)

const currentStyles = computed<PropertyDisplayType>(() => {
  switch (displayMode.value) {
    case DisplayType.DISPLAY:
      return props.item.properties.screen;
    case DisplayType.EDIT:
      return props.item.properties.input;
    case DisplayType.PDF:
      return props.item.properties.print;
  }
});
const labelAlign = computed<TextAlign>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return displayProp.labelStyle.align.toLowerCase() as TextAlign;
});

const cssLabel = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  const css: CSSProperties = {
    // backgroundColor: 'tomato',
    backgroundColor: 'trasparent',
    fontSize: Sh.getFontSize(displayProp.labelStyle.fontSize), 
    fontWeight: displayProp.labelStyle.bold ? 'bold' : 'normal',
    textDecoration: displayProp.labelStyle.underline ? 'underline' : 'none',
    color: displayProp.labelStyle.color,
    textAlign: labelAlign.value,
    width: '100%',
    cursor: isDesign.value && !isEditingLabel.value ? 'pointer' : 'unset'
  }
  return css;
})

const cssOnEdit = computed<CSSProperties>(() => {

  const css: CSSProperties = { ...cssLabel.value, border: 'none', borderBottom: '1px solid #83B5AC' }
  return css;
})
/**
 *  uso del Store, mediante el hook useSurvey 
 */
const {
  displayMode,
  isDesign,
  showOriginSelector,
  onChangeResponseItem,
} = Survey();

const onChange = (value: any) => { // Todo actualizar state.
  onChangeResponseItem();
}

const onClickLabel = () => {
  if (displayMode.value == DisplayType.EDIT && isDesign.value) {
    isEditingLabel.value = true;
  }
}
const onKeyLabelEdit = (event) => {
  if (event.key === 'Enter') {

    setLabel(event.target.value);
    endEdit();
  }
}
const setLabel = (label: string) => {
  props.item.label = label;
  isEditingLabel.value = false;
}
const endEdit = () => {
  isEditingLabel.value = false;
}
const showOriginItem = computed<boolean>(() => {
  if (props.item && props.item.type == FieldType.FORMREF) return true;
  if (props.item && props.item.inheritItemId) return true;
  return false;
})


const containerClass = computed(() => {
  switch (props.item.type) {
    case FieldType.NUMBER:
      return 'container-number';
    case FieldType.HTML:
      return 'container-html';
    case FieldType.TEXT:
      return 'container-text';
    case FieldType.DATE:
      return 'container-date';
    case FieldType.DATERANGE:
      return 'container-daterange';
    case FieldType.YESNO:
      return 'container-yesno';
    case FieldType.DROPDOWN:
      return 'container-dropdown';
    case FieldType.MULTYDROPDOWN:
      return 'container-multidropdown';
    case FieldType.GRID:
      return 'container-grid';
    case FieldType.FILE:
      return 'container-file';
    case FieldType.GRAPHIC:
      return 'container-graphic';
    case FieldType.FORMULE:
      return 'container-formule';
    case FieldType.APIREST:
      return 'container-api-selector';
    default:
      return '';
  }
});

const containerDisplayClass = computed(() => {
  switch (props.item.type) {
    case FieldType.GRID:
      return 'display-container-grid';
    default:
      return '';
  }
});

const containerPDFClass = computed(() => {
  switch (props.item.type) {
    case FieldType.GRID:
      return 'PDF-container-grid';
    default:
      return '';
  }
});

</script>
<template v-if="item" >
  <SurveyItemBase :item="item">
    <div :class="['survey-item-container', { 
      [containerClass]: isDesign && displayMode === DisplayType.EDIT,
      [containerDisplayClass]: displayMode === DisplayType.DISPLAY,
      [containerPDFClass]: displayMode === DisplayType.PDF
    }]">
      <div class="survey-item-label" :style="cssLabel" v-on:click="onClickLabel">
        <span v-if="!isEditingLabel" :style="cssLabel"> {{ item.label }} {{ (isDesign && showOriginItem ?
          '(Referenciado)' : '' ) }} {{ !NOTINPUTITEM.includes(item.type) && item.required ? '(*)' : "" }}</span>
        <input v-if="isEditingLabel" type="text" v-model="item.label" class="survey-item-label-input" :style="cssOnEdit"
          v-on:keyup="onKeyLabelEdit" v-on:blur="endEdit">
      </div>
      <SurveyItemNumber v-if="[FieldType.NUMBER, FieldType.FORMULE, FieldType.APIREST].includes(item.type)" :item="item" :onChange="onChange" :response="response" />
      <SurveyItemFreeText v-if="item.type === FieldType.HTML" :item="item" :onChange="onChange" />
      <SurveyItemText v-if="item.type === FieldType.TEXT" :item="item" :onChange="onChange" :response="response" />
      <SurveyItemDate v-if="item.type === FieldType.DATE" :item="item" :onChange="onChange" :response="response" />
      <SurveyItemDateRange v-if="item.type === FieldType.DATERANGE" :item="item" :onChange="onChange"
        :response="response" />
      <SurveyItemYesNo v-if="item.type === FieldType.YESNO" :item="item" :onChange="onChange" :response="response" />
      <SurveyItemDropDown v-if="[FieldType.DROPDOWN, FieldType.MULTYDROPDOWN].includes(item.type)" :item="item"
        :onChange="onChange" :response="response" :multiple="item.type === FieldType.MULTYDROPDOWN" />
      <SurveyItemGrid v-if="item.type === FieldType.GRID" :item="item" :onChange="onChange" :response="response" />
      <SurveyItemFile v-if="item.type === FieldType.FILE" :item="item" :onChange="onChange" :response="response" />
      <SurveyItemGraphic v-if="item.type === FieldType.GRAPHIC" :item="item" :onChange="onChange"/>
      <SurveyItemAPIselector v-if="item.type === FieldType.APIREST" :item="item" :onChange="onChange" :response="response" />


    </div>
  </SurveyItemBase>

</template>
<style lang="scss">
@import url(../survey.scss);
</style>
<script lang="ts">
export default {
  name: 'SurveyItem'
}
</script>
