<script setup lang="ts">
import { DisplayType } from '@/core/store/survey/surveyDesignDtos';
import { ref, watch } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import Survey from '@/core/store/survey/surveyStore';
import SurveyPresentationPanel from '../components/survey/surveyPresentationPanel.vue';
import { Sh } from '@/core/sharedService';
import { roles } from '@/shared/globaEnums';
import { Form_status } from '@/core/store/forms/dtos';

const route = useRoute();
const { setMode, isDesign, release, currentResponse } = Survey();
const checkMode = (mode: any): string => {
  Sh.user.role;
  switch (Sh.user.role) {
    case roles.CLIENT:
    case roles.ROOT:
      return mode == "design" ? "design" : "display";
    case roles.GROUP:
      return "display";
    case roles.ENTITY:
    case roles.USER:
    if (currentResponse.value && [Form_status.ASSIGN, Form_status.DRAFT].includes(currentResponse.value.state)) {
        if (mode == "design") return 'display';
        return mode;
      }
  }
  return mode;
}
let mode: any = checkMode(route.params.mode);


const showDialog = ref<boolean>(false)
const formStatus = ref<Form_status | null>(null);

watch(currentResponse, (newResponse) => {
  if (newResponse && newResponse.state) {
    formStatus.value = newResponse.state;
  } else {
    formStatus.value = null;
  }
}, { immediate: true });

switch (mode) {
  case "design":
    setMode(DisplayType.EDIT);
    isDesign.value = true;
    break;
  case "input":
    setMode(DisplayType.EDIT);
    isDesign.value = false;
    break;
  case "display":
    setMode(DisplayType.DISPLAY);
    isDesign.value = false;
    break;
  case "pdf":
    setMode(DisplayType.PDF);
    isDesign.value = false;
    break;

}

onBeforeRouteLeave(async (to, from, next) => {
  if (['design', 'input'].includes(mode) && to.fullPath != "/login") {
    // VERIFICO SI EL USUARIO ES ENTIDAD Y HA CERRADO EL FORMULARIO PARA REDIRIGIRLO A FORMULARIOS CERRADOS.
    if (Sh.user.role === roles.ENTITY && formStatus.value === Form_status.CLOSED) {
      release();
      next(true);
    } else {
      const result = await Sh.showGenericDialog({ title: "Va a abandonar el formulario", message: "¿Está seguro de que desea abandonarlo?" });
      if (result) {
        release();
        next(true);
      } else {
        next(false);
      }
    }
  } else {
    release();
    next(true);
  }
});

</script>

<template>

  <SurveyPresentationPanel :mode="mode" />

</template>


<script lang="ts">
export default {
  name: "SurveyView"
}
</script>