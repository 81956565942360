import { DisplayType, FormDesignItemDto } from "@/core/store/survey/surveyDesignDtos";
import { heightCanvas, widthCanvas } from "@/views/baseLayoutConstants";
import Survey from '@/core/store/survey/surveyStore';
import { CSSProperties } from "vue";
export const surveyStyles = {
  page: {
    width: `${widthCanvas}pt`,
    maxWidth: `${widthCanvas}pt`,
    minHeight: `${heightCanvas}pt`,
    background: "white",
    padding: "0px",
    margin: "0px",
  },

};

/**
      * @type {import('vue').CSSProperties}
      */
export const getSectionStyle = (item: FormDesignItemDto, mode: DisplayType): CSSProperties =>{
  
  const currentStyle = mode == DisplayType.DISPLAY ? item.properties.screen.labelStyle : (mode == DisplayType.EDIT ? item.properties.input.labelStyle : item.properties.print.labelStyle);
  const {isDesign} = Survey();
  switch(mode){
    case DisplayType.DISPLAY:
      
      return {
        width: currentStyle.width ? `${currentStyle.width}%`: "100%" ,
        fontSize: `${currentStyle.fontSize}px` ?? "16px",
        color: currentStyle.color,
        backgroundColor: !isDesign.value ? "var(----dark-section)" :   currentStyle.backgroundColor,
        textAlign: currentStyle.align,
/*         borderRadius: isDesign.value ?  "5px" : 'unset',
        boxShadow: isDesign.value ?  "2px 2px 4px 0px gray" : "unset",
        margin: isDesign.value ?  "5px" : "unset", */
        fontWeight: 'bold',
        
      }
    case DisplayType.EDIT:
      return {
        width: currentStyle.width ? `${currentStyle.width}%`: "100%" ,
        fontSize: `${currentStyle.fontSize}px` ?? "16px",
        color: currentStyle.color,
        backgroundColor: !isDesign.value ? "var(----dark-section)" :   currentStyle.backgroundColor,
        textAlign: currentStyle.align,
/*         borderRadius: isDesign.value ?  "5px" : 'unset',
        boxShadow: isDesign.value ?  "2px 2px 4px 0px gray" : "unset",
        margin: isDesign.value ?  "5px" : "unset", */
        fontWeight: 'bold',
        
      }
    case DisplayType.PDF:
      return {
        width: currentStyle.width ? `${currentStyle.width}%`: "100%" ,
        fontSize: `${currentStyle.fontSize}px` ?? "16px",
        backgroundColor: currentStyle.backgroundColor,
        textAlign: currentStyle.align,
        color: currentStyle.color,
        fontWeight: 'bold',
      }
  }

}