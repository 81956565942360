import type { FileData } from "@/shared/globalInterfaces";

export interface EntityListItemDto   {
  id: string,
  name: string,
  finhavaId: string,
  vatNumber: string,
  phone: string,
  address: string,
  country: string;
  city: string;
  province: string;
  childrenCount: number;
  selected: boolean;
  groupId?: string;
  clientId?: string;
}
export class Entity{
  id: string = "";
  name: string = "";
  finhavaId: string = "";
  entityType: string = "";
  groupId?: string = "";
  clientId?: string = "";
  fileData: FileData = {id: "", url: ""};
  identity : Identity = new Identity();
  user: User = new User();
  childs?: EntityListItemDto[] = [];
}

export class Identity{
  id: string = "";
  name: string = "";
  surname1: string = "";
  surname2: string = "";
  vatNumber: string = "";
  phone: string = "";
  address: string = "";
  country: string = "";
  city: string = "";
  province: string = "";
  postalCode: string = "";
  email: string = "";
}
export class User{
  email: string = "";
  id: string = "";
  password?: string = "";
  role: string = "";
  groupId: string = "";
  status: string = "ACTIVE";
  clientId: string = "";
  entityId: string = "";
  identityId?: string = "";
  identity?: Identity = new Identity();
  entityName?: string;
  groupName?: string;
  clientName?: string;

}

// Generated by https://quicktype.io

export interface FinhavaEntity {
  Id:               string;
  Name:             string;
  Address:          string;
  Email:            string;
  Vat:              string;
  PhoneNumber:            string;
}
export class FinhavaEntityChecked {
  Name:             boolean = true;
  Address:          boolean = true;
  Email:            boolean = true;
  Vat:              boolean = true;
  PhoneNumber:            boolean = true;
}
export const FinhavaToODto = (obj: any) =>{
  const fi: FinhavaEntity = {
    Id:               obj.Id ?? "",
    Name:             obj.Name ?? "",
    Address:          obj.Address ?? "",
    Email:            obj.Email ?? "",
    Vat:              obj.Vat ?? "",
    PhoneNumber:            obj.PhoneNumber ?? "",
  }    
  return fi;
}
