<script setup lang="ts">
import { Sh, SharedService } from "@/core/sharedService";
import { menus, type menuItem } from "../shared/globalInterfaces";
import { useRoute } from "vue-router";
import { useEntity } from "@/core/store/entities/useEntity";
import { computed, ref, watch } from "vue";
import { useForms } from "@/core/store/forms/useForms";
import { useRouter } from "vue-router";
//const {currentComponent, breadCrumb} = RouterEstate();
const { user, loged } = Sh.userStore;
const menu: menuItem[] = menus[user.role.toString()];
const route = useRoute();
const { curEntity, loadChild } = useEntity();
const { getMetrics } = useForms();
const router = useRouter()
getMetrics();
watch(
  () => curEntity.value,
  (newValue, oldValue) => {
    if (newValue.id !== oldValue.id && !Sh.isNullOrEmptyOrZero(newValue.id)) {
      loadChild();
    }
  }
);
const isLinkActive = (link: string) => {
  if (
    link === "/list" &&
    (route.path === "/" ||
      route.path === "/list" ||
      route.path.startsWith("/entity"))
  )
    return true;
  if (link === "/users" && route.path.startsWith("/users")) return true;
  if (link === "/forms" && route.path.startsWith("/forms")) return true;
  return false;
};
if (route.path === "/") {
  //Sh.goDefaultPath();
}
const isLoading = ref<boolean>(false);
SharedService.loadingObserver = (value) => {
  isLoading.value = value;
  if (value) {
    Sh.showToast("Cargando ...");
  } else {
    Sh.hideToast();
  }
};
if (!loged) {
  Sh.router.push("/login");
}
const entityTitle = computed(() => {
  if (user.entityTitle) return user.entityTitle;
  if (user.groupTitle) return user.groupTitle;
  if (user.clientTitle) return user.clientTitle;
  return null;
});

const logout = async () => {
  const result = await Sh.showGenericDialog({
    title: "Va a abandonar la sesión",
    message: "¿Está seguro de que desea cerrar sesión?"
  });

  if (result) {
    Sh.logout();
    localStorage.clear();
    router.push("/login");
  }
};


const isMenuOpen = ref(false);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

</script>

<template>
  <header class="nav_menu">
    <div class="left_nav_menu">
      <div>
        <a href="/">
          <img class="nav_logo" src="../assets/loopo-logo_small.png" alt="logo" />
        </a>
      </div>
      <button class="hamburger" @click="toggleMenu">
        ☰
      </button>

      <!-- UNIFICAR LOS NAV EN UNO SOLO -->
      <nav :class="{ 'open': isMenuOpen }">
        <ul class="nav-list">
          <li v-for="item in menu" :key="item.link">
            <router-link
              class="nav_btn"
              :to="item.link"
              :class="{ 'nav-btn-active': isLinkActive(item.link) }"
              @click="isMenuOpen = false"
              >{{ item.title }}</router-link
            >
          </li>
        </ul>
      </nav>
    </div>

    <div class="right_nav_menu">
      <div class="userInfo">
        <label v-if="entityTitle" class="entityName">{{ entityTitle }} - {{ user.email }}</label>
      </div>
      <div class="logout">
        <button class="btn-orange" @click.prevent="logout">
          Cerrar sesión
        </button>
      </div>
    </div>
  </header>

  <div class="wrapper">
    <RouterView />
  </div>
</template>



<style>



nav:not(.open) .nav-list {
  transform: translateY(14px);
}


.logout{
  transform: translateY(3px);
}

.userInfo{
  transform: translateY(-2px);
  z-index: 10 !important;
}

.hamburger {
  background-color: var(--primary-color);
  height: 50px;
  width: 50px;
  border-radius: 25px;
  color: var(--white);
  border: none;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-color);
  transition: all 0.4s ease;
}

.hamburger:hover {
  background-color: var(--white);
  color: var(--primary-color);
}

.nav_menu nav {
  display: none;
  position: absolute;
  top: 110px;
  left: 300px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px;
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

.nav_menu nav.open {
  display: block;
  opacity: 1; 
  transform: translateY(0);
}

.nav_menu nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav_menu nav ul li {
  margin: 10px;
}

.nav_menu nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  padding-inline: 28px;
  padding-block: 8px;
  height: 42px;
  transition: all 0.4s ease;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--white);
  border-radius: 59px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
}

.nav_menu nav ul li a:hover {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(4px);
}

.nav-btn-active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

@media (min-width: 1375px) {
  .hamburger {
    display: none;
  }

  .nav_menu nav {
    display: flex;
    position: static;
    background: none;
    box-shadow: none;
    padding: 0;
    flex-direction: row;
  }

  .nav_menu nav ul {
    display: flex;
    flex-direction: row;
  }

  .nav_menu nav ul li {
    margin: 0 14px;
  }
}

.nav_menu{
  z-index: 10 !important;
}

</style>
