import { apiRoutes } from "@/core/apiRouters";
import { Sh } from "@/core/sharedService";
import { LocalStorageService } from "@/core/localStorage.service";
import { storeToRefs } from "pinia";
import entityStore from "../entities/entityState";
import { useEntity } from "../entities/useEntity";
import { GlobalState, type Context } from '../global/gobalState';
import type { LoginPostDto, UserListItemDto } from "./dtos";
import { type LoginResponseDto, type SimpleDataDTO } from './dtos';
import { UserStore } from "./userState";
import { ResponseString, roles } from "@/shared/globaEnums";
import { User } from '../entities/dtos';
import { type SimpleFormResponseData } from '../forms/dtos';


 
const useUsers = () => {
  
  const store = UserStore();
  const {pushContext,currentContext} = GlobalState();
  const { reset } = UserStore();
  const {user, error, errors, loged, userList, userEditing } = storeToRefs(store);
   
  const login = async (loginData: LoginPostDto) => {
    try {
      const result = await Sh.api<LoginResponseDto>({
        method: 'POST',
        route: '/users/login',
        data: loginData,
        auth: false
      });
      
      Sh.logout();
      store.login(result);
      Sh.token = result.token;
      
      if(user.value.role!== roles.ENTITY){
        Sh.navigate('/list');
      }else{
        Sh.navigate('/forms/listAssigneds');
      }
      
      LocalStorageService.setValue("user", result);
        const {loadEntity, curEntity, reset} = useEntity();
        
        if(user.value.role!==roles.ROOT){
          await loadEntity();
          const entity = entityStore();
          const context: Context = {
            entity: entity.curEntity,
            user: entity.curEntity.user,
           };
            pushContext(context);
        } else{
          reset();
        }

    }catch(e ){
      store.errorLogin("Usuario o contraseña incorrectos");
    }
  }
  const list = async () => {
    let entityId: string;
    if(currentContext.entity &&  !Sh.isNullId(currentContext.entity.id)){
      entityId = currentContext.entity.id;
    }
    try{

      const result = await Sh.api({
        method: 'GET',
        route: entityId ? apiRoutes.usersListEntity : apiRoutes.usersList,
        params: entityId ? [entityId] : [],
      });
      console.log(result)
      store.setListUser(result as UserListItemDto[]);
    }catch(e){
      console.log("error", e);
    }

  }
  const saveUser = async (rePassword?: string ) => {
    if(!await validUser(rePassword)) return;
    try{
      const isNew = Sh.isNullOrEmptyOrZero(userEditing.value.id);
      const user = await Sh.api<User>({
        method: 'POST',
        route: apiRoutes.userUpdate,
        data: userEditing.value
      });
      const u:UserListItemDto = {
        id: user.id,
        clientName: user.clientName,
        groupName: user.groupName,
        email: user.email,
        entityName: user.entityName,
        name: user.identity ? user.identity.name: "",
        surname1: user.identity ? user.identity.surname1 : "",
        role: user.role
      };
      if(isNew){
        userList.value.push(u)
      }else{
        const uind = userList.value.findIndex(us => us.id === user.id);
        userList.value[uind] = u;
      }
    }catch(e){
      errors.value = [e.toString()];
    }

  }
  const validUser = async (rePasword?: string): Promise<boolean> => {
    errors.value = [];
    console.log("errores", JSON.stringify(errors.value, null, 3))
    const curUser = userEditing.value;
    if(curUser.password && curUser.password.trim().length && curUser.password !== rePasword){
      errors.value.push("user_passwordRepassword");
    }
    const isNew = Sh.isNullOrEmptyOrZero(curUser.id);
    if(isNew && Sh.isNullOrEmptyOrZero(curUser.password)) {
      errors.value.push("user_password") 
    }
    if(Sh.isNullOrEmptyOrZero(curUser.email)){
      errors.value.push("user_email_empty");
    }else if(!Sh.validateEmail(curUser.email)){
      errors.value.push("user_email_invalid");
    }else if(await checkMail(curUser.email)){
      errors.value.push("user_email_found");
    }
    if(curUser.identity && curUser.identity.phone && curUser.identity.phone.trim().length && !Sh.validatePhone(curUser.identity.phone)){
      errors.value.push("identity_phone_invalid");
    }
    return errors.value.length ? false : true;
  }

  const setEdit = async (userId: string|null) => {
    if(!userId || userId ==="new"){
      userEditing.value = new User();
      if(user.value.role !== roles.ROOT){
        userEditing.value.clientId = user.value.clientId;
        userEditing.value.groupId = user.value.groupId;
        userEditing.value.entityId = user.value.entityId;
  
      }
      return;
    }
    try{
      userEditing.value = await Sh.api<User>({
        method: 'GET',
        route: apiRoutes.userById,
        params: [userId]
      });
    }catch(e){
      error.value = "Usuario no encontrado";
      return;
    }
  }
  const checkMail = async (mail: string) => {
    try{
      const res = await Sh.api<any>({
        method: 'GET',
        route: apiRoutes.checkEmailUser,
        params: [mail]
      })
      if(res === "ko") return false;
      if(userEditing.value.id == res) return false;
      return true;
    }catch{
      return false;
    }
  }
  const getDataToDelete = async (userId: string) => {
    try{
      const res = await Sh.api<{forms: SimpleFormResponseData[], users: SimpleDataDTO[], isOwner: boolean}>({
        method: 'GET',
        route: apiRoutes.userGetDataToDelete,
        params: [userId]
      })
      
      console.log("res", res)
      return res;
    }catch(e){
      console.log("error", e);
    }
  }
  const deleteUser = async (userId: string, mode: 'DEACTIVATE' |'DELETE' = 'DELETE', newUserId: string = null):Promise<ResponseString> => {
    try {
      const params: string[] =  [userId, mode];
      if(newUserId){
        params.push(newUserId);
      }
      const res = await Sh.api<ResponseString>({
        method: 'DELETE',
        route: apiRoutes.userDelete,
        params: params
      });
      return res;
    } catch (error) {
      return ResponseString.ERROR;      
    }
    

  }
  const clear = () => {
    reset();
  }
  return {
    login, 
    list,
    clear,
    user,
    error,
    loged,
    userList,
    userEditing,
    setEdit,
    saveUser,
    errors,
    getDataToDelete,
    deleteUser

  }
 }

 export default useUsers;