import { apiRoutes } from '@/core/apiRouters';
import { Sh } from '@/core/sharedService';
import { ResponseString, entityTypes, roles } from '@/shared/globaEnums';
import { storeToRefs } from 'pinia';
import { GlobalState } from '../global/gobalState';
import { UserStore } from '../user/userState';
import { Entity, type EntityListItemDto, FinhavaToODto } from './dtos';
import EntityStore from './entityState';
import { type SimpleDataDTO } from '../user/dtos';
import { type SimpleFormResponseData } from '../forms/dtos';
import { ref } from 'vue';

export const useEntity = () => {
  
  const {setEntities, setCurEntity, setSelectedEntity,  setChildEntities, newEntity, setEditing, resetEntities} = EntityStore();
  const {entities, curEntity, childEntities, selectedEntity, isEditing} = storeToRefs(EntityStore());
  const {currentContext, loading, error} = GlobalState();
  const oldFinhavaId = ref<string>(null!);
  const userStore = UserStore();
  const shared = Sh;
  const {user} = storeToRefs(userStore);
  const loadNewEntity = () => {
    loading();
    const entity = new Entity();
    const curEntity = currentContext.entity;
    if(curEntity && curEntity.id.trim().length){
      entity.entityType= curEntity.entityType===entityTypes.CLIENT ? entityTypes.GROUP : entityTypes.ENTITY;
      entity.clientId = curEntity.entityType===entityTypes.CLIENT ? curEntity.id : curEntity.clientId;
      if(curEntity.entityType === entityTypes.GROUP) entity.groupId = curEntity.id;
    }else{
      entity.entityType = entityTypes.CLIENT;
    }
    newEntity(entity);
    loading(false);
    return entity;
  }
  const saveSelectedEntity = async (entityToSave?: Entity, rePassword?: string): Promise<string[]> =>{
    const errors: string[] = [];
    try{
      loading();
      const entity =  entityToSave ? entityToSave : selectedEntity.value;
      const isNew = entity.id.trim().length ? false: true;
      if(!entity.name.trim().length) errors.push("entity_name");
      
      if(await checkName(entity.name)) errors.push("entity_name");
      if(!entity.identity.phone.toString().trim().length) errors.push("identity_phone");

      if(entity.identity.phone.toString().trim().length && !shared.validatePhone(entity.identity.phone.toString())) errors.push("identity_phone_invalid");

      if(!entity.identity.email.trim().length) errors.push("entity_email");
      if(!shared.validateEmail(entity.identity.email)) errors.push("entity_email_invalid");
      if(!entity.user.email.trim().length) errors.push("user_email_empty");
      if(!shared.validateEmail(entity.user.email)) errors.push("user_email_invalid");
      
      if(await checkMail(entity.user.email.trim())) errors.push("user_email_found");
      
      if(!entity.id.trim().length && (!entity.user.password || !entity.user.password.trim().length)) errors.push("user_password");
      if(entity.user.password && entity.user.password.trim().length && entity.user.password !== rePassword){
        errors.push("user_passwordRepassword");
      }
      if(errors.length) {
        loading(false);
        console.log(errors)
        return errors;
      }

      entity.identity.phone = entity.identity.phone.toString();
      
      const result: Entity = await shared.api({
        method: "POST",
        route: apiRoutes.entityPost,
        data: Sh.clearObject(entity)
      })
      loading(false);
      if(isNew){
        setEntities([...entities.value, {id: result.id, finhavaId: entity.finhavaId, name: entity.name, vatNumber: entity.identity.vatNumber, phone: entity.identity.phone, address: entity.identity.address, childrenCount: 0, country: entity.identity.country, city: entity.identity.city, province: entity.identity.province, selected: true}])
      }else{
        setEntities(entities.value.map((e) => {
          if(e.id == entity.id){
            return {...e, address: entity.identity.address, finhavaId: entity.finhavaId, name: entity.name, vatNumber: entity.identity.vatNumber, phone: entity.identity.phone }
          }else{
            return e;
          }
        } ))
      }
      
      return errors;
    }catch(e: any) {
      error("Error al guardar", e.toString())
      errors.push("error");
    }
    return errors
  }
  const checkName = async (name: string) => {
    try{
      const res = await shared.api<any>({
        method: 'GET',
        route: apiRoutes.checkNameEntity,
        params: [name]
      })
      if(res === "ko") return false;
      if(selectedEntity.value.id == res) return false;
      return true;
    }catch(e){
      console.log("Error", e)
    }
  }
  const checkMail = async (mail: string) => {
    try{
      const res = await shared.api<any>({
        method: 'GET',
        route: apiRoutes.checkEmailUser,
        params: [mail]
      })
      if(res === "ko") return false;
      
      if(selectedEntity.value.user.id == res) return false;
      return true;
    }catch (e){
      console.log("Error checkmal", e)
    }
  }
  const descends = () => {
    switch(user.value.role){
      case roles.ROOT:
        return {type: entityTypes.CLIENT, parentId: null};
      case roles.CLIENT:
        return {type: entityTypes.GROUP, parentId: user.value.clientId};
      case roles.GROUP:
        return {type: entityTypes.ENTITY, parentId: user.value.groupId};
      case roles.ENTITY:
        return {type: entityTypes.ENTITY, parentId: user.value.entityId};
      }
  }
  const loadEntities = async (type?: entityTypes, parentId?: string|null, child?: boolean): Promise<boolean> =>{
    //let url: string = '/entity/list/';
    const parms:string[] = [];
    if(!type){
      const {type: t, parentId: p} = descends();
      type = t;
      parentId = p;
      //type = Sh.selectCase(user.value.role, [[roles.ROOT, entityTypes.CLIENT], [roles.CLIENT, entityTypes.GROUP], [roles.GROUP, entityTypes.ENTITY]]);
    }
    if(!type) return null;
    parms.push(type);
    if(parentId) parms.push(parentId);
    try{
      const res = await shared.api<EntityListItemDto[]>({
        method: 'GET',
        route: parms.length == 1 ?  apiRoutes.entityListType :  apiRoutes.entityListTypeParent,
        params: parms
      })
      setEntities(res!, child);
    }catch(e){
      console.log("ERROR", e)
      return false;
    }

   }
   const loadChild =() => {
    switch(curEntity.value.entityType){
      case entityTypes.CLIENT:
        loadEntities(entityTypes.GROUP, curEntity.value.id);
        break;
      case entityTypes.GROUP:
        loadEntities(entityTypes.ENTITY, curEntity.value.id);
        break;
      default:
        if(user.value.role === roles.ROOT){
          loadEntities(entityTypes.CLIENT);
        }else{
          entities.value = [];
        }
    }
   }
   const loadEntity = async (entityId?: string, selected: boolean=false, toOther: boolean = false) => {
    if(!entityId) entityId = Sh.getParentUser();
    try{
      const res = await 
      shared.api<Entity>({
        method: 'GET',
        route:  apiRoutes.entityGetFull,
        params: [entityId]
      })
      if(!toOther){
        if(selected){
          setSelectedEntity(res!);
          oldFinhavaId.value = res.finhavaId;
          return res!
        }else{
          setCurEntity(res!);
          oldFinhavaId.value = res.finhavaId;
          return res!
        }
      }else{
        oldFinhavaId.value = res.finhavaId;
        return res;
      }

    }catch(e){
      console.log("error an loadEntity", e);
    }
  }
  // Prodesp de borrado
  const getDataToDelete = async (entityId: string) => {
    try{
      const res = await Sh.api<{formResponses:SimpleFormResponseData[], forms: SimpleDataDTO[], entities: SimpleDataDTO[], entitiLevels: SimpleDataDTO[] }>({
        method: 'GET',
        route: apiRoutes.entityGetDataToDelete,
        params: [entityId]
      })
      
      return res;
    }catch(e){
      console.log("error", e);
    }
  }
  const deleteEntity = async (entityId: string, newEntityId: string = null):Promise<ResponseString> => {
    try {
      const params: string[] =  [entityId];
      if(newEntityId){
        params.push(newEntityId);
      }
      const res = await Sh.api<ResponseString>({
        method: 'DELETE',
        route: apiRoutes.entityDelete,
        params: params
      });
      return res;
    } catch (error) {
      return ResponseString.ERROR;      
    }
    

  }
  const checkFinhavaId = async (finhavaId: string) => {
      try{
        const res:any = await Sh.finhavaApi(apiRoutes.finhavaEntityById,[finhavaId] );
        console.log("resultado", res.value);
        if( res.value && res.value.length){
          return FinhavaToODto( res.value[0]);

        }
        if(!res.value) throw ""
        return res;
      }catch(e){
        console.log("error", e);
        throw e
        
      }
  }

   const reset = () => {
    
    resetEntities();
   }
  return { 

    entities,
    curEntity,
    childEntities,
    selectedEntity,
    isEditing,
    currentContext,
    oldFinhavaId,  
    setEditing,
    loadEntities,
    loadEntity,
    loadNewEntity,
    saveSelectedEntity,
    reset,
    loadChild,
    checkName,
    getDataToDelete,
    deleteEntity,
    checkFinhavaId,
  }
}