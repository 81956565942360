<script setup lang='ts'>
  import { Sh, SharedService } from '@/core/sharedService';
import { useRoute, useRouter } from 'vue-router';
import { UseFormListStore } from '../formStore';
import formItemToComplete from '@/views/components/formItemToComplete.vue';
import { ref, watch } from 'vue';

import { form_state, roles } from '@/shared/globaEnums';
import Dialog from '@/views/components/dialog.vue';
import { FormResponseCompletedItemsDto, Item_type } from '@/core/store/forms/dtos';
import useUsers from '@/core/store/user/useUsers';
  const route = useRoute();
  const router = useRouter()
  const itemsRefs = ref([]);
  const isValidate = ref<boolean>(false);
  const isShowDialog = ref<boolean>(false);
  const msgError = ref<string>("");
  let isEdit  = route.params.mode == "edit" ? true : false;
  const repaint = ref<number>(0);
  enum actions {
    SAVE = "SAVE",
    VALIDATE = "VALIDATE",
    FINALLY = "FINALLY",
    REOPEN = "REOPEN",
    PRINT ="PRINT",
    CALCULATE = "CALCULATE",
    EXIT = "EXIT",
  }
  const doAction = async (type: actions) => {
    try{
    switch(type){
      case actions.EXIT:
        router.push("/forms/listAssigneds");
        break;
      case actions.VALIDATE:
        await changeState(form_state.VALIDATED);
        router.push("/forms/listAssigneds");
        break;
      case actions.REOPEN:
         await changeState(form_state.DRAFT);
         router.push("/forms/listAssigneds");
         break;
         
      case actions.CALCULATE:
        calculateValueForm();
        repaint.value++;
        break;
      case actions.SAVE:
          const calc = await calculateValueForm();
          if(calc){
            formToComplete.value.questionsEnded = calc.completed;
            formToComplete.value.questionsRequireds = calc.required;
            formToComplete.value.result = calc.value;
          }
          await saveFormCompleted(false, false);
          break;
      case actions.FINALLY:

        let isValid = true;
        for(const c of itemsRefs.value){
          if(!c.valid()) {
            isValid = false;
          }
        }
        const v = calculateValueForm();
        isValidate.value = isValid
        if(!isValidate.value){
          msgError.value = "El formulario no es válido aún para poder finalizarlo<br>Revise los datos";
          isShowDialog.value = true;
          return;
        }
          await saveFormCompleted(true, false);
        break;
      case actions.PRINT:
        const date = Sh.toDateTime(new Date());
        const calc2 = await calculateValueForm();
          if(calc2){
            formToComplete.value.questionsEnded = calc2.completed;
            formToComplete.value.questionsRequireds = calc2.required;
            formToComplete.value.result = calc2.value;
          }
          await saveFormCompleted(false, false);
        window.open(Sh.urlBase + `/forms/getpdf/${formToComplete.value.formId}/${formToComplete.value.entityId}/${date}`, "_blank")

    }
  }catch(e){
    console.log("pasas por el error", e);
    msgError.value = "Se ha producido el siguiente error:<br>" + e;
    isShowDialog.value = true;
  }
}
  const { 
    formToComplete, saveFormCompleted,calculateValueForm,getValues, changeState
    } = UseFormListStore();
  const {user} = useUsers();
  if(formToComplete.value.id){
    calculateValueForm();
  } else{
    console.log(formToComplete.value);
  }
  

  if(formToComplete.value.id && user.value.entityId!== formToComplete.value.entityId ) isEdit = false;
  
  const {canSave, canValidate, canFinally, canReopen, canCalculate} = {
    canSave: ref<boolean>(formToComplete.value.state!== form_state.CLOSED  && formToComplete.value.state!== form_state.VALIDATED && isEdit),
    canValidate: ref<boolean>(formToComplete.value.state=== form_state.CLOSED && Sh.user.role === roles.CLIENT),
    canFinally: ref<boolean>(formToComplete.value.state!== form_state.CLOSED && formToComplete.value.state!== form_state.VALIDATED && isEdit),
    canReopen: ref<boolean>(formToComplete.value.state=== form_state.CLOSED && (Sh.user.role === roles.GROUP || Sh.user.role === roles.CLIENT) ),
    canCalculate: ref<boolean>(formToComplete.value.state!== form_state.CLOSED && isEdit && formToComplete.value.items.some(i => i.itemTemplate.itemType === Item_type.FORM_FORMULE)),
  }
  
  const avance = (items: FormResponseCompletedItemsDto[]) => {
    let requireds = 0;
    let completes = 0;
    for(const i of items){
      const {completed} = getValues(i);
      if(i.itemTemplate.required && !i.itemTemplate.itemType.startsWith("STATIC") && !i.itemTemplate.itemType.includes("FORMULE")){
        requireds++;
        if(completed) completes++;
      }
    }

      return {requireds, completes};
  }
  const progress = ref<{requireds: number, completes: number}>(avance(formToComplete.value.items));

  watch( () => formToComplete.value.items, (newValue) => {
    const progr = avance(newValue);
    progress.value = progr;
  }, {deep: true})
</script>
<template>
  <div class="formToComplete">
    <input type="hidden" v-model="repaint"/>
    <label v-if="progress.requireds" :class="['progress', {progressOk: progress.completes==progress.requireds, progressKo: progress.completes!=progress.requireds}]">{{  progress.completes.toString() + "/"+progress.requireds.toString() }}  
      <i v-if="progress.completes==progress.requireds" class="bi bi-check-circle-fill custom-icon"></i>
    </label>
    <div class="contentForm">
      <template v-for="(item, inx) in formToComplete.items" :key="inx">
        <formItemToComplete :can-edit="isEdit"   :data="item"  ref="itemsRefs"/>  
      </template>
            
    </div>

    <div class="row">
      <div class="col-11">
        <button v-if="canCalculate"      @click="doAction(actions.CALCULATE)">Calcular resultado</button>
        <button v-if="canSave"      @click="doAction(actions.SAVE)">Guardar</button>
        <button v-if="canValidate"  @click="doAction(actions.VALIDATE)">Validar</button>
        <button v-if="canFinally"   @click="doAction(actions.FINALLY)">Finalizar</button>
        <button v-if="canReopen"    @click="doAction(actions.REOPEN)">Reabrir</button>
        <button v-if="canSave"      @click="doAction(actions.EXIT)">Salir sin guardar</button>
        <button @click="doAction(actions.PRINT)">Imprimir</button>
      </div>
      <div class="col-1">
        <h3 v-if="isValidate"><i class="bi bi-check-circle-fill custom-icon"></i></h3>
      </div>
    </div>
    <template v-if="isShowDialog">
      <Dialog :show="isShowDialog"  :on-close="() => {isShowDialog = false; msgError = ''}">
        <p v-html="msgError"></p>
      </Dialog>
    </template>
      
  </div>
  <div v-if="canSave" class="opaque">


</div>  
</template>
<style>
.custom-icon:before {
  color: green; /* Cambia el color del ícono a blanco */
}
.contentForm{
  max-height: 70vh;
  overflow-y: auto;
}
.progress{
  display: inline-flex;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: -30px;
    height: 30px;
    background: transparent;
  float: right;
}
.progressOk{
  color:green !important;
  
}
.progressKo{
  color: orange !important;
}
  .formToComplete {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 15px #0000002e;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    padding: 50px 30px;
    margin: 50px auto;
    z-index: 9999999;
  }

  .formToComplete h2 {
    color: #005060;
    text-align: center;
  }

  .formToComplete h5 {
    color: #81b4ab;
    font-weight: 400;
    font-size: 25px;
    margin-top: 10px;
  }

  .formToComplete .checkbox input,
  .formToComplete .checkbox label {
    display: inline-block;
    margin-right: 10px;
    width: fit-content;
  }

  .formToComplete .startToFinish div:last-child {
    margin-left: 3%;
  }

  .formToComplete .startToFinish div:nth-child(2) {
    margin-right: 3%;
  }

  .formToComplete .startToFinish div {
    display: inline-block;
    width: 47%;
  }

  .formToComplete label {
    color: #005060;
    font-weight: 400;
    font-size: 18px;
    display: block;
  }

  .formToComplete button {
    background-color: #005060;
    color: #fff;
    border: 0px;
    padding: 7px 45px;
    border-radius: 5px;
    margin-right: 15px;
  }

  .formToComplete .text {
    margin: 20px 0px;
  }
</style>
<script lang="ts">
  export default {
    name: 'completeForm',
  }
</script>@/core/sharedService@/views/components/formItemsDto