<script setup lang="ts">

import DataTablesCore from 'datatables.net';
import DataTable from 'datatables.net-vue3';
import { computed, ref } from 'vue';
import { optionsTable } from '../../common';
import BadgeComponent from "../../components/badge.vue";
import apiIcon from "/assets/API_icon.svg";
import plusButton from "../../components/plusButton.vue";
import CreateApiDialog from './createApiDialog.vue';
import { SurveyApiDto, SurveyApiListItemDto } from '@/core/store/survey/surveyApiDtos';
import Survey from '@/core/store/survey/surveyStore';
import  {GlobalState} from '@/core/store/global/gobalState';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
DataTable.use(DataTablesCore);

const showApiDialog = ref(false);
const currentApiData = ref<SurveyApiDto>(new SurveyApiDto());
const {getListApi, getApi } = Survey();
const {currentContext} = storeToRefs(GlobalState());
const newAPI = () => {
  currentApiData.value = new SurveyApiDto();
  currentApiData.value.clientId = currentContext.value.entity.entityType == "CLIENT" ? currentContext.value.entity.id :  currentContext.value.entity.clientId;

  showApiDialog.value = true;
};

const editAPI = async (id: string) => {

  const api = apis.value.find(api => api.id === id);

  if (api) {
    const r = await getApi(id);
    if(r== false){
      alert("Api no encontrada"); // Todo sustituirlo por algo mas bonito 
      return;
    }

    currentApiData.value = <SurveyApiDto> r;
    showApiDialog.value = true;
  }
};

const closeApiDialog = () => {
  showApiDialog.value = false;
  currentApiData.value = null;
};

const saveApi = (apiData) => {
  // TODO: LÓGICA PARA GUARDAR API
  console.log('Guardando API:', apiData);
  loadAPIs();
};

const options = computed(() => ({
  ...optionsTable,
  pageLength: 3,
  drawCallback: function (settings: any) {
    const rows = settings.aoData;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].nTr;
      const viewSelect = row.querySelector('.viewSelect');
      const editSelect = row.querySelector('.editSelect');
      if (viewSelect) {
        viewSelect.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          copyApi(data.id);
        });
      }
      if (editSelect) {
        editSelect.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          editAPI(data.id);
        });
      }
    }
  }
}));



const actions = () => {
  return `
    <img class="actionIcon viewSelect" src="/assets/copy_icon.png" alt="eye_icon">
    <img class="actionIcon editSelect" src="/assets/pen_action_icon.png" alt="pen_icon">
  `;
};

const copyApi = async (id: string) => {
  const api = apis.value.find(api => api.id === id);

  if (api) {
    const r = await getApi(id);
    if(r==false) return;
    const a:SurveyApiDto = r as SurveyApiDto;

     a.id= uuidv4();
     a.name = a.name.trim() + " copia";
     a.isNew = true;
     currentApiData.value = <SurveyApiDto> a;
    showApiDialog.value = true;

  }
  console.log("Viendo API con ID:", id);
};

const columns = [
  { data: 'name', width: '300' },
  { data: 'description', width: '650' },
  { data: 'url', width: '200', orderable: false},
  { data: 'method', width: '100', orderable: false},
  { data: null, render: actions, orderable: false, width: '100' },
];


const apis = ref<SurveyApiListItemDto[]>([]);

const loadAPIs = async () => {
  const ap =  await getListApi();
  if(ap!==false){
    apis.value = <SurveyApiListItemDto[]> ap;
  }else{
    apis.value = [];
  }

};

loadAPIs();
const closeDialog = () => {
  showApiDialog.value = false;
  //currentApiData.value = null;
  loadAPIs();
}


</script>

<template>
  <div class="container-client">

    <div class="header-container">
      <BadgeComponent :icon="apiIcon" label="APIs Disponibles" />
      <plusButton label="Nueva API" @click="newAPI()" />
    </div>

    <div class="table-responsive">
      <DataTable :options="options" :data="apis" :columns="columns" width="100%">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>URL</th>
            <th>Método</th>
            <th class="actionsTh"></th>
          </tr>
        </thead>
      </DataTable>
    </div>
    
  </div>

    <CreateApiDialog
        :show="showApiDialog"
        :apiData="currentApiData"
        :onClose="closeDialog"
      />
    
</template>



<style scoped>
.dataTables_length {
  display: none;
}

* {
  background-color: #fff;
}

.container-client {
  padding: 50px;
  border-radius: 22px;
  max-width: 80%;
  width: 100%;
  margin: -56px auto;
  box-shadow: 0px 0px 15px #0000002e;
}

.header-container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  width: 100%;
  text-align: left;
  margin-top: -30px;
}

.table-responsive th {
  padding: 5px;
  text-align: left;
}


@media (max-width: 1400px) {

  .header-container {
    margin-top: -15px;
    margin-bottom: 70px;
  }

}

.actionsTh {
  display: flex;
  flex-direction: row;
  min-width: 90px;
}

@media (max-width: 1190px) {
  .actionsTh {
    height: 56px !important;
  }
}
</style>
