import { Form_status } from "../forms/dtos";
import { v4 as uuidv4 } from 'uuid';
import { DisplayType, FormDesignDto } from "./surveyDesignDtos";
import { Sh } from "@/core/sharedService";

export class SurveyResponseCompleteDto{
  design: FormDesignDto;
  response?: SurveyResponseDto;
}

export class SurveyResponseDto{
  id: string = uuidv4();
  state: Form_status; 
  questionsRequireds: number; 
  questionsEnded: number; 
  closeAt: Date;
  entityId: string; 
  formId: string; 
  userId: string; 
  user: string;
  result: number;
  items: SurveyResponseItemDto[] = [];
  isNew: boolean = true;
  static toSend(survey: SurveyResponseDto){
    
    const {items, ...rest} = survey;
    rest["items"] = survey.items.map(i => SurveyResponseItemDto.toSend(i));
    return rest;
  }
}
export class SurveyResponseItemDto{
  id: string = uuidv4();  
  value: any; 
  userId: string; 
  user: string; 
  formsDesignItemId: string; 
  formResponseId: string; 
  static toSend(item: SurveyResponseItemDto){
    const {value,...rest} = item;
    
    rest["value"] = Sh.toJson(value);
    return rest;
  }
}
export class ResponseContext{
  displayMode: DisplayType = DisplayType.DISPLAY;
  state: Form_status = Form_status.ASSIGN;
  canEdit: boolean = false;
  canClose: boolean = false;
  canAprobe: boolean = false;
}
export class SurveyResponseValueDateRange{
  init: string = ''; 
  end: string = '';
  toJSON() {
    const {init, end} = this;
    return {init, end};
  }
  
  static fromValue = (value: string): SurveyResponseValueDateRange => {
    if(!value || !value.trim().length){
      return new SurveyResponseValueDateRange();
    }
    try{
      const val = JSON.parse(value);
      if(!val.init) val.init = "";
      if(!val.end) val.end = "";
      const {init, end} = val;
      return Object.assign(new SurveyResponseValueDateRange(), {init, end} );
    }catch{
      return new SurveyResponseValueDateRange();
    }
  }

  toValue = () => {
    return JSON.stringify(this);
  }
}

export class SurveyResponseValueFile{
  id: string = ''; 
  url: string = '';
  originalName: string = "";
  toJSON() {
    const {id, url, originalName} = this;
    return {id, url, originalName};
  }
  
  static fromValue = (value: string|any): SurveyResponseValueFile => {
    if(!value || !value.trim().length){
      return new SurveyResponseValueFile();
    }
    try{
      const val = typeof value == 'string' ? JSON.parse(value) : value;
      if(!val.id) val.id = "";
      if(!val.url) val.url = "";
      if(!val.originalName) val.originalName = "";
      const {id, url, originalName} = val;
      const newValue= new SurveyResponseValueFile();
      newValue.id = id;
      newValue.url = url;
      newValue.originalName = originalName;
      return newValue;
    }catch{
      return new SurveyResponseValueFile();
    }
  }

  toValue = () => {
    return JSON.stringify(this);
  }
}
/* 
FieldType.NUMBER: Float,
FieldType.TEXT: string,
FieldType.DATE: string (date format),
FieldType.DATERANGE: {init: string, end: string}
FieldType.YESNO: boolean,
FieldType.DROPDOWN: number[] índices del selector
FieldType.MULTYDROPDOWN: number[] índices del selector
FieldType.GRID:  any[] (array de fechas, números o texto)
FieldType.FILE: SurveyResponseValueFile */