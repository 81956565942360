<script setup lang="ts">
  import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
  import { SurveyResponseItemDto, SurveyResponseValueFile } from '@/core/store/survey/surveyResponseDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import { computed, CSSProperties, PropType, Ref, ref, watch } from 'vue';

  import { Sh } from '@/core/sharedService';
  import { apiRoutes } from '@/core/apiRouters';
  const {
      displayMode, 
      isDesign,
      } = Survey();

   const props  = defineProps({
    item: {
      type: Object as () => FormDesignItemDto,
      required: true
    },
/*     response: {
      type: Object as () => SurveyResponseItemDto,
      default: null,
      required: false
    }, */
    onChange: {
      type: Function as PropType<( value: any) => void>,
      default: null,
      required: false,
    },
  });
  const currentStyles = computed<PropertyDisplayType>(()=>{
      switch(displayMode.value){
        case DisplayType.DISPLAY: 
          return  props.item.properties.screen;
        case DisplayType.EDIT: 
          return  props.item.properties.input;
        case DisplayType.PDF: 
          return  props.item.properties.print;
      }
  });

  const cssGraphic = computed<CSSProperties>(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return {
      border: 'none',
      width: displayProp.contentStyle.width ?  displayProp.contentStyle.width + "px" : "100px",

    }
  });
  const cssContainer = computed<CSSProperties>(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return {
      border: 'none',
      textAlign: displayProp.contentStyle.align

    }
  });


  const fileInput = ref<HTMLInputElement | null>(null);
  const value = ref<SurveyResponseValueFile>(props.item && props.item.content ? SurveyResponseValueFile.fromValue(props.item.content) : new SurveyResponseValueFile());
  
  watch(value, (newValue) => {
      if(JSON.stringify(newValue) !== props.item.content){
        props.item.content = JSON.stringify(newValue);
      }
  });
const handleDrop = async (event: DragEvent)  => {
  event.preventDefault();
  const droppedFiles = event.dataTransfer?.files;
  if (droppedFiles && droppedFiles.length > 0) {
    const file = droppedFiles[0];
    if(!validateAndProcessFile(file)) return;
    processFile(file);
  }
};
const validateAndProcessFile = (file: File):boolean => {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSize = 10485760; // 10 MB EN BYTES
  if (!acceptedTypes.includes(file.type)) {
    Sh.showToast("Tipo de archivo no soportado. Solo se permiten jpg, png o gif", 10);
    return false;
  }
  if (file.size > maxSize) {
    Sh.showToast("Archivo demasiado grande, máximo 10Mb", 10);
    return false;
  }
  return true;
};
const handleFileSelect = () => {
  const files = fileInput.value?.files;
  if (files && files.length > 0) {
    const file = files[0];
    if(!validateAndProcessFile(file)) return;
    processFile(file);
  }
};

// Función para procesar el archivo
const processFile = async (file: File) => {
  try{
      const formData = new FormData();
      formData.append("file", file);
      const result = await Sh.api<{id: string, url: string, originalName: string}>({
        method: "POST",
        route: apiRoutes.files,
        data:  formData,
        type: 'multipart'
      });
      const fileData = new SurveyResponseValueFile();
      fileData.id = result.id;
      fileData.originalName = result.originalName;
      fileData.url = result.url;
      value.value = fileData;

    }catch(e){
      console.log("ERror", e)
    }
};

// Método para abrir el selector de archivos
const openFileDialog = () => {
  fileInput.value?.click();
};

const isCurrentVisible = computed(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return displayProp.visible || isDesign;
  });
</script>


<template>
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible ">
    <div class="drowpdown-menu "  :style="cssContainer"
      @dragover.prevent
      @drop="handleDrop"
      @click="openFileDialog">
      <input type="file" ref="fileInput" @change="handleFileSelect" hidden accept=".jpg,.jpeg,.png,.gif">
      <label v-if="!value.url">Arrastre un archivo (jpg, png o gif) o pulse aquí para buscarlo</label>
      <img v-if="value.url" :style="cssGraphic" :src="value.url">
    </div>
  </div>
  <div v-if="displayMode == DisplayType.DISPLAY  && isCurrentVisible" :style="cssContainer">
    <img v-if="value.url" :style="cssGraphic" :src="value.url">
  </div>  
  <div v-if="displayMode == DisplayType.PDF  && isCurrentVisible" :style="cssContainer">
    <img v-if="value.url" :style="cssGraphic" :src="value.url">
  </div>  
</template>

<style lang="css">
  .dialogUpload{
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-around;
    align-items: center;
  }
  .dialogUpload label{
    margin: auto;
    text-align: center;
    padding: 20px;
  }

</style>
<script lang="ts">
  export default {
    name: "SurveyItemGraphic"
  }
</script>