import { default } from './surveyItem.vue';
<script setup lang="ts">
import { Sh } from '@/core/sharedService';
import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
import Survey from '@/core/store/survey/surveyStore';
import { computed, CSSProperties, PropType, ref, watch } from 'vue';
const {
  displayMode,
  isDesign,
} = Survey();

const props = defineProps({
  item: {
    type: Object as () => FormDesignItemDto,
    required: true
  },
  response: {
    type: Object as () => SurveyResponseItemDto,
    default: null,
    required: false
  },
  onChange: {
    type: Function as PropType<(value: any) => void>,
    default: null,
    required: false,
  },
});

const currentStyles = computed<PropertyDisplayType>(() => {
  switch (displayMode.value) {
    case DisplayType.DISPLAY:
      return props.item.properties.screen;
    case DisplayType.EDIT:
      return props.item.properties.input;
    case DisplayType.PDF:
      return props.item.properties.print;
  }
});

const cssInput = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return {
    backgroundColor: displayProp.contentStyle.backgroundColor,
    borderRadius: '9999px',
    border: 'none',
    fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
    color: displayProp.contentStyle.color,
    width: '100%',

  }
});

const value = ref<{ init: string, end: string }>(props.response && props.response.value ? props.response.value : { init: '', end: '' });
const valueInit = ref<string>(value.value.init);
const valueEnd = ref<string>(value.value.end);

watch(value, (newValue) => {
  if (props.response) {
    if (JSON.stringify(newValue) !== props.response.value) {
      props.response.value = newValue;
      props.onChange(newValue);
    }
  }
});
watch(valueInit, (newValue) => {
  value.value = { ...value.value, init: newValue };
})
watch(valueEnd, (newValue) => {
  value.value = { ...value.value, end: newValue };
})
const isCurrentVisible = computed(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return displayProp.visible || isDesign;
});

const startDate = ref<string>('');
const endDate = ref<string>(''); 
const errorMessage = ref<string>('');

watch([valueInit, valueEnd], ([newStart, newEnd]) => {
  if (new Date(newEnd) < new Date(newStart)) {
    errorMessage.value = "La fecha de fin no puede ser anterior a la fecha de inicio";
  } else {
    errorMessage.value = "";
  }
});



</script>
<template>
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible" class="surve-item-data-range">
    <div class="survey-item-data-range-date startDate">
      <span>Inicio</span>
      <input type="date" class="survey-item-input survey-input-number-buttons-none" :style="cssInput"
        v-model="valueInit" :readonly="item.inheritItemId != null && item.inheritItemId.trim().length != 0">
    </div>
    <div class="survey-item-data-range-date endDate">
      <span>Fin</span>
      <input type="date" class="survey-item-input survey-input-number-buttons-none" :style="cssInput" v-model="valueEnd"
        :readonly="item.inheritItemId != null && item.inheritItemId.trim().length != 0">
    </div>
    <!-- Mostrar el mensaje de error -->
    <div v-if="errorMessage" class="errorContainerDate">
      <span class="errorSpanDate">{{ errorMessage }}</span>
    </div>
  </div>
  <div v-if="displayMode == DisplayType.DISPLAY && isCurrentVisible" class="surve-item-data-range">
    <div class="survey-item-data-range-date startDate">
      <span>Inicio</span>
      <input type="date" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" readonly
        tabindex="-1" :style="cssInput" v-model="valueInit">
    </div>
    <div class="survey-item-data-range-date endDate">
      <span>Fin</span>
      <input type="date" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" readonly
        tabindex="-1" :style="cssInput" v-model="valueEnd">
    </div>
  </div>
  <div v-if="displayMode == DisplayType.PDF && isCurrentVisible" class="surve-item-data-range">
    <div class="survey-item-data-range-date startDate">
      <span>Inicio</span>
      <input type="date" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" readonly
        tabindex="-1" :style="cssInput" v-model="valueInit">
    </div>
    <div class="survey-item-data-range-date endDate">
      <span>Fin</span>
      <input type="date" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" readonly
        tabindex="-1" :style="cssInput" v-model="valueEnd">
    </div>
  </div>
</template>

<style lang="scss">
@import url(../survey.scss);

.surve-item-data-range {
  position: relative;
}

.errorContainerDate {
  position: absolute;
  right: 0;
  bottom: -45px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.errorSpanDate {
  background-color: var(--red);
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 5px;
  animation: blinkAnimation 1.2s infinite;
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script lang="ts">
export default {
  name: "SurveyItemDateRange"
}
</script>