<script setup lang='ts'>
  import  DataTablesCore  from 'datatables.net';
  import  DataTable  from 'datatables.net-vue3';
import {  type PropType, type Ref } from 'vue';
import { optionsTable } from '../common';
import { Sh } from '@/core/sharedService';
  DataTable.use(DataTablesCore);
  type DataArray = Ref<any[]>; // Tipo Array
  type DataAny = any; // Tipo Any
  const props = defineProps({
    columns: {
      type: Array as () => DataTableColumn[],
      required: true,
    },
    data:{
      type: Object as () => any,
      required: true
    }, 
    tag:{
      type: Object as () => any,
      required: false
    }, 
    searchAction: {
      type: Function as PropType<() => void>,
      required: false
    }, 
    searchLabels: {
      type: Object as () => SearchLabel,
      requred: false
    },
    rowClick: {
      type: Function as PropType<(data:any, tag: any) => void>,
      requred: false
    }
  });
  
  const drawTable = (settings: any) =>{
    const actions = props.columns.filter(c => c.action);
    if(actions.length==0 && !props.rowClick) return;
    const rows = settings.aoData;
    
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].nTr;
      
      if(props.rowClick){
        row.removeEventListener('click', () => props.rowClick(settings.aoData[i]._aData, props.tag));
        row.addEventListener('click', () => props.rowClick(settings.aoData[i]._aData, props.tag));
        row.classList.add("hover");
      }
      for(const a of actions){
        const sel = row.querySelector('.data-' + a.name)
        if(sel){
          sel.removeEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          a.action(data, props.tag);
        });
          sel.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          a.action(data, props.tag);
        });
        }
      }

    }
  }
  const isVisible = (col:DataTableColumn):boolean => {
    if(col.visible === undefined) return true;
    if(typeof col.visible === 'boolean') return col.visible;
    return col.visible(col.field, props.tag);
  }

  const options = {
    ...optionsTable, 
    drawCallback: drawTable,
    pageLength: 5, 
  };

  const columns = props.columns.filter(c => isVisible(c)).map(c => {
    const colProp: any = {};
    if(c.field && !Sh.isNullOrEmptyOrZero(c.field)){
      colProp.data = c.field.trim();
    }
    if((c.render && !Sh.isNullOrEmptyOrZero(c.render)) || c.isDate || c.isDateEng || c.isNumber || c.icon){

      if(c.isDate|| c.isDateEng){
        colProp.render = (p1:any, p2:any, p3:any) =>  Sh.toShortDate(p3[c.field], !!c.isDateEng);
      }else if(c.isNumber){
        colProp.render = (p1:any, p2:any, p3:any) =>  Sh.formatNumber(p3[c.field]);
      }else if(c.icon){
        colProp.render = () => c.icon;
      }else{
        colProp.render = (p1:any, p2:any, p3:any) =>  c.render(p3, c.field, props.tag);
      }
    }
    
    colProp.orderable = !!c.orderable;
    if(c.width){
      colProp.width = c.width;
    }
    colProp.class = "data-"+c.name;
    if(c.action!!){
      colProp.class+= " cursor" 
    }
    colProp.visible = isVisible(c)
    return colProp;
  })


</script>

<template>
<DataTable :options="options" :data="data" :columns="columns" width="100%">
  <thead>
    <tr>
      <template v-for="(col, index) in props.columns" :key="index">
        <th class="tableRow" v-if="isVisible(col)" >{{ col.header ? col.header : "" }}</th>
      </template>
      
    </tr>

  </thead>

</DataTable>
</template>

<style scoped>

th, td {
  padding: 10px; /* Añadir padding para la separación */
}

</style>

<script lang="ts">
  export interface DataTableColumn{
    name: string,
    header?: string,
    field?: string,
    isDate?: boolean,
    isDateEng?: boolean,
    width?: string;
    isNumber?: boolean,
    orderable?: boolean,
    visible?: boolean | ((col: string, tag?:any) => boolean),
    action?: (data: any, tag?: any) => void,
    render?: (data: any, field: string, tag?: any) => string,
    icon?: string
  }
  export interface SearchLabel{
    label: string,
    placeHolder?: string, 
    icon?: string
  }

  export const optionsTableDp = {
  "language": {
    "search": '<img src="/assets/search.svg" alt="">',
    "searchPlaceholder": "Buscar",
    "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
    "paginate": {
      "first": "Primero",
      "last": "Último",
      "next": "Siguiente",
      "previous": "Anterior"
    },
    //"url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
  }
}
export default {
  name: "DataTableDP"
}

</script> 
