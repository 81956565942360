import { apiRoutes } from "@/core/apiRouters";
import { Sh } from "@/core/sharedService";
import { FormDto, FormResponseCompletedDto, FormResponseCompletedItemsDto, Item_type } from "@/core/store/forms/dtos";
import { FormState } from "@/core/store/forms/formState";
import { useForms } from "@/core/store/forms/useForms";
import useUsers from "@/core/store/user/useUsers";
import { form_state, roles } from "@/shared/globaEnums";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, toRef, toRefs } from "vue";

const numRows = 0;
export class SearchAssigned{
  entityId: string = "";
  state?: string = "";
  key: string = "";
  page: number = 0;
  rows: number = numRows;
  states: string[]  = [];
  orderBy: ordersBy = ordersBy.endDate;
  orderType: "ASC"|"DESC" = "DESC";
}
export class SearchResult {
  id: string ="";
  name: string ="";
  entityId: string ="";
  entityName: string ="";
  endDate: string ="";
  state: string ="";
  count: number = 0;
  questionsRequireds: number  = 0;
  questionsEnded: number  = 0;
  result: number  = 0;
  child?: SearchResult[] = [];
}
export enum ordersBy {
  endDate = "endDate",
  name = "name",
  state = "state",
  entityName = "entityName",
}
export const FormListStore = defineStore('formList', () =>{
  const formToComplete = ref<FormResponseCompletedDto>(new FormResponseCompletedDto());
  const search = ref<SearchAssigned>(new SearchAssigned());
  const currentForm = ref<FormDto>(new FormDto());
  const currentResponse = ref<FormResponseCompletedDto>(new FormResponseCompletedDto());
  const forms = ref<SearchResult[]>([]);
  const hasMore = ref<boolean>(true);
  const isVisibleMenu = ref<boolean>(true);
  const load = (data: SearchResult[], add: boolean = false) => {
      if(add){
        forms.value = [...forms.value, ...data];
      }else{
        forms.value = data;
      }
      if(data.length< search.value.rows){
        hasMore.value = false;
      }else{
        hasMore.value = true;
      }
  }

  function searchEntityId(array: SearchResult[], entityId: string, formId: string): SearchResult | null {

    for (const item of array) {
      if (item.entityId === entityId && item.id === formId) {
        return item;
      }
      if (item.child && item.child.length > 0) {
        const found = searchEntityId(item.child, entityId, item.id);
        if (found) {
          return found;
        }
      }
    }
    
    return null;
  }
  const addChilds = (entityId: string, formId: string, data: SearchResult[] ) => {
     const item = searchEntityId(forms.value, entityId, formId);
     if(!item) return;
     item.child = data;

  }
  const clearSearch = () => {
    search.value = new SearchAssigned();
    hasMore.value = true;
  }
  const clearForms = () => {
    forms.value = [];
    hasMore.value = true;
  }
  const setFormToComplete = (form: FormResponseCompletedDto) =>{
    formToComplete.value = form;
  }
  const releaseState = () => {
    formToComplete.value = new  FormResponseCompletedDto();
    search.value = new  SearchAssigned();
    currentForm.value = new FormDto ();
    currentResponse.value  = new FormResponseCompletedDto();
    forms.value = [] ;
    hasMore.value = true;
  }
  return {
    search, 
    forms,
    hasMore,
    currentForm,
    currentResponse,
    formToComplete,
    isVisibleMenu,
    // Actions
    load,
    addChilds,
    clearSearch,
    clearForms,
    setFormToComplete,
    releaseState
  }
}, {persist: true});

export const UseFormListStore = () => {
  const formStore = FormListStore();
  const {load: loadForms, addChilds, clearSearch, clearForms, setFormToComplete, releaseState} = formStore;
  const {search, forms, hasMore, currentForm, currentResponse, formToComplete, isVisibleMenu } = storeToRefs(formStore);
  const {getForm, currentForm:formFromUseForm, getCompletedForm, saveCompleteForm, changeStatusCompleteForm} = useForms();
  const {user} = useUsers()
  const load = async (): Promise<SearchResult[]>  => {
    if(user.value.role == roles.GROUP || user.value.role == roles.ENTITY){
      search.value.entityId =  user.value.role == roles.GROUP  ? user.value.groupId :  user.value.entityId;
    }

    try{
      
      const data = await Sh.api<SearchResult[]>({
        method: 'POST',
        route: apiRoutes.formAssigns,
        data: search.value
      });
      return data;
    }catch(e){
      throw e;
    }
  }
  const init = async () => {
    try{
      search.value.entityId = user.value.entityId;
      loadForms(await load())
    }catch(e){
      throw e;
    }
  }
  const page = async (page: number, rows: number = numRows) => {
    search.value.page = page;
    search.value.rows = rows;
    
    loadForms(await load(), true);
  }
  const getMore= async() => {
    if(hasMore){
      search.value.page++;
      loadForms(await load(), true);
    }
  }
  const getChild= async(formId: string, entityId: string) => {
    try{
      const data = await Sh.api<SearchResult[]>({
        method: 'POST',
        route: apiRoutes.formAssigns,
        data: {...search.value, entityId: entityId, formId: formId}
      });
      addChilds(entityId, formId, data);
    }catch(e){
      
    }
  }

  const searchKey = async (key: string) => {
    clearSearch();
    search.value.key = key;
    loadForms(await load());
  }
  const setOrder = async (order: ordersBy, orientation: "ASC"|"DESC" = 'DESC' ) => {
    const key = search.value.key;
    clearSearch();
    search.value.key = key.trim().length ? key : "";
    search.value.orderBy = order;
    search.value.orderType = orientation;
    loadForms(await load());
  }
  const loadDefaultResponseForm = (formId: string, entityId: string, toComplete: FormResponseCompletedDto ) => {
    const items = currentForm.value.items;
    
    const its: FormResponseCompletedItemsDto[] = [];
    for(const i of items){
      let item = toComplete.items.find(it => it.formItemId == i.id);
      
      if(!item){
        item = new FormResponseCompletedItemsDto();
        item.formItemId = i.id;
        
        
      }
      its.push(item);
      item.itemTemplate = {...i};
      if(item.value && item.value.trim().length){
        item.valueObj = JSON.parse(item.value);
      }else{
        switch(i.itemType){
          case Item_type.FORM_COMBOBOX_MULTIPLE:
            item.valueObj = [];
            break;
          case Item_type.FORM_DATE_RANGE:
            item.valueObj = {init: "", end: ""};
            break;
          case Item_type.FORM_YES_NO:
            item.valueObj = {value: false};
            break;
          case Item_type.FORM_NUMBER:
          case Item_type.FORM_FINHAVA:
          case Item_type.FORM_COMBOBOX:
            item.valueObj = {value: 0};
            break;
          case Item_type.FROM_IMAGE:
            item.valueObj = {id: "", url: "", originalName: ""}
            break;
          default:
            item.valueObj = {value: ""}
            break;
        }
      }
    }
    toComplete.items = its;
  }
  const getTocompleteForm = async (formId: string, entityId: string) => {
    try{

      const f = await getForm(formId);
      let toComplete:FormResponseCompletedDto = new FormResponseCompletedDto();
      currentForm.value = formFromUseForm.value;
      let found: boolean = false;
      if(!f) throw "Formulario inexistente";
      try{
        const a:FormResponseCompletedDto = await  getCompletedForm(formId, entityId);
        toComplete = a;
        found = true;
      }catch(e){
        
        toComplete.formId = formId;
        toComplete.entityId = entityId;
        toComplete.state = form_state.DRAFT;
        toComplete.items = [];
        toComplete.name = currentForm.value.cab.name;
        toComplete.userId = user.value.id;
        toComplete.userEmail = user.value.email;
        
      }
      loadDefaultResponseForm(formId, entityId, toComplete);
      setFormToComplete(toComplete);
      if(found) calculateValueForm();
    }catch(e){
      console.log("No puede iniciarse el formulario", e)
      throw e;
    }
  }
  const getValues = (item: FormResponseCompletedItemsDto) => {
    const i = item.itemTemplate;
    let value: number = 0;
    let isEmpty = true;
    let weight = item.itemTemplate.weight;
    switch(i.itemType){
      case Item_type.FORM_TEXT:
        if(item.valueObj.value) {
          value = item.itemTemplate.weight;
          weight = item.itemTemplate.weight;
        }else{
          value = item.itemTemplate.weightEmpty;
          weight = item.itemTemplate.weightEmpty;

        }
        if(item.valueObj.value && typeof item.valueObj.value && item.valueObj.value.trim().length>0){
          isEmpty = false;
        }
        break;
      case Item_type.FORM_COMBOBOX_MULTIPLE:
        if(Array.isArray(item.valueObj) && item.valueObj.length!==0 ){
          const vals = Sh.parseStringToObjects(i.value, i.weight);
          
          (<number[]>item.valueObj).map(v => value+= vals[v].value);

          if(value != 0) isEmpty = false;
          weight = item.itemTemplate.weight;
        }else{
          weight = item.itemTemplate.weightEmpty;
        }
        break;
      case Item_type.FORM_DATE_RANGE:
        let init = 0;
        let end = 0;
        if(item.valueObj.init && item.valueObj.init.trim().legth !==0){
          init = new Date(item.valueObj.init).getTime();
        }
        if(item.valueObj.end && item.valueObj.end.trim().legth !==0){
          end = new Date(item.valueObj.end).getTime();
        }
        
        if(init !==0 && end !== 0){
          isEmpty = false;
          weight = item.itemTemplate.weight;
        } else{
          weight = item.itemTemplate.weightEmpty;
        }
        value = ((end - init) / 1000) / 86400;
        break;
      case Item_type.FORM_FINHAVA:
        value = item.valueObj.value;
        weight = item.valueObj.value;
        isEmpty = value !=0;
        break;
      case Item_type.FORM_YES_NO:
        if(item.valueObj.value) {
          value = item.itemTemplate.weight;
          weight = item.itemTemplate.weight;
        }else{
          value = item.itemTemplate.weightEmpty;
          weight = item.itemTemplate.weightEmpty;

        }
        isEmpty = false;

        break;
      case Item_type.FORM_COMBOBOX:
        const valsCmb = Sh.parseStringToObjects(i.value, i.weight);
        value = valsCmb[item.valueObj.value].value ? valsCmb[item.valueObj.value].value : item.itemTemplate.weight;
        if(value !==0 ){
          isEmpty = false;
          weight = item.itemTemplate.weight;
        } else{
          weight = item.itemTemplate.weightEmpty;
        }
        break;
      
      case Item_type.FORM_NUMBER:
          value = item.valueObj.value;
          if(value !==0){
            isEmpty = false;
            weight = item.itemTemplate.weight;
          } else{
            weight = item.itemTemplate.weightEmpty;
          }
        break;
      case Item_type.FROM_IMAGE:
        if(!Sh.isNullOrEmptyOrZero(item.valueObj.id)) {
          isEmpty = false;
          value = item.itemTemplate.weight;
          weight = item.itemTemplate.weight;
        }else{
          weight = item.itemTemplate.weightEmpty;
        }
        break;
      default:
        if(!Sh.isNullOrEmptyOrZero(item.valueObj.value)){
          weight = item.itemTemplate.weight;
          isEmpty = false;
        } else{
          weight = item.itemTemplate.weightEmpty;
        }
        break;
    }
    return {
      weight: weight,
      value: value, 
      completed: (item.itemTemplate.required && !item.itemTemplate.itemType.startsWith("STATIC")&& !item.itemTemplate.itemType.includes("FORMULE")) ? !isEmpty : true
    }
  }
  const calculateValueForm = () => {
    try{
      const items = formToComplete.value.items;

      const values: {field: string, values: {weight: number, value: number}}[] = [];
      const formRequired: number = currentForm.value.items.filter(i => i.required && !i.itemType.startsWith("STATIC") && !i.itemType.includes("FORMULE")).length;
      let formulares: {formulate: string, value: number, name: string}[] = currentForm.value.items
                                                                          .filter(i => i.itemType === Item_type.FORM_FORMULE)
                                                                          .sort((i1, i2) => i1.order-i2.order)
                                                                          .map(i => ({formulate: i.value, value: 0, name: i.varNameId }));
      let formCompleted: number = 0;
      items.filter(i => !i.itemTemplate.itemType.startsWith("STATIC")).filter(i => i.itemTemplate.itemType !== Item_type.FORM_FORMULE  ).map(i => {

        const {weight, value, completed} = getValues(i);
        if(i.itemTemplate.required && completed) formCompleted++;
        values.push({
          field: i.itemTemplate.varNameId,
          values: {weight, value}
        })
      });
      
      
      if(formRequired == formCompleted){
        formulares.map(f => {
          f.value = Sh.evaluateFormula(f.formulate, [...values.map(v => ({ name: v.field, ...v.values})),...formulares.filter(fff=> fff.name!== f.name).map(ff => ({name: ff.name, weight: ff.value, value: ff.value}))])
          
          
          formToComplete.value.items.find(i => i.itemTemplate.varNameId === f.name).valueObj.value = f.value;
          }
        )
      }
      return {
        completed: formCompleted,
        required: formRequired,
        value: formRequired == formCompleted && formulares.length ? formulares[formulares.length-1].value : 0
      }
    }catch(e){
      console.log("error", e)
      throw e;
    }
    
  }
  const saveFormCompleted = async ( close: boolean = false, reopen: boolean=false) => {

    try{
      if(close) formToComplete.value.state = form_state.CLOSED;
      if(reopen) formToComplete.value.state = form_state.DRAFT;
      if(!close){
        if(Sh.isNullOrEmptyOrZero(formToComplete.value.state)){
          formToComplete.value.state = form_state.DRAFT;
        }
      }
      const formToSend  = {...formToComplete.value};
      
      const {completed, required, value} = calculateValueForm();
      
      formToSend.questionsEnded = completed;
      formToSend.questionsRequireds = required;
      formToSend.result = value;
      formToSend.items = formToSend.items.map(i => ({
        id: i.id,
        formId: formToSend.formId,
        formResponseId: i.formResponseId,
        formItemId: i.formItemId,
        value: JSON.stringify(i.valueObj)
      }))
//      if(formToSend.value!=undefined) delete formToSend.value;


      let toComplete:FormResponseCompletedDto= await saveCompleteForm(formToComplete.value.formId, formToComplete.value.entityId, formToSend);
      loadDefaultResponseForm(formToComplete.value.formId, formToComplete.value.entityId, toComplete);
      setFormToComplete(toComplete);
      Sh.navigate("/forms/listAssigneds")
      
      return true;
    }

    catch(e){
     console.log(e, 'traza')
     console.trace()
     throw e;
    }
  }
  const changeState = async (newState: form_state):Promise<boolean> => {
    if(await changeStatusCompleteForm(formToComplete.value.id, newState)){
      formToComplete.value.state = newState;
      return true;
    }else{
      return false;
    }
  }
  const release = () => {
    releaseState();
  }
  return {
    init,
    forms, 
    search,
    hasMore,
    formToComplete,
    isVisibleMenu,
    page,
    searchKey,
    setOrder,
    clearSearch,
    getMore,
    clearForms, 
    getChild,
    getTocompleteForm,
    saveFormCompleted,
    release,
    getValues,
    calculateValueForm,
    changeState
  }
}