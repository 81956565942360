<script setup lang="ts">
  import Survey from '@/core/store/survey/surveyStore';
  import SurveyItemSection  from './itemsComponents/surveyItemSection.vue';
  import { DisplayType, FieldType, FormSectionDesignDto } from '../../../core/store/survey/surveyDesignDtos';
  import { computed, Ref, ComputedRef } from 'vue';
  const { selectItem, selectedSectionId, selectedItemId, isDesign, displayMode, currentDesign } = Survey();
  const itemsOrganized: Ref<FormSectionDesignDto[]> = computed<FormSectionDesignDto[]>(() => {
      const items = currentDesign.value.items;
      if(items== null || items.length == 0 ) return [];
      const sections: FormSectionDesignDto[] = items.filter(i => [FieldType.SECTION.toString(), FieldType.NEWPAGE.toString()].includes(i.type)).map(i => ({
        order: i.order,
        break: i.type == FieldType.NEWPAGE.toString(),
        section: i,
        items: []
      })).sort((i1,i2) => i1.order - i2.order);
 
      for(const i of items.filter(i=> ![FieldType.SECTION.toString(), FieldType.NEWPAGE.toString()].includes(i.type)).sort((i1, i2) => i1.order - i2.order)){
        const sect = sections.find(s => s.section.id == i.parentItemId);
        if(!sect) continue;
        sect.items.push(i);
      }
      return sections;
    }); 
</script>

<template>
  <div v-if="itemsOrganized" class="surveyContainer">
    <div v-for="section of itemsOrganized" :key="section.section.id" :style="selectedSectionId == section.section.id ?  {borderRadius: '25px',} : {border: 'none'} ">
      <div v-if="isDesign && displayMode == DisplayType.EDIT">
        <SurveyItemSection :section="section" />
        <hr v-if="isDesign && displayMode != DisplayType.EDIT" class="sectionSeparatorNoVisible">
        <hr class="sectionSeparator">
        <div v-if="section.break" 
            :class="[selectedItemId == section.section.id ? 'dashed-line-selected' : 'dashed-line']" 
            v-on:click="selectItem(section.section.id)">
          &nbsp;
        </div>
      </div>
      <div v-else> 
        <div v-if="section.break">
          <!--<div v-if="section.section.type === FieldType.NEWPAGE.toString()" 
              :class="[selectedItemId == section.section.id ? 'dashed-line-selected' : 'dashed-line']" 
              v-on:click="selectItem(section.section.id)">&nbsp;</div>-->
        </div>
        <div v-else>
          <SurveyItemSection :section="section" />
          <hr v-if="isDesign && displayMode != DisplayType.EDIT" class="sectionSeparatorNoVisible">
          <hr class="sectionSeparator">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import url(./survey.scss);
</style>

<script lang="ts">
  export default {
    name: "SurveyContent"
  }
</script>