<script setup lang="ts">
import { computed, ref, watch } from 'vue';


const props = defineProps({
  
  show: Boolean,
  closeLostFocus: Boolean, 
  hiddenShowButton: Boolean,
  zIndex: {
    type: String as () => string,
    required: false
  },
  onClose: Function,
  onConfirm: Function
});


const zindex = computed<string>( () => props.zIndex);

const closeDialog = () => {
  props.onClose();
};
const confirmDialog = () => {
  props.onConfirm();
};
</script>


<template>
  <div v-if="show" :style="{zIndex: zindex ? zindex : '9999'}">
    <div class="dialog-overlay" v-on:click="() => closeLostFocus ? closeDialog() : null "></div>
    <div class="dialog-box">
      <slot></slot>
      <div class="dialog-buttons">
        <button v-if="onConfirm" @click="confirmDialog">Confirmar</button>
        <div v-if="!closeLostFocus && !hiddenShowButton" @click="closeDialog" class="closeDlg" :class="{ 'margin-left-auto': !onConfirm }">
          <i class="bi bi-x-circle-fill closeDialogIcon"></i> 
          <span>
            Cerrar
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999999999;
}
.closeDlg{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  background-color: var(--secondary-color) !important;
  color: #fff;
  border: 0px;
  padding: 7px 45px;
  border-radius: 20px;
  cursor: pointer;
}

.closeDialogIcon{
  transform: translateY(2px);
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}
.dialog-box {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px 20px 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999999999999;
}

.dialog-buttons {
  text-align: right;
  margin-top: 20px;
}
.dialog-buttons .margin-left-auto {
  margin-left: auto;
}
.dialog-buttons button{
  background-color: #005060;
    color: #fff;
    border: 0px;
    padding: 7px 45px;
    border-radius: 20px;
    /* margin-right: 15px; */
}
</style>
<script lang="ts">
export default {name: "DPDialog"}

</script>
