import { storeToRefs } from "pinia";
import { DisplayType, FieldType, FormCabDesigntDto, FormConfig, FormDesignDto, FormDesignItemDto, FormSectionDesignDto, FormulaAssignableItem, ItemsType, ITEMSWITHTAGS, SelectedItemType } from "./surveyDesignDtos";
import { ResponseContext, SurveyResponseDto, SurveyResponseItemDto } from "./surveyResponseDtos";
import { SurveyState } from "./surveyState";
import { computed, ref, Ref } from "vue";
import { GlobalState } from "../global/gobalState";
import { roles } from "@/shared/globaEnums";
import { Form_status } from "../forms/dtos";
import { Sh } from "@/core/sharedService";

export class SurveyBaseStore{

  // State:
    // Design
  newDesign:  () => void;
  setDesign:  (form: FormDesignDto) => void;
  setCab: (cab: FormCabDesigntDto) => void;
  setMode:  (mode: DisplayType) => void;
  selectItem: (id: string) => void;
  setSelectedType: (type: SelectedItemType) => void;
  addItem: (item: FormDesignItemDto) => void;
  removeItem:  (item: FormDesignItemDto) => void;
  setDesignMode: (design: boolean) => void;
  updateItem: (FormDesignItemDto) => void;
  asignItem: (oldItem: FormDesignItemDto, newItem: FormDesignItemDto) => void;
  release: () => void;
    // Response
  newResponse: (entityId: string, userId: string) => void;
  addResponseItem: (item: FormDesignItemDto, value: any, userId: string) => void;
  setResponse: (response?: SurveyResponseDto, entityId?: string, userId?: string) => void;
  formatValue: (responseItem: SurveyResponseItemDto, designItem: FormDesignItemDto) => void;
  changeReponseValue: (item: FormDesignItemDto, newValue: any) => void;
  changeStatus: (newStatus: Form_status) => void;
  loading: Ref<boolean>;
  errors: Ref<string[]>;
  selectedType: Ref<SelectedItemType>;
  currentDesign: Ref<FormDesignDto>;
  displayMode: Ref<DisplayType>;
  selectedSectionId: Ref<string>;
  selectedItemId: Ref<string>;
  currentResponse: Ref<SurveyResponseDto>;
  isDesign: Ref<boolean>;
  currentItem: Ref<FormDesignItemDto>;


  // Computed
  hasError: Ref<boolean>;
  isNew: Ref<boolean>;
  cab: Ref<FormCabDesigntDto>;
  config: Ref<FormConfig>;
  header: Ref<string>;
  footer: Ref<string>;
  items: Ref<FormDesignItemDto[]>;
  itemsOrganized: Ref<FormSectionDesignDto[]>;// = ref([]);
  
  currentSection: Ref<FormSectionDesignDto>;
  responseContext: Ref<ResponseContext>;
  canClose: Ref<boolean>;
  canAcept: Ref<boolean>;
  responseStatus: Ref<{requireds: number, response: number, result: number}>;
  formulaAssignable: Ref<FormulaAssignableItem[]>; // Computed

  state = SurveyState();

  constructor() {
   this.init(); // load state.
  }

  init = () => {
    // Imports methods from state.
  //#region create instances state
    const st = this.state;
    const {
      newDesign,
      setDesign,
      setCab,
      setMode,
      selectItem,
      setSelectedType,
      addItem,
      removeItem,
      setDesignMode,
      updateItem,
      newResponse,
      addResponseItem,
      asignItem,
      setResponse,
      formatValue,
      changeReponseValue,
      release,
      changeStatus,
    } = st;
    this.release = release;
    this.newDesign = newDesign;
    this.setDesign = setDesign;
    this.setCab = setCab;
    this.setMode = setMode;
    this.selectItem = selectItem;
    this.setSelectedType = setSelectedType;
    this.addItem = addItem;
    this.removeItem = removeItem;
    this.setDesignMode = setDesignMode;
    this.updateItem = updateItem;
    this.asignItem = asignItem;
    // Response
    this.newResponse = newResponse;
    this.addResponseItem = addResponseItem;
    this.setResponse = setResponse;
    this.formatValue = formatValue;
    this.changeReponseValue = changeReponseValue;
    this.changeStatus = changeStatus;

    // state:
      const {
        selectedType,
        currentDesign,
        displayMode,
        selectedSectionId,
        selectedItemId,
        isDesign,
        responseStatus,
        itemsOrganized,
        currentRespone,
        currentItem,
      } = storeToRefs(st);
      this.selectedType = selectedType;
      this.currentDesign = currentDesign;
      this.displayMode = displayMode;
      this.selectedSectionId = selectedSectionId;
      this.selectedItemId = selectedItemId;
      this.currentResponse = currentRespone;
      this.itemsOrganized = itemsOrganized;
      this.responseStatus = responseStatus;
      this.currentItem = currentItem;
      this.isDesign = isDesign;
      this.loading = ref(false);
      this.errors = ref([]);
  //#endregion create instances de estate.






    const {currentContext} = GlobalState();
  //#region propiedades computadas
    // Computed:
      this.hasError = computed(() => this.errors.value.length!=0);
      this.isNew = computed(() => this.currentDesign.value.cab.id == null || this.currentDesign.value.cab.id.trim().length == 0);
      this.cab = computed(() => this.currentDesign && this.currentDesign.value.cab ? this.currentDesign.value.cab : null);
      this.config = computed(() => this.cab && this.cab.value.config ? this.cab.value.config : null);
      this.header = computed(() => this.config ? this.config.value.header : null);
      this.footer = computed(() => this.config ? this.config.value.footer : null);
      this.items = computed(() => this.currentDesign.value.items);
      

      this.currentSection = computed(()=>{
        if(this.selectedSectionId.value.trim().length){
          return this.itemsOrganized.value.find(i => i.section.id == this.selectedSectionId.value);
        }
        if(this.currentItem.value!=null && ![FieldType.SECTION, FieldType.NEWPAGE].includes(this.currentItem.value.type)){
          return this.itemsOrganized.value.find(i => i.section.id == this.currentItem.value.parentItemId);
        }
        return null;
      });

      this.responseContext = computed<ResponseContext>(() => {
        const context = new ResponseContext();
        const role = currentContext.user.role;
        switch(role){
          case roles.CLIENT:
          case roles.GROUP:
          case roles.ROOT:
          case roles.USER:
              context.displayMode = this.displayMode.value == DisplayType.EDIT ? DisplayType.DISPLAY : this.displayMode.value;
            break;
          case roles.ENTITY:
            if(this.currentResponse.value && [Form_status.CLOSED, Form_status.VALIDATED].includes(this.currentResponse.value.state)){
              context.displayMode = this.displayMode.value == DisplayType.EDIT ? DisplayType.DISPLAY : this.displayMode.value;
            }else if(!this.currentResponse.value.items.length){
              context.displayMode = DisplayType.DISPLAY;
            }else {
              context.displayMode = this.displayMode.value;
            }
        }
        if(!this.currentResponse.value || !this.currentResponse.value.items.length) return context;
        context.state = this.currentResponse.value.state;
        context.canAprobe = this.currentResponse.value.state == Form_status.CLOSED &&  currentContext.user.role === roles.CLIENT;
        context.canClose  = true;
        for(const d of this.currentDesign.value.items){
          if(d.response && d.required && !d.response.value){
            context.canClose = false;
            break;
          }
        }
        context.canEdit  = currentContext.user.role == roles.ENTITY;
        return context;
      });
      

      this.formulaAssignable = computed<FormulaAssignableItem[]>(() => {
        return this.currentDesign.value.items
          .filter(i => Object.keys(ITEMSWITHTAGS).includes(i.type) )
          .map(it => <FormulaAssignableItem>{
            tag: it.tag, 
            type: it.type, 
            label: it.label, 
            hasValue: ITEMSWITHTAGS[it.type], 
            items: it.type !== FieldType.GRID  ? [] : 
                it.items.map(itt => <FormulaAssignableItem>{
                    tag: itt.tag,
                    label: itt.label,
                    hasValue: it.itemsType == ItemsType.NUMBER
                })
          });
      });


  //#endregion propiedades computadas
}
    isNotEmptyValue(design: FormDesignItemDto): boolean{
      const response: SurveyResponseItemDto = design.response;
      if(!response) return false;
        switch(design.type){
          case FieldType.NUMBER:
          case FieldType.FORMULE:          
            return response.value !=0;
          case FieldType.TEXT:
          case FieldType.DATE:
            return response.value.trim() !="";
          case FieldType.DROPDOWN:
          case FieldType.MULTYDROPDOWN:
            return response.value.length !=0;
          case FieldType.DATERANGE:
            return response.value["init"].trim() != "" && response.value["end"].trim() != "";
          case FieldType.FILE: 
            return response.value["id"].trim() != "";
          case FieldType.GRID:
            return response.value.length && response.value.find(v => v == undefined || v == null || v.trim()== "")== null;
        }
    }
}