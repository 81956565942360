import { default } from './surveyItem.vue';
<script setup lang="ts">
import { Sh } from '@/core/sharedService';
import { DisplayType, FieldType, FormDesignItemDto, ItemMultiDrawarer, ItemsType, PropertyDisplayType, PropertyItem } from '@/core/store/survey/surveyDesignDtos';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
import Survey from '@/core/store/survey/surveyStore';
import { computed, CSSProperties, PropType, ref, watch } from 'vue';
const {
  displayMode,
  isDesign,
} = Survey();

const props = defineProps({
  item: {
    type: Object as () => FormDesignItemDto,
    required: true
  },
  response: {
    type: Object as () => SurveyResponseItemDto,
    default: null,
    required: false
  },
  multiple: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  onChange: {
    type: Function as PropType<(value: any) => void>,
    default: null,
    required: false,
  },

});
const items = computed<ItemMultiDrawarer[]>(() => {
  return props.item.items ?? [];
})
const currentStyles = computed<PropertyDisplayType>(() => {
  switch (displayMode.value) {
    case DisplayType.DISPLAY:
      return props.item.properties.screen;
    case DisplayType.EDIT:
      return props.item.properties.input;
    case DisplayType.PDF:
      return props.item.properties.print;
  }
});


const cssInput = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return {
    backgroundColor: displayProp.contentStyle.backgroundColor,
    borderRadius: '9999px',
    border: 'none',
    fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
    color: displayProp.contentStyle.color,
    width: isDesign.value && displayMode.value === DisplayType.EDIT ? '344px' : '100%',
    float: 'right',
  }
});

const cssSuffix = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return {
    color: displayProp.contentStyle.color,
    fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
  };
});


const value = ref<any[]>(props.response && props.response.value ? props.response.value : []);

watch(value, (newValue) => {
  if (props.response) {
    if (JSON.stringify(newValue) !== JSON.stringify(props.response.value)) {
      props.response.value = newValue;
      props.onChange(newValue);
    }
  }
});

const isCurrentVisible = computed(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return displayProp.visible || isDesign;
});
const handleChange = (event: Event, i: number) => {
  const target = event.target as HTMLSelectElement;
  const newValue = [...value.value];
  newValue[i] = target.value;
  value.value = newValue;
}

const itemsDisplay = computed<{ label: string, value: string }[]>(() => {
  const its: { label: string, value: string }[] = [];
  let val: any;
  for (const [index, item] of props.item.items.entries()) {
    if (!value.value) value.value = [];
    if (!value.value.at(index)) {
      value.value[index] = [ItemsType.DATE, ItemsType.TEXT].includes(props.item.itemsType) ? '' : 0
    }
    its.push({ label: item.label, value: value.value[index] });
  }
  return its;
});

const inputType = computed<string>(() => {
  switch (props.item.itemsType) {
    case ItemsType.DATE: return 'date';
    case ItemsType.NUMBER: return 'number';
    case ItemsType.TEXT: return 'text';
  }
});
</script>
<template>
  <!-- Edit Mode -->
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible">
    <div class="survey-item-dropdown-items">
      <div v-for="(i, index) in props.item.items" :key="index" class="survey-item-grid-item">
        <div class="survey-item-grid-item-label">
          {{ i.label }}
        </div>
        <div class="survey-item-grid-item-values gridInputContainer">
          <input :type="inputType" v-model="value[index]" class="survey-item-input survey-input-number-buttons-none"
            :style="cssInput" @change="($event) => handleChange($event, index)"
            :readonly="item.inheritItemId != null && item.inheritItemId.trim().length != 0">
          <span v-if="item.sufix" class="input-suffix-dt" :style="cssSuffix">{{ item.sufix }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Display Mode with Suffix inside the input -->
  <div v-if="[DisplayType.PDF, DisplayType.DISPLAY].includes(displayMode) && isCurrentVisible"
  class="survey-item-dropdown-items">
    <div class="survey-item-dropdown-items">
      <div v-for="(i, index) in itemsDisplay" :key="index" class="survey-item-dropdown-item">
        <div class="survey-item-grid-item-label">
          {{ i.label }}
        </div>
        <div class="survey-item-grid-item-values gridInputContainer">
          <input :type="inputType" class="survey-item-input survey-input-number-buttons-none" v-model="i.value"
            :style="cssInput" readonly tabindex="-1">
          <span v-if="item.sufix" class="input-suffix-dt" :style="cssSuffix">{{ item.sufix }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
@import url(../survey.scss);

.gridInputContainer {
  position: relative;
  margin-bottom: 20px;
}

.survey-item-input {
  padding-right: 2rem;
  width: 100%;
}

.input-suffix-dt {
  position: absolute;
  right: 15px;
  top: 52%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #555;
  pointer-events: none;
}
</style>

<script lang="ts">
export default {
  name: "SurveyItemGrid"
}
</script>