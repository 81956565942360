<script setup lang="ts">

</script>

<template>
  <!--   FORMULARIO RECOVERY-->
  <div class="content-login">
    <div class="container-recovery">
      <router-link to="/login">Volver</router-link>
      <!-- <img class="logo" src="../assets/LogoLoopo.jpg" alt=""> -->
      <form class="recovery" action="">
        <input type="text" placeholder="Correo electrónico">
        <button class="btn-orange" type="submit">Recuperacion</button>
      </form>
    </div>
  </div>
</template>

<style scoped src="./recovery-styles.css">
/* FORMULARIO RECOVERY*/
</style>

