<script setup lang="ts">
  import tinymce, {Ui, Editor} from 'tinymce/tinymce';
  import 'tinymce/icons/default';
  import 'tinymce/themes/silver';
  import 'tinymce/plugins/advlist';
  import 'tinymce/plugins/autolink';
  import 'tinymce/plugins/lists';
  import 'tinymce/plugins/link';
  import 'tinymce/plugins/image';
  import 'tinymce/plugins/charmap';
  import 'tinymce/plugins/preview';
  import 'tinymce/plugins/anchor';
  import 'tinymce/plugins/code'; 
  import 'tinymce/plugins/table'; 
  import { ref, onMounted, onBeforeUnmount, PropType  } from 'vue';
  import {getItemsSystemVars} from './dpWysywigButtonFields';
  import { Sh, SharedService } from '@/core/sharedService';
  import { apiRoutes } from '@/core/apiRouters';
  import { GlobalState } from '@/core/store/global/gobalState';
  const props  = defineProps({
    html: {
      type: String,
      required: true
    },
    onChange: {
      type: Function as PropType<( html: string) => void>,
      default: () => {},
    },
    style: {
      type: Object,
      default: {}
    }
  });

  const insertImage = (editor) => {
    const {showDialog} = GlobalState();
    showDialog('upload', (result) => {
      let html = `<img src="${result}" style="width: 100px; height: 100px">`;
      if(result){
        if(result.toString().toUpperCase().endsWith("SVG")){
          html = `<object data="${result}" type="image/svg+xml" style="width: 100px; height: 100px">
                    Tu navegador no soporta SVGs.
                  </object>`;
        }
        editor.insertContent(html)
      }
      
    });
  }
  const uploadImg = async (blobInfo, progress) => {
    try{
      const formData = new FormData();
      formData.append("file", blobInfo.blob());
      const result = await Sh.api<{id: string, url: string, originalName: string}>({
        method: "POST",
        route: apiRoutes.files,
        data:  formData,
        type: 'multipart'
      });
      
      return result.url;
    }catch(e){
      console.log("ERror", e)
    }
  };
  const editor = ref(null);
  let editorInstance = null;
  const initTinyMCE = () => {
    tinymce.init({
      target: editor.value,
      license_key: 'gpl',
      plugins: 'advlist autolink lists link image charmap preview anchor code table autoresize', // Omite 'print' si no está disponible
      toolbar: 'systemVars table textcolor colorpicker code | uploadImage | undo redo | bold fontsize italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
      base_url: '/assets/tinymce', // Ruta base para TinyMCE
      font_size_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 36px 48px 72px',
      images_upload_handler: uploadImg,
      branding: false, // Desactivar el logo de TinyMCE
      statusbar: false, // Desactivar la barra de estado (que incluye el branding)
      autoresize_bottom_margin: 0,
      valid_elements: '*[*]',
      //extended_valid_elements: 'img[class=myclass|!src|border:0|alt|title|width|height|style|data-*]',
      extended_valid_elements: 'img[**]',
      convert_urls: false,
      remove_script_host: false,
      custom_elements: 'style',
      menubar: false,
      setup: (editor) => {
        editorInstance = editor; // 
        editor.on('init', () => {
          editor.setContent(props.html.toString());
          for(const k of Object.keys(props.style)){
            editor.getContainer().style[k] = props.style[k]
          }
          
        });
        editor.on('blur', () => {
          const content = editor.getContent().replace('<p style="text-align: center;">&nbsp;</p>', "");
          editor.setContent(content);
          props.onChange(content);
          });
        editor.ui.registry.addButton('uploadImage', {
          icon: "image",
          text: "Subir imagen",
          onAction: () =>  insertImage(editor)
        })

        editor.ui.registry.addMenuButton('systemVars', {
          icon: "code-sample",
          
          text: "Vaiables",
          tooltip: "Variables disponibles",
          
          
          fetch: (callback) => {

            const items: Ui.Menu.NestedMenuItemContents[] = [
              {
                text: "Datos de formuario",
                type: 'nestedmenuitem',
                getSubmenuItems: () => [
                  {
                    type: 'menuitem',

                  }
                ]
                
              }
            ];

            callback(getItemsSystemVars(editor));
          }
        });


      },
      
    });

  };
  onMounted(() => {
  initTinyMCE();
  
  
});

onBeforeUnmount(() => {
  //   if (tinymce.get(editor.value.id)) {
  if (editor.value && tinymce.get(editor.value.id)) {
    tinymce.get(editor.value.id).remove();
  }
});


</script>

<template>
  <textarea ref="editor"></textarea>
</template>

<script lang="ts">
  export default {
    name: 'DpWysywig'
}
</script>
<style >
  @import url('./dpComponent.css');
</style>