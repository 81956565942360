import { defineStore } from "pinia";
import { FieldType, FormCabDesigntDto, FormDesignDto, FormDesignItemDto, defaultItem, DisplayType, SelectedItemType, FormSectionDesignDto, ErrorItem  } from "./surveyDesignDtos";
import { computed, ref } from "vue";
import { SurveyResponseDto, SurveyResponseItemDto, SurveyResponseValueFile } from "./surveyResponseDtos";
import { Entity, Identity } from '../entities/dtos';
import { Form_status, FormItemDto, FormResponseDto } from '../forms/dtos';
import { GlobalState } from "../global/gobalState";
import { Sh } from "@/core/sharedService";

export const SurveyState = defineStore("survey", () => {
    const currentDesign = ref<FormDesignDto>( new FormDesignDto());
    const displayMode = ref<DisplayType>( DisplayType.DISPLAY);
    const selectedItemId = ref<string>("");
    const selectedSectionId = ref<string>("");
    const selectedType = ref<SelectedItemType>( SelectedItemType.NONE);
    const isDesign = ref<boolean>(false);
    const currentResponse = ref<SurveyResponseDto>(null);
    const errors = ref<ErrorItem[]>([]);
    const isNotEmptyValue = (design: FormDesignItemDto): boolean =>{
    
      const response: SurveyResponseItemDto = design.response;
      if(!response) return false;
        switch(design.type){
          case FieldType.NUMBER:
          case FieldType.FORMULE:          
            return response.value !=0;
          case FieldType.TEXT:
          case FieldType.DATE:
            return response.value.trim() !="";
          case FieldType.DROPDOWN:
          case FieldType.MULTYDROPDOWN:
            return response.value.length !=0;
          case FieldType.DATERANGE:
            return response.value["init"].trim() != "" && response.value["end"].trim() != "";
          case FieldType.FILE: 
            return response.value["id"].trim() != "";
          case FieldType.GRID:
            return response.value.length && response.value.find(v => v == undefined || v == null || v.toString().trim()== "")== null;
        }
    }
    const currentItem = computed(() => {
      if(selectedItemId.value.trim().length == 0 ) return null;
      return currentDesign.value.items.find((i) => i.id == selectedItemId.value);
    }
    );
    const responseStatus =  computed<{requireds: number, response: number, result: number}>(() => {
      const data: {requireds: number, response: number, result: number} = {requireds: 0, response: 0, result: 0};
      const notValue: FieldType[] = [FieldType.GRAPHIC, FieldType.HTML, FieldType.IMAGE, FieldType.NEWPAGE,  FieldType.SECTION,  FieldType.APIREST, FieldType.YESNO];
      if(!currentResponse || !currentResponse.value || !currentResponse.value.items  || currentResponse.value.items.length == 0  ) return data;
      for(const g of itemsOrganized.value){
        if(g.break) continue;
        for(const i of g.items.filter(d=> !notValue.includes(d.type) && d.required)){
          data.requireds+= i.type!=FieldType.FORMULE ? 1 : 0;
          data.response+= isNotEmptyValue(i) ? i.type!=FieldType.FORMULE ? 1 : 0 : 0;
          if(i.type == FieldType.FORMULE){
            data.result = i.response.value;
          }
        }
      }
      return data;
    });
  const  itemsOrganized = computed<FormSectionDesignDto[]>(() => {
    const d = currentDesign.value;
    const items = currentDesign.value.items;
    if(items== null || items.length == 0 ) return [];
    const sections: FormSectionDesignDto[] = items.filter(i => [FieldType.SECTION.toString(), FieldType.NEWPAGE.toString()].includes(i.type)).map(i => ({
      order: i.order,
      break: i.type == FieldType.NEWPAGE.toString(),
      section: i,
      items: []
    })).sort((i1,i2) => i1.order - i2.order);

    for(const i of items.filter(i=> ![FieldType.SECTION.toString(), FieldType.NEWPAGE.toString()].includes(i.type)).sort((i1, i2) => i1.order - i2.order)){
      const sect = sections.find(s => s.section.id == i.parentItemId);
      if(!sect) continue;
      sect.items.push(i);
    }
    return sections;
  });
    //const currentResponse = ref<>();

  return {
    currentDesign,
    displayMode,
    selectedItemId,
    selectedType,
    selectedSectionId,
    isDesign,
    itemsOrganized,
    responseStatus,
    currentItem,
    currentRespone: currentResponse,
    errors,
    //actions
    release() {
      currentDesign.value =  new FormDesignDto();
      displayMode.value = DisplayType.DISPLAY
      selectedItemId.value = "";
      selectedSectionId.value = "";
      selectedType.value = SelectedItemType.NONE;
      isDesign.value = false;
      currentResponse.value = null;
      errors.value = [];
    },
    newDesign(){
      currentDesign.value = new FormDesignDto();

    },
    changeStatus(newStatus: Form_status){
      currentResponse.value.state = newStatus;
    },
    setDesign(form: FormDesignDto){
      form.cab.endDate = Sh.toShortDate(form.cab.endDate, true)
      form.cab.initDate = Sh.toShortDate(form.cab.initDate, true)
      if(!form.items.find(i => i.type == FieldType.SECTION)){
        form.items = [...form.items, defaultItem(FieldType.SECTION, form, 1)];
      }
      currentDesign.value = form;
    },
    setDesignMode(design: boolean){
      isDesign.value = design;
    },
    setCab(cab: FormCabDesigntDto){
      currentDesign.value = {...currentDesign.value, cab: cab};
    }, 
    setMode(mode: DisplayType )  {
      displayMode.value = mode;
    },
    addItem(item: FormDesignItemDto){
      currentDesign.value = {...currentDesign.value, items: [...currentDesign.value.items, item]};
      selectedItemId.value = item.id;
      if(item.parentItemId && item.parentItemId.trim().length){
        selectedSectionId.value = item.parentItemId;
      }
    },
    updateItem(item: FormDesignItemDto){

      currentDesign.value = {
        ...currentDesign.value, 
        items: currentDesign.value.items.map(i => i.id === item.id ? item : i)
      }
      //currentDesign.value.items = currentDesign.value.items.map(i => i.id === item.id ? item : i);
    },
    removeItem(item: FormDesignItemDto){
      currentDesign.value = {...currentDesign.value, items: currentDesign.value.items.filter(i => i.id!= item.id && i.parentItemId!=i.id)};
      if(item.id === selectedItemId.value){
        selectedItemId.value = "";
      }
    },
    selectItem(id: string){
      let sectionId = selectedSectionId.value;

      if(id && id.trim().length){
        const item = currentDesign.value.items.find(i => i.id == id);
        switch(item.type){
          case  FieldType.SECTION:
            this.setSelectedType(SelectedItemType.SECTION);
            sectionId  = id;
         //   selectedSectionId.value = id;
            break;
          case FieldType.NEWPAGE:
            this.setSelectedType(SelectedItemType.PAGE);
            sectionId = "";
            //selectedSectionId.value = "";
            break;
          default:
            this.setSelectedType(SelectedItemType.ITEM);
            if(item.parentItemId!== selectedSectionId.value){
              sectionId = item.parentItemId;
              //selectedSectionId.value = item.parentItemId;
            }
        }
      }
      selectedSectionId.value = sectionId;
      selectedItemId.value = id;
    },
    setSelectedType(type: SelectedItemType){
      selectedType.value = type;
      if(![SelectedItemType.SECTION, SelectedItemType.PAGE,SelectedItemType.ITEM].includes(type)){
        this.selectItem("");
      }else if(type == SelectedItemType.SURVEY){
        selectedSectionId.value = "";
        selectedItemId.value = "";
      }
    },
    asignItem(oldItem: FormDesignItemDto, newItem: FormDesignItemDto) {
      const pos = currentDesign.value.items.findIndex(i => i.id === oldItem.id);
      if(pos!== -1){
        currentDesign.value.items[pos] = {...newItem, order: oldItem.order, parentItemId: oldItem.parentItemId};
        currentDesign.value = {...currentDesign.value, items: currentDesign.value.items}
      }
    },
    changeReponseValue(item: FormDesignItemDto, newValue: any){
      const pos = currentDesign.value.items.findIndex(i => i.id === item.id);

      if(pos!== -1){
        currentDesign.value.items[pos].response.value = newValue;
        currentDesign.value = {...currentDesign.value, items: currentDesign.value.items}
      }
    },
    setResponse(response?: SurveyResponseDto, entityId?: string, userId?: string){
      if(response){
        currentResponse.value = response;
        this.asignResponseToDesign();
        return;
      }
      this.newResponse(entityId, userId);
    },
    newResponse(entityId: string, userId: string){
      const newResponse  = new SurveyResponseDto();
      newResponse.state = Form_status.DRAFT;
      newResponse.formId = currentDesign.value.cab.id;
      newResponse.questionsRequireds = 0;
      newResponse.questionsEnded = 0;
      newResponse.closeAt = null;
      newResponse.result = 0;
      newResponse.user = "";
      newResponse.userId = userId;
      newResponse.isNew = true;
      newResponse.entityId = entityId;
      currentResponse.value= newResponse;
      this.asignResponseToDesign()
    },
    asignResponseToDesign(){
      const notInclude: FieldType[] = [FieldType.GRAPHIC, FieldType.HTML, FieldType.IMAGE, FieldType.NEWPAGE, FieldType.SECTION]
      const {currentContext} = GlobalState();
        for(const d of currentDesign.value.items.filter(f => !notInclude.includes(f.type))){
          const r = currentResponse.value.items.find(r => r.formsDesignItemId === d.id);
          if(r){
            this.formatValue(r, d);
            d.response = r;
            
            
          }else{
            d.response = new SurveyResponseItemDto();
            d.response.formsDesignItemId = d.id;
            d.response.formResponseId = currentResponse.value.id;
            d.response.userId = currentContext.user.id;
            this.formatValue(d.response, d);
            currentResponse.value.items.push(d.response);
          }
        }
    },
    formatValue(responseItem: SurveyResponseItemDto, designItem: FormDesignItemDto){
      const defValues = {
        [FieldType.NUMBER]: "0",
        [FieldType.TEXT]: "",
        [FieldType.DATE]: "",
        [FieldType.DATERANGE]: '{"init": "", "end": ""}',
        [FieldType.YESNO]: "false",
        [FieldType.DROPDOWN]: "[]",
        [FieldType.MULTYDROPDOWN]: "[]",
        [FieldType.GRID]: "[]",
        [FieldType.FILE]: '{"id": "", "url": "", "originalName": ""}',
      }
      const originValue = responseItem.value ?? defValues[designItem.type];
      switch(designItem.type){
        case FieldType.NUMBER:
          responseItem.value = parseFloat(originValue);
          break;
        case FieldType.TEXT:
        case FieldType.DATE:
          responseItem.value = originValue;
          break;
        case FieldType.YESNO:
          responseItem.value = originValue == "true" ? true : false;
          break;
        case FieldType.DROPDOWN:
        case FieldType.MULTYDROPDOWN:
        case FieldType.GRID:
        case FieldType.DATERANGE:
            responseItem.value = JSON.parse(originValue);
            break;
        case FieldType.FILE:
            responseItem.value = SurveyResponseValueFile.fromValue(originValue);
            break;
      }
    },
    addResponseItem(item: FormDesignItemDto, value: any, userId: string){
      const it = new SurveyResponseItemDto();
      it.value = value;
      it.userId = userId;
      it.formsDesignItemId = item.id;
      it.formResponseId = currentResponse.value.id;
      currentResponse.value.items.push(it);
      currentResponse.value = {...currentResponse.value};
    },
    setErrors(itemId: string, messages: string[]) {
      errors.value = errors.value.filter(e => e.itemId !== itemId);
      errors.value.push(...messages.map(message => ({ itemId, message })));
    },
    removeErrors(itemId: string){
      errors.value = errors.value.filter(e => e.itemId !== itemId);
    },
    clearErrors(){
      errors.value = [];
    }



  }


}, {persist: true});