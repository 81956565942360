
export const  apiRoutes = {
    login: '/users/login',
    entityList: '/entity/list/',
    entityListTypeParent: '/entity/list/{0}/{1}',
    entityListType: '/entity/list/{0}',
    entitySimpleList: '/entity/simpleList/{0}',
    entitySimpleListClients: '/entity/simpleList',
    entityGet: '/entity/{0}', // {0} = entityId
    entityGetFull: '/entity/full/{0}', // {0} = entityId
    entityPost: "/entity",
    entityGetDataToDelete: "/entity/getdatatodelete/{0}", // {0} = userId
    entityDelete: "/entity/{0}/{1}", // {0} = entityId,  {1} = newEntityId
    usersListEntity: "/users/list",
    usersList: "/users/list",
    userDelete: "/users/{0}/{1}/{2}", // {0} = userId, {1} = mode "DELETE|DEACTIVATE", {2} = newUserId
    userGetDataToDelete: "/users/getdatatodelete/{0}", // {0} = userId
    
    formsListCreated: "/forms/listCreated/{0}",
    formsListCreatedKey: "/forms/listCreated/{0}/{1}",
    formsCanDelete: "/forms/candelete/{0}", // {0} = formId
    formsDelete: "forms/{0}", // {0} = formId
    componentsList: "/forms/components/{0}", // {0} = clientId
    componentsItems: "/forms/getItems/{0}", // {0} = formId
    formsUpdateCab: "/forms/cab",
    formsUpdate: "/forms",
    formsGetByIdCreated: "/forms/getById/{0}",
    formsEntitiesAsigns: "/forms/entitiesAsigns/{0}/{1}", // {0} type {1} formId (POST y GET) (post se envía array string de entities)
    formsEntitiesAsignsEntity: "/forms/entitiesAsigns/{0}/{1}/{2}", // {0} type {1} formId  {2} groupId (POST y GET) (post se envía array string de entities)
    formAssigns: "/forms/assigned",  // POST Devuelve los formularios asignados según SearchAssigned
    formCompleted: "/forms/completed/{0}/{1}", // {0} FormId {1} entityId
    formChangeStatus: "/forms/state/{0}/{1}", // GET {0}  formId {1} new State Only GROUP (only to DRAFT) or up (all)
    files: "/files", // POST (formdata {id?: string, file: File})  DELETE
    userById: "users/{0}",
    userUpdate: "users", // POST send user DTO;
    checkNameEntity: "entity/checkName/{0}", // {0} name
    checkEmailUser: "users/checkmail/{0}", // {0} email


    // finhava api:
    finhavaEntityById: 'VW_Entities?$filter=Id eq {0}', //{0} id finhava
    finhavaMetrics: 'Metrics',
    finhavaMetricValue: `API_Metrics?$filter=Year%20eq%20{0}%20and%20Month%20eq%20{1}%20and%20Id_Hotel%20eq%20'{2}'%20and%20Factor%20eq%20'{3}'`,
                          // {0} Year {1} Month {2} FinhavaId {3} metric



    // Survy Nuevos endpoints  (nuevo formato de diseño)
    surveyGetDesign: "/forms/{0}",    // GET {0} id formulario.
    surveyDesignUpdate: "survey/design/", // create or update survey Design
    surveyDesingListSimple: "/survey/design/formsSimple",
    surveyDesingListSimpleCompontents: "/survey/design/formsSimple/components",
    surveyDesingListItemSimple: "/survey/design/itemsSimple/{0}", //{0} formId
    surveyDesingItem: "/survey/design/items/{0}", //{0} formId
    surveyCanDelete:  "/survey/design/candelete/{0}", // get {0} formId devuelve bool
    surveyDelete:  "/survey/design/delete/{0}", //DELETE {0} formId devuelve bool
    surveyGetResponse: "/survey/response/byDesign/{0}/{1}", // {0} EntityId, {1} FormDesignId GET
    surveySaveResponse: "/survey/response", //POST 
    surveyInherits: "/survey/response/inherits/{0}", //POST  {0} entityId
    surveyChangeStatus: "/survey/response/changestatus/{0}/{1}", //POST  {0} formResponseID, {1} Form_status return bool
    
    
    // Survey Apis:
    surveyApiSave: "/survey/api",    // POST 
    surveyApiList: "/survey/api",    // GET SurveyApiListItemDto[]
    surveyApiGet: "/survey/api/{0}",    // GET {0} apiId SurveyApiDto
    // Survey Apis print:
    surveyPrintDesign: "survey/design/print/{0}", // GET {0} formId bytes[] recibe un pdf con mocdatas.
    surveyPrintResponse: "survey/response/print/{0}/{1}", // GET {0} formId, {1} entityId bytes[] recibe un pdf con mocdatas.

}

export const mocFunctions = {
  //login: MocServices.login,
  //entityList: MocServices.getEntities,
  //entityGet: MocServices.getEntity,
}
export const hasMocData = (path: string) => {
  const routeKey = Object.keys(apiRoutes).find(key => (<any>apiRoutes)[key] === path);
  if(routeKey){
      const moc =  (<any>mocFunctions)[routeKey];
      return moc;
  }
  return null;
}
