<script setup lang='ts'>
  import { Sh } from '@/core/sharedService';
import { GlobalState } from '@/core/store/global/gobalState';
import useUsers from '@/core/store/user/useUsers';
import { roles } from '@/shared/globaEnums';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { FormListStore, UseFormListStore } from './formStore';

  const {user, list, userList} = useUsers();
  const { currentContext } = storeToRefs(GlobalState());
  const { isVisibleMenu } =  storeToRefs(FormListStore());
  
  const lastLink = ref<string>("");
  const menu = [
      {    
        title:  user.value.role === roles.ROOT || user.value.role === roles.CLIENT ?  "Edición de formularios" : (user.value.role === roles.GROUP ? 'Asignación de formularios' : ''),
        link: "/forms/listCreate",
        enabled: user.value.role === roles.ROOT || user.value.role === roles.CLIENT || user.value.role === roles.GROUP
      },
      {
        title: "Formularios asignados",
        link: "/forms/listAssigneds",
        enabled: true
      },
      {
        title: "Formularios en curso",
        link: "/forms/listAssigneds/incourse",
        enabled: true
      },
      {
        title: "Formularios terminados",
        link: "/forms/listAssigneds/complete",
        enabled: true
      },
      {
        title: "Configuración API", 
        link: "/forms/listAPI",
        enabled: user.value.role === roles.CLIENT
      },
      
  ];
  

  const isLinkActive = (link: string) => {
    const route = useRoute();
    const routes = route.matched.map(m => m.path);
    if(link.includes("listCreate") && routes.includes("/forms/:id")) return true;
    if(routes.includes('/forms/complete/:mode?')){
      return link === lastLink.value;
    }
    return route.path.endsWith(link);
  }  
  const onclick = (item: any) => {
    lastLink.value = item.link;
  }
  
</script>
<template>
    <div class="headerList">
        <div class="nav-list formsTopMenu">
          <template v-for="item in menu" :key="item.link">
            <li v-if="item.enabled" :class="{'selectedMenu': isLinkActive(item.link)}">
              <router-link class="nav_btn " :to="item.link" @click="onclick(item)" :class="{ 'nav-btn-active': isLinkActive(item.link) }">{{ item.title }}</router-link>
            </li>
          </template>
        </div>
    </div>
  <RouterView />
</template>
<style scoped>

.formsTopMenu{
  margin-left: 207px;
  z-index: 50;
  transform: translateY(10px);

  /* @media screen and (max-width: 1900px) {
    margin-left: 9%;
  }
  @media screen and (max-width: 1800px) {
    margin-left: 6.5%;
  } */
}

@media (max-width: 1545px) {
  .formsTopMenu {
    column-count: 2;
    margin-bottom: 70px;
    margin-left: 140px;
  }

  li{
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 2200px) {
  .formsTopMenu {
    margin-left: 12%;
  }
}

@media screen and (min-width: 3540px) {
  .formsTopMenu {
    margin-left: 11.5%;
  }
}

</style>