<script setup lang="ts">
  import { FormItemDto, Item_type   } from "@/core/store/forms/dtos";
  import { type PropType, type Ref, ref, watch } from 'vue';
import { FieldsItems, type FormItem, typesItem } from "@/views/components/formItemsDto";
import { errorItems } from "@/views/components/formItemsDto";
import { labelTipos } from '../../core/store/forms/dtos';
import { Sh } from '@/core/sharedService';

  const props = defineProps({
    data: FormItemDto,
    onTrash: Function as PropType<(item: FormItemDto) => void>,
    moveUp: Function as PropType<(item: FormItemDto) => void>,
    moveDown: Function as PropType<(item: FormItemDto) => void>,
    checking: Boolean,
    canEdit: Boolean,
    extraValid: Function as PropType<(item: FormItemDto, value: FormItem ) => boolean>,
  });
  const comp = ref<FormItem[]>(FieldsItems[props.data.itemType]);  
  const label = ref(labelTipos.find(l => l.type == props.data.itemType)?.labelMenu)
  const imgUrl = ref<string>(props.data.text) ;
  const imgWidth = ref<string>(props.data.weight ? props.data.weight.toString() + "px" : "175px");
  const imgPos = ref<string>(props.data.weightEmpty && props.data.weightEmpty < 2 ? "left" : (props.data.weightEmpty == 2 ? "center" : "right"));
  const isCheck = ref<boolean>(false);
  const updateValue = (event:any) => {
    props.data.value = event.target.value;
  }
  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file)
    try{
      const result = await Sh.api<{id: string, url: string}>({
      method: 'POST',
      route: "/files",
      data:formData, 
      type: "multipart"
    });
    props.data.value = result.id;
    props.data.text = result.url;
    imgUrl.value = result.url;

    }catch{

    }
    
  }
  const getData = () => {
    return props.data;
  }
  
  const unCheck = () => {
    isCheck.value = false;
  }
  const changeValue = async (value: any, target: Ref, staticImage: boolean = false) =>{
    if(props.data.itemType === Item_type.STATIC_IMAGE && staticImage){
      const input = value.target as HTMLInputElement;
      if(input.files?.length){
        uploadFile(input.files[0]);
        return;
      }
    }
    if(value.target && value.target.value) value = value.target.value;
    target.value = value;
    if(props.data.itemType == Item_type.STATIC_IMAGE){
      imgWidth.value = props.data.weight ? props.data.weight.toString() + "px" : "75px";
      imgPos.value = props.data.weightEmpty && props.data.weightEmpty < 2 ? "left" : (props.data.weightEmpty == 2 ? "center" : "right");
    }
  }
  const valid = ():boolean => {
    let isValid = true;
    for(const item of comp.value){
      item.hasError = false;
      item.hasWarning= false;
      
      if(item.error && item.error !== errorItems.NONE){
        if(Sh.isNullOrZeroString(props.data[item.field])){
          if(item.error === errorItems.WARNING){
            item.hasWarning = true;
          } 
          if(item.error === errorItems.ERROR){
            console.log("ERROR", props.data)
            item.hasError = true;
            
          } 
        }else{
          if(item.extraValid && props.extraValid){  
            item.hasError = props.extraValid(props.data, item)
          }
        }
        if(item.hasError) isValid = false;
      }
      
    }
    return isValid;
  }

  defineExpose({
    valid, 
    isCheck,
    getData,
    unCheck
    }
  )
  watch(()=> props.data, (newValue, oldValue) => {
    comp.value = FieldsItems[(<FormItemDto>newValue).itemType]; 
    label.value = labelTipos.find(l => l.type == props.data.itemType)?.labelMenu;
  })    
</script>
<template>

  <div class="container-fluid p-0">
    <div class="row itemForm">
      <div v-if="!checking && canEdit" class="col-1 panelItemLeft">
        <i class="bi bi-chevron-up cp" @click="moveUp(data)"></i>
        <i class="bi bi-chevron-down cp" @click="moveDown(data)"></i>
      </div>
      <div v-if="checking" class="col-1 panelItemLeft">
        <input v-if=" props.data.itemType !==Item_type.FORM_FORMULE" :disabled="!canEdit" type="checkbox" v-model="isCheck" class="cp">
      </div>
      <div class="col-10">
        <h4>{{ label }}</h4>
        <template v-for="(item, index) in comp" :key="index">

          <h5 v-if="item.type == typesItem.h5">{{ item.label }}</h5>
          <h2 v-if="item.type == typesItem.h2">{{ item.label }}</h2>
          <label v-if="item.type==typesItem.label">{{ item.label }}</label>
          <div v-if="item.type == typesItem.input  && (!item.hidden || (item.hidden && !data[item.hidden]))">
            <label>{{ item.label }}</label>
            <input v-model="data[item.field]" :class="[item.hasError ? 'input-with-error' : '']" class="w-100" :disabled="!canEdit"/>
            <small v-if="item.hasWarning || item.hasError" :class="[item.hasError ? 'small-error': 'small-warning']" >{{ item.message }} </small>

            
          </div>
          <div v-if="item.type == typesItem.number && (!item.hidden || (item.hidden && !data[item.hidden]))">
            <label>{{ item.label }}</label>
            <input type="number" v-model="data[item.field]" :class="[item.hasError ? 'input-with-error' : '']"  @change="changeValue($event, ref(props.data[item.field]))" :disabled="!canEdit"/>
            <small v-if="item.hasWarning || item.hasError" :class="[item.hasError ? 'small-error': 'small-warning']" >{{ item.message }} </small>
          </div>
          <div v-if="item.type == typesItem.check && (!item.hidden || (item.hidden && !data[item.hidden]))" class="row">
            <div class="col-1">
              <input  type="checkbox" v-model="data[item.field]" :disabled="!canEdit">
            </div>
            <div class="col-11">
              <label>{{ item.label }}</label>
            </div>
            
          </div>

          <div v-if="item.type==typesItem.text  && (!item.hidden || (item.hidden && !data[item.hidden]))">
            <label>{{ item.label }}</label>

            <textarea v-model="data[item.field]"  class="w-100" :class="[item.hasError ? 'input-with-error' : '']" @input="($event) =>changeValue($event, ref(data[item.field]))" :disabled="!canEdit" :maxlength="item.maxText??null"></textarea>
            <small v-if="item.hasWarning || item.hasError" :class="[item.hasError ? 'small-error': 'small-warning']" >{{ item.message }}</small>
          </div>
          <div v-if="item.type==typesItem.hr">
            <hr>
            {{ item.label }}
            <hr>
          </div>
          <div v-if="item.type==typesItem.image && data.itemType === Item_type.STATIC_IMAGE " >
            <hr>
            {{ item.label }}
            <input type="file" v-bind:hidden="(!!data.text && data.text.trim().length!==0)" @change="changeValue($event, ref(props.data[item.field]), true)" :disabled="!canEdit">
            <div  :style="{display: 'flex', justifyContent: imgPos}">
              <img v-if="!!data.text && data.text.trim().length !==0" :src="data.text" v-bind:style="{width: imgWidth}">
            </div>
            <hr>
          </div>
          <div v-if="item.type==typesItem.fieldForm" class="fr">
            <label>{{ item.label }} { {{ data[item.field] }} }</label>
          </div>

          
          <div v-if="item.type==typesItem.selector">
            <label>{{ item.label }}</label>
            <select v-model="data[item.field]" :disabled="!canEdit">
              <option v-for="( i, index) in item.getItems()" :key="index" :value="i.id">
                {{ i.title }}
              </option>
            </select>
          </div>
          
        </template>

      </div>
      <div class="col-lg-1 panelItemLeft" v-if="canEdit">
        
        <i class="bi bi-trash3 cp" @click="onTrash(data)"></i>
      </div>
    </div>
  </div>
  
</template>

<style scope>
.input-with-error{
  border: 1px solid red;
}
.small-error{
  color: red;
}
.small-warning{
  color:orange
}
input {
  border: 0px;
  position: relative;
  border: 3px solid #81B4AB;
  padding-left: 10px;
  border-radius: 5px;
}

.contentForm .row.itemForm {
  padding: 10px 0px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.cp.bi-trash3 {
  color: #ff0000;
  font-size: 18px;
}

.cp{
  cursor: pointer;
  font-size: 18px;
}
.formInput{
  margin-bottom: 10px;
}

.itemForm{
  margin-bottom: 20px;
  box-shadow: 0px 0px 15px #0000002b;
}
.itemForm h5{
  display: inline-block;
  color: #005060;
  
  font-weight: 200;
  width: 80%;
}
.itemVarNameId{
  float:right;
}
.panelItemLeft{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.description-input {
  margin-bottom: -3em;
}

.description-trash {
  
  margin-bottom: 2em;
}

.description-component {
  margin-top: 2em;
  color: #005060;
}

.form-control {
  margin-top: 1em;
  border: 0px;
  border-left: 1px solid #81b4ab;
  border-width: 2px;
  margin-left: 5px;
  width: 98%;
}

.description-item {
  margin-bottom: 1em;
  width: 4em;
  margin-right: -1em;
}
.w100{
  width: 100%;
}
.fr{
  float: right
}

</style>
<script lang="ts">
export default {
  name: 'formItem'
}
</script>
