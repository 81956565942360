<script setup lang="ts">
  import { PropType, ref } from 'vue';
  import DPDialog from '@/views/components/dialog.vue';
  import { GlobalState } from '@/core/store/global/gobalState';
  import { storeToRefs } from 'pinia';
import { apiRoutes } from '@/core/apiRouters';
import { Sh } from '@/core/sharedService';
  const {isGlobalDialog, globalDialogType, globalDialogContent, globalDialogAction} =  storeToRefs(GlobalState());

  const close = () => {

    isGlobalDialog.value = false;
  }
    const fileInput = ref<HTMLInputElement | null>(null);

const handleDrop = async (event: DragEvent)  => {
  event.preventDefault();
  const droppedFiles = event.dataTransfer?.files;
  if (droppedFiles && droppedFiles.length > 0) {
    const file = droppedFiles[0];
    if(!validateAndProcessFile(file)) return;
    processFile(file);
  }
};
const validateAndProcessFile = (file: File):boolean => {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  if (acceptedTypes.includes(file.type)) {
    return true;
  } else {
    return false;
  }
};
// Función para manejar la selección de un archivo a través del input
const handleFileSelect = () => {
  const files = fileInput.value?.files;
  if (files && files.length > 0) {
    const file = files[0];
    if(!validateAndProcessFile(file)) return;
    processFile(file);
  }
};

// Función para procesar el archivo
const processFile = async (file: File) => {
  try{
      const formData = new FormData();
      formData.append("file", file);
      const result = await Sh.api<{id: string, url: string, originalName: string}>({
        method: "POST",
        route: apiRoutes.files,
        data:  formData,
        type: 'multipart'
      });
      globalDialogAction.value(result.url);
      isGlobalDialog.value = false;
    }catch(e){
      console.log("ERror", e)
    }
};

// Método para abrir el selector de archivos
const openFileDialog = () => {
  fileInput.value?.click();
};




</script>

<template>
  <DPDialog :show="isGlobalDialog" :closeLostFocus="true" :onClose="() => close()" >
    <div v-if="globalDialogType == 'upload'" class="drowpdown-menu dialogUpload" style="width: 200px; height: 200px; border: 1px solid gray;" @dragover.prevent
    @drop="handleDrop"
    @click="openFileDialog">
    <input type="file" ref="fileInput" @change="handleFileSelect" hidden accept=".jpg,.jpeg,.png,.gif">
    <label>Arrastre un archivo (jpg, png o gif) o pulse aquí para buscarlo</label>
    </div>
    <div v-if="globalDialogType == 'html'" v-html="globalDialogContent">
    </div>

  </DPDialog>

</template>


<style lang="css">
  .dialogUpload{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-around;
    align-items: center;
  }
  .dialogUpload label{
    margin: auto;
    text-align: center;
    padding: 20px;
  }

</style>


<script lang="ts">
export default {
  name: "DPGlobalDialog"
}

</script>