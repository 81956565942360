


export class FormDto{
  cab: FormCabPostDto = new FormCabPostDto();
  items: FormItemDto[] = [];
}

export class FormCabPostDto {
    id: string = "";
    name: string = "";
    clientId: string = "";
    description: string = "";
    endDate: string = "";
    formType: Form_type = Form_type.FROM;
    fieldCounter: number = 0;
    
}
export enum Form_status
{
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
  VALIDATED= "VALIDATED",
  ASSIGN= "ASSIGN",
}
export const Form_status_descriptions = {
  [Form_status.ASSIGN] : "Asignado",
  [Form_status.DRAFT] : "En elaboración",
  [Form_status.CLOSED] : "Finalizado",
  [Form_status.VALIDATED] : "Finalizado y Validado",
}
export class FormResponseDto extends FormCabPostDto{
  
  formsItems: FormItemDto[] = [];
}


export class FormItemDto
  {
    isValid?: boolean = true;
    id: string = "";
    itemType: Item_type = Item_type.FORM_TEXT;
    value: string = "";
    order: number = 0;
    weight: number = 0;
    label: string = "";
    text: string = "";
    required: boolean = false;
    weightEmpty: number = 0;
    varNameId: string = "";
    formulate: string = "";
    finhavaId: string = "";
    formId: string = "";
    parentItemId: string = "";
    finhavaMetric: string  = "";
    finhavaYear: number = 0;
    finhavaMonth: number = 0;
}

export interface ComponentDefinition{
    labelMenu: string,
    type: Item_type,
}
export class FormCreatedItemDto{
  id: string = "";
  name: string = "";
  create: string = "" 
  description: string  ="";
  endDate: string ="" 
  clientId?: string  ="";
  clientName?: string  ="";
  groupId?: string  ="";
  groupName?: string  ="";
  entityId?: string  ="";
  entityName?: string  ="";
  type: Form_type = Form_type.FROM;
  state: string;
}
export class FormResponseCompletedDto{
  id: string = "";
  state: string = "";
  closeAt: string = "";
  entityId: string = "";
  formId: string = "";
  userId: string = "";
  name: string = "";
  userEmail: string = "";
  result: number = 0;
  questionsRequireds: number = 0;
  questionsEnded: number = 0;
  items: FormResponseCompletedItemsDto[] = []
 }
 
 export  class FormResponseCompletedItemsDto{
  id: string = "";
  formId: string = "";
  formResponseId: string = "";
  formItemId: string = "";
  value: string = "";
  valueObj?: any={};
  itemTemplate?: FormItemDto;

 }

export enum Form_type{
  FROM = "FORM",
  COMPONENT = "COMPONENT"
}
export enum Item_type{
  STATIC_TITLE ="STATIC_TITLE",
  STATIC_SUBTITLE ="STATIC_SUBTITLE",
  STATIC_TEXT ="STATIC_TEXT",
  STATIC_IMAGE ="STATIC_IMAGE",

  FORM_TEXT ="FORM_TEXT",
  FORM_NUMBER ="FORM_NUMBER",
  FROM_IMAGE = "FROM_IMAGE",
  FORM_DATE ="FORM_DATE",
  FORM_START ="FORM_START",
  FORM_END ="FORM_END",
  FORM_DATE_RANGE ="FORM_DATE_RANGE",
  FORM_COMBOBOX ="FORM_COMBOBOX",
  FORM_COMBOBOX_MULTIPLE ="FORM_COMBOBOX_MULTIPLE",
  FORM_YES_NO ="FORM_YES_NO",
  FORM_FORMULE ="FORM_FORMULE",
  FORM_FINHAVA ="FORM_FINHAVA",
}


export interface zonesMenuForms{
  texts: {label: string, components: ComponentDefinition[]},
  form: {label: string, components: ComponentDefinition[]},
  components: {label: string, components: ComponentDefinition[]}
}
export interface SimpleFormResponseData{
   id: string;
   title: string;
   state: string;
}

export interface Metrics {
  id: string; 
  title: string;
}
export const ComponentsDefinitions: zonesMenuForms = {
  texts: {
    label: "Texto e imágenes",
    components: [
      {labelMenu: "Título", type: Item_type.STATIC_TITLE},
      {labelMenu: "Subtítulo", type: Item_type.STATIC_SUBTITLE},
      {labelMenu: "Texto", type: Item_type.STATIC_TEXT},
      {labelMenu: "Imagen", type: Item_type.STATIC_IMAGE},
    ]
  },
  form: {
    label: "Elementos del formulario",
    components: [
       {labelMenu: "Texto libre", type: Item_type.FORM_TEXT},
      {labelMenu: "Número", type: Item_type.FORM_NUMBER},
      {labelMenu: "Fecha", type: Item_type.FORM_DATE},
      {labelMenu: "Rango de fechas", type: Item_type.FORM_DATE_RANGE},
      {labelMenu: "Desplegable", type: Item_type.FORM_COMBOBOX},
      {labelMenu: "Elección múltiple", type: Item_type.FORM_COMBOBOX_MULTIPLE},
      {labelMenu: "Si/No", type: Item_type.FORM_YES_NO},
      {labelMenu: "Fichero adjunto", type: Item_type.FROM_IMAGE},
      {labelMenu: "Resultado de fórmula", type: Item_type.FORM_FORMULE},
      {labelMenu: "Finhava API", type: Item_type.FORM_FINHAVA},
    ]
  },
  components: {
    label: "Elementos Personalizados",
    components: []
  }
};



export const labelTipos = [...ComponentsDefinitions.texts.components,...ComponentsDefinitions.form.components ]