<template>
  <DPDialog v-if="apiData" :show="show" :closeLostFocus="false" :hiddenShowButton="true" :onClose="closeDialog">
    <div class="apiDialogContainer">
      <!-- HEADER -->
      <div class="apiModalHeaderContainer">
        <h3 class="modalApiTitle">{{ !apiData.isNew ? apiData.name : 'Nueva API' }}</h3>
        <button v-if="!isTesting" class="close-button" @click="closeDialog">
          <span class="x-mark">&#10005;</span>
        </button>
      </div>
      <!-- FORMULARIO CREACIÓN DE API -->
      <div v-if="!isTesting" class="apiForm">
        <div class="formRow">
          <!-- CAMPO DE NOMBRE -->
          <div class="apiFormGroup">
            <label class="formLabel">Nombre*</label>
            <input v-model="apiData.name" type="text" class="apiFormInput" placeholder="Nombre de la API">
          </div>
          <!-- DESCRIPCIÓN -->
          <div class="apiFormGroup">
            <label class="formLabel">Descripción</label>
            <input v-model="apiData.description" type="text" class="apiFormInput" placeholder="Breve descripción">
          </div>
        </div>
        <div class="formRow">
          <!-- PROPIEDAD A RECUPERAR -->
          <div class="apiFormGroup">
            <label class="formLabel">Propiedad*</label>
            <input v-model="apiData.property" type="text" class="apiFormInput" placeholder="Propiedad a recuperar">
          </div>
          <!-- MÉTODO -->
          <div class="apiFormGroup">
            <label class="formLabel">Método*</label>
            <select v-model="apiData.method" class="apiFormSelect">
              <option value="" disabled>Selecciona un método valido</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
            </select>
          </div>
        </div>
        <!-- URL DE LA API -->
        <div class="apiFormGroup">
          <label class="formTextareaLabel">URL*</label>
          <textarea v-model="apiData.url" type="text" class="apiFormInput"
            placeholder="https://api.ejemplo.com"></textarea>

        </div>
        <!-- SECCIONES DE TEXTAREA -->
        <div class="apiTextareaSection">
          <div class="apiTextareaColumn">
            <div class="labelContainer">
              <label class="formTextareaLabel">Variables personalizadas</label>
              <i class="bi bi-question-circle-fill info-popover" @mouseenter="showPopover('customVars')"
                @mouseleave="handleMouseLeave('customVars')"></i>
              <div :class="['popover-text', { 'visible': visiblePopover === 'customVars' }]">Una variable por línea -
                ej: [MONTH]=Mes de inicio</div>
            </div>
            <textarea v-model="apiData.customVars" class="apiTextarea"
              placeholder="Ingrese las variables personalizadas"></textarea>
          </div>
          <div class="apiTextareaColumn">
            <label class="formTextareaLabel">Variables globales disponibles</label>
            <textarea class="apiTextarea readOnly" :readonly="true"
              :innerHTML='"{id-finhava}  - Id Finhava de la entidad \n{cif-entidad} - CIF de la entidad"'>
            </textarea>
          </div>
        </div>
        <div class="apiTextareaColumn">
          <div>
            <label class="formTextareaLabel">Payload (JSON)</label>
            <textarea v-model="apiData.payload" class="apiTextarea" placeholder="Ingrese el payload"></textarea>
          </div>
          <div>
            <div class="labelContainer">
              <label class="formTextareaLabel">Header petición</label>
              <i class="bi bi-question-circle-fill info-popover" @mouseenter="showPopover('headers')"
                @mouseleave="handleMouseLeave('headers')"></i>
              <div :class="['popover-text', { 'visible': visiblePopover === 'headers' }]">Uno por linea, ej:
                Authorization=Bearer eyJhbGciO1. . .</div>
            </div>
            <textarea v-model="apiData.headers" class="apiTextarea"
              placeholder="Ingrese el header de la petición"></textarea>
          </div>
        </div>
      </div>

      <!-- SECCIÓN DE PRUEBA DE API -->
      <div v-if="isTesting" class="apiFormTest">
        <div style="display: flex; flex-direction: row;" v-if="testData">
          <div class="variablesContainer">
            <div class="globalVariables" v-if="testData.globals.length">
              <div v-for="v in testData.globals" :key="v.var">
                <span class="formSectionTitle">Variables globales disponibles:</span>
                <br>
                <label class="formLabel">{{ v.description }}:</label>
                <input type="text" v-model="v.value">
              </div>
            </div>
            <div class="customVariables" v-if="testData.custom.length">
              <span class="formSectionTitle">Variables Personalizadas:</span>
              <br>
              <div v-for="v in testData.custom" :key="v.var">
                <label class="formLabel">{{ v.description }}:</label>
                <input type="text" v-model="v.value">
              </div>
            </div>
          </div>
          <div class="resultContainer">
            <div class="formRow">
              <div class="apiFormGroup">
                <label class="formSectionTitle">Devolución:</label>
                <textarea rows="5" style="overflow-y: auto;" class="apiTextarea resultTextArea readOnly"
                  readonly>{{ resultTest ? resultTest.returnText : "" }}</textarea>
              </div>
            </div>
            <div class="formRow">
              <div class="apiFormGroup">
                <label class="formSectionTitle">Resultado llamada:</label>
                <input :value="resultTest ? resultTest.value : ''" type="text" class="apiFormInput" readonly>
                <label v-if="hasError" style="color: red">Error en llamada a api {{ props.apiData.url }}</label>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- BOTONES -->
      <div class="apiButtonContainer">
        <button v-if="!isTesting" :class="['btn-orange', { 'inactive': !isFormValid }]"
          @click="() => saveApi()">Guardar</button>
        <button v-if="!isTesting" :class="['refFormModalAsignBTN', { 'inactive': !isFormValid }]"
          @click="handleTestClick">
          Probar API
        </button>
        <button v-if="isTesting" class="backBTN" @click="() => { isTesting = false; hasError = false; }"><i
            class="bi bi-box-arrow-left"></i>Volver</button>
        <button v-if="isTesting" class="refFormModalAsignBTN" @click="testApi">Probar API</button>
      </div>
    </div>
  </DPDialog>
</template>

<script setup lang="ts">
import DPDialog from '@/views/components/dialog.vue';
import { ResultTest, SurveyApiDto, SurveyApiParms } from '@/core/store/survey/surveyApiDtos';
import Survey from '@/core/store/survey/surveyStore';
import { computed, ref, watch } from 'vue';
import { Sh } from "@/core/sharedService";
const { updateApi, getVarsApi, getDataApi } = Survey();
const hasError = ref<boolean>(false);
const props = defineProps<{
  show: boolean;
  onClose: () => void,
  apiData: SurveyApiDto;
}>();

const testData = ref<SurveyApiParms>(null);
const resultTest = ref<ResultTest>(null);
const closeDialog = () => {
  if (props.onClose) {
    props.onClose()
  }
};
const isTesting = ref<boolean>(false);

const handleTestClick = async () => {
  const saved = await saveApi(false);

  if (saved) {
    testData.value = getVarsApi(props.apiData);
    isTesting.value = true;

  }
};

const saveApi = async (withClose: boolean = true) => {
  if (!isFormValid.value) {
    Sh.showToast("Por favor, rellene todos los campos obligatorios", 8, true);
    return false;
  }
  const updated = await updateApi(props.apiData);
  if (updated == false) {
    Sh.showToast("Error al guardar", 8, true);
    return false;
  }
  props.apiData.isNew = false;
  Sh.showToast("API guardada correctamente", 8, false);
  hasError.value = false;
  if (withClose) closeDialog();
  return true;
};

const testApi = async () => {
  try {
    resultTest.value = await getDataApi(testData.value, props.apiData);
    hasError.value = false;
  } catch (e) {
    resultTest.value = null;
    hasError.value = true;

  }

  return;
  try {
    const response = await fetch(props.apiData.url, {
      method: props.apiData.method,
      headers: JSON.parse(props.apiData.headers || '{}'),
      body: props.apiData.method === 'POST' ? JSON.stringify(props.apiData.payload) : null,
    });

    const data = await response.json();
    props.apiData.response = JSON.stringify(data, null, 2);
    props.apiData.callResult = response.ok ? 'Éxito' : `Error ${response.status}`;
  } catch (error) {
    props.apiData.response = `Error: ${error}`;
    props.apiData.callResult = 'Error en la llamada';
  }
};



const visiblePopover = ref<string | null>(null);
let hideTimeout: ReturnType<typeof setTimeout> | null = null;

const showPopover = (popoverId: string) => {
  if (hideTimeout) {
    clearTimeout(hideTimeout);
  }
  visiblePopover.value = popoverId;
};

const handleMouseLeave = (popoverId: string) => {
  hideTimeout = setTimeout(() => {
    visiblePopover.value = null;
  }, 1000);
};

const isFormValid = computed(() => {
  return (
    props.apiData.name.trim() !== '' &&
    props.apiData.url.trim() !== '' &&
    props.apiData.method.trim() !== '' &&
    props.apiData.property.trim() !== ''
  );
});

watch(isFormValid, (newValue) => {
  console.log('Formulario válido:', newValue);
});

</script>

<style lang="scss">
// @import url(../../components/survey/survey.scss);

.apiFormSelect option[value=""] {
  color: #999;
}

.apiDialogContainer {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  max-width: 90vw;
  max-height: 90vh;
  padding: 5px;
  background-color: white;
}

.apiModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modalApiTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
}


.apiForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.formRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.apiFormGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.apiFormGroup input,
.apiFormGroup select,
.apiFormGroup textarea {
  width: 100%;
  background-color: var(--lightBlue-bg) !important;
  border: 0px solid transparent !important;
  height: 38px;
  border-radius: 21px;
  padding-left: 20px;
  box-sizing: border-box;
}


.apiFormGroup input:focus,
.apiFormGroup select:focus-visible,
.apiFormGroup textarea:focus-visible {
  outline: 2px solid var(--primary-color);
}

.apiFormGroup select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--compositor-color);
  background-image: url('../../../../public/assets/bannerIcon.svg');
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: right 15px center;
  padding-right: 30px;
}

.apiFormGroup textarea {
  height: 76px;
  padding: 15px;
  border-radius: 5px;
  resize: none;
}

textarea::-webkit-scrollbar {
  width: 12px;
  background-color: #EDF5F3;

}

textarea::-webkit-scrollbar-thumb {
  background-color: #12999D;
  border-radius: 6px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #FAA476;

}

textarea::-webkit-scrollbar-track {
  background-color: #B7DDDE;
}

.labelContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  i {
    font-size: 18px;
    color: var(--primary-color);
    cursor: pointer;
  }
}

.formLabel {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--primary-color);
  padding-left: 10px;
}

.formSectionTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--primary-color);
  // margin-bottom: 10px;
}

.formTextareaLabel {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--primary-color);
}

.apiTextareaSection {
  display: flex;
  gap: 15px;
}

.apiTextareaColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.apiTextarea {
  width: 100%;
  height: 100px;
  background-color: var(--lightBlue-bg) !important;
  border: 0px solid transparent !important;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  resize: none;
}

.resultTextArea {
  height: 600px !important;
  margin-bottom: 20px
}

.apiTextarea:focus {
  outline: 2px solid var(--primary-color);
}

.readOnly {
  background-color: rgb(234, 234, 234) !important;
}

.apiButtonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.info-popover {
  position: relative;
}

.popover-text {
  background-color: var(--primary-color);
  color: #fff;
  text-align: left;
  border-radius: 999px;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  top: -190%;
  left: 55%;
  width: max-content;
  margin-top: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
}

.popover-text.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
}

.backBTN {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  height: 42px;
  background-color: var(--secondary-color);
  color: #fff;
  border: 2px solid transparent;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
}

.backBTN i {
  font-size: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

.backBTN:hover {
  background-color: #fff;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.inactive {
  background-color: #f2f2f2 !important;
  color: #999 !important;
  border: transparent !important;
  cursor: not-allowed !important;
  // pointer-events: none !important;
}

.variablesContainer {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin-right: 2%;
  input{
    width: 100%;
    background-color: var(--lightBlue-bg) !important;
    border: 0px solid transparent !important;
    height: 38px;
    border-radius: 21px;
    padding-left: 20px;
    box-sizing: border-box;
  }
}

.globalVariables{
  margin-bottom: 20px;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin-left: 2%;
}
</style>