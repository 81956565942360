import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Entity, type EntityListItemDto } from './dtos';


const entityStore = defineStore('entity', () =>{
  const entities = ref<EntityListItemDto[]>([]);
  const childEntities = ref<EntityListItemDto[]>([]);
  const selectedEntity = ref<Entity>(new Entity());
  const curEntity = ref<Entity>(new Entity());
  const isEditing = ref<boolean>(false);
  return {
    // Properties
    entities,
    curEntity,
    childEntities,    
    selectedEntity,
    isEditing,
      // Actions
    setEntities(ents: EntityListItemDto[], toChild: boolean = false){
      if(toChild){
        childEntities.value = ents;
      }else{
        entities.value = ents;
      }
      
    },
    setChildEntities(ents: EntityListItemDto[]){
      childEntities.value = ents;
    },
    setCurEntity(ent: Entity){
      curEntity.value = ent;
    },
    setSelectedEntity(ent: Entity){
      selectedEntity.value = ent;
    },
    newEntity(entity: Entity){
      selectedEntity.value = entity;
    },
    setEditing(editing: boolean = true){
      isEditing.value = editing;
    },
    resetEntities(){
      entities.value = [];
      childEntities.value = [];
      selectedEntity.value = new Entity();
      curEntity.value = new Entity();
      isEditing.value = false;
    }


  }

}, {persist: true});
export default entityStore;