import { VueQueryPlugin } from '@tanstack/vue-query';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import {createBootstrap} from 'bootstrap-vue-next'
import App from './App.vue';
import './assets/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Sh } from './core/sharedService';
import router from './router';
declare var tinymce: any;

const app = createApp(App);
app.use(createBootstrap());
app.component('VueDatePicker', VueDatePicker);

const pinia = createPinia();
app.use(router);


app.use(pinia);
pinia.use(piniaPluginPersistedstate)


VueQueryPlugin.install( app, {
    queryClientConfig: {
        defaultOptions:  {
            queries: {
                cacheTime: 1000 * 60, // 1 minuto
            }
        }
    }
});
app.config.warnHandler = (msg, instance, trace) =>
  ![
    'built-in or reserved HTML elements as component id: component',
    '"class" is a reserved attribute and cannot be used as component prop',
    'Cannot find element: #__nuxt'
  ].some((warning) => msg.includes(warning)) &&
  (msg.includes("FormResponseCompletedItemsDto") ? null : console.trace('[Vue warn]: '.concat(msg).concat(trace)))
  
app.mount('#app');


Sh.router = router;
Sh.pinia = pinia;
