<template>
    <div class="plusButton">
      <div class="icon-container">
        <img src="@/assets/plus-icon.svg" alt="Icon" />
      </div>
      <div class="text-container">
        <span>{{ label }}</span>
      </div>
    </div>
  </template>
  

<script setup lang='ts'>
  const props = defineProps({
      label: String
    });


</script>
  
  <style scoped>

  .plusButton {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 50%; 
    width: 32px;
    height: 32px;
    margin-right: 14px;
    box-shadow: inset 2px 2px 5px #00000044, /* Sombra oscura para profundidad */
              inset -2px -2px 5px #FFFFFF44; /* Sombra clara para levantamiento */
    z-index: 1;
    /* border: 1px solid var(--white); */
  }
  
  .icon-container img {
    width: 25px;
    height: 25px;
  }

  .text-container {
    background-color: var(--primary-color);
    padding: 6px 20px;
    margin-left: -50px;
    border-radius: 32px;
    transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.3s,
    letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
    box-shadow: 4px 4px 8px #00000033;
  }

  .text-container:active {
    background-color: var(--secondary-color);
    transform: scale(0.95);
  }

  .text-container span {
    padding-left: 36px;
    padding-right: 10px;
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
  }
  </style>
<script lang="ts">
    export default {
      name: "plusButton",
    }
</script>