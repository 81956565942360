import { default } from '../survey/surveyProperties.vue';
<script setup lang="ts">
import { PropType, ref, reactive } from 'vue';
import DPDialog from '../dialog.vue';
import { ColorPicker } from 'vue-color-kit';
import "vue-color-kit/dist/vue-color-kit.css";
const props = defineProps({
  onChange: {
    type: Function as PropType<(color: string) => void>,
    required: true
  },
  color: {
    type: String as () => string,
    reactive: true,
    required: true,
  }
});
const isShow = ref<boolean>(false);

</script>

<template>
  <div class="dp-color-picker-input">
    <div class="dp-color-picker-button">
      <button :style="{ background: color }" @click="isShow = !isShow">
        &nbsp;
      </button>
    </div>
    <input type="text" :value="color"
      v-on:change="($event: Event) => props.onChange((($event.target) as HTMLInputElement).value)" />
  </div>


  <DPDialog :show="isShow" :closeLostFocus="true" :onClose="() => isShow = false">
    <ColorPicker theme="light" :color="color" :sucker-hide="false" :sucker-canvas="null" :sucker-area="[]"
      @changeColor="(clr) => { props.onChange(clr.hex); }" />
  </DPDialog>

</template>


<script lang="ts">
export default {
  name: "DpColorPicker"
}
</script>

<style>
@import url(../survey/survey.scss);

.dp-color-picker-input {
  display: flex;
  flex-direction: row;
}

.dp-color-picker-button {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  /* margin-left: 40px; */
}

.dp-color-picker-button button {
  width: 100%;
  height: 100%;
  border: 2px solid var(--darkgreen-text);
  border-radius: 4px;
}

.dp-color-picker-input input {
  flex: 1;
}



</style>