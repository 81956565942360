import { apiRoutes } from "@/core/apiRouters";
import { storeToRefs } from "pinia";
import { Sh } from '../../sharedService';
import { GlobalState } from '../global/gobalState';
import { FormCabPostDto, FormCreatedItemDto, FormDto, FormItemDto, FormResponseCompletedDto, FormResponseDto, Item_type } from './dtos';
import { FormState } from "./formState";
import { entityTypes, form_state } from "@/shared/globaEnums";


export const useForms = () => {
  const {addItem, newForm, setItems, setForm, setCurrentFormItem,setCabForm, selectItem,setForms, resetCurrent, setSelectedEntitiesToForm, setMetrics} = FormState();

  const {currentForm, forms, isEditing, currentFormItem,
    selectedEntitiesToForm, metrics} = storeToRefs(FormState())
  const { currentContext } = storeToRefs(GlobalState())
  const {loading} =  GlobalState();

  const getEntitiesForms = async (type: entityTypes,  groupId: string = "") => {
    try{
      loading(true);
      const route = type === entityTypes.GROUP ? apiRoutes.formsEntitiesAsigns : apiRoutes.formsEntitiesAsignsEntity;
      const params: string[] = [type, currentFormItem.value.id];
      if(type === entityTypes.ENTITY){
        params.push(groupId);
      }
       const data = await Sh.api<string[]>({
        method: 'GET',
        route: route,
        params: params
      });
      setSelectedEntitiesToForm(data) ;
    }catch(e){
      setSelectedEntitiesToForm([]) ;
    }finally{
      loading(false);
    }
  }
  const canDelete = async (formId: string)  =>{
    try{
      loading(true);
      const params: string[] = [formId];

       const data = await Sh.api<boolean>({
        method: 'GET',
        route: apiRoutes.formsCanDelete,
        params: params
      });
        return data;
    }catch(e){
      
    }finally{
      loading(false);
    }
  }
  const deleteForm = async (formId: string)  =>{
    try{
      loading(true);
      const params: string[] = [formId];
       const data = await Sh.api<boolean>({
        method: 'DELETE',
        route: apiRoutes.formsDelete,
        params: params
      });
        return data;
    }catch(e){
      
    }finally{
      loading(false);
    }
  }
  const setEntitiesForms = async (type: entityTypes, entities: string[],  groupId: string = "") => {
    try{
      loading(true);
      const route = type === entityTypes.GROUP ? apiRoutes.formsEntitiesAsigns : apiRoutes.formsEntitiesAsignsEntity;
      const params: string[] = [type,  currentFormItem.value.id];
      if(type === entityTypes.ENTITY){
        params.push(groupId);
      }
       const data = await Sh.api<string[]>({
        method: 'POST',
        route: route,
        params: params, 
        data: entities
      });
      setSelectedEntitiesToForm(data) ;
    }catch(e){
      setSelectedEntitiesToForm([]) ;
    }finally{
      loading(false);
    }
  }

  const setCurFormItem = (id: string ) => {
    const curForm = forms.value.find(f => f.id == id);
    if(curForm){
      setCurrentFormItem(curForm);
    }
    
  }
  const getForm = async (id: string) => {
    try{
      loading(true);
      const data:any = await Sh.api({
        method: 'GET',
        route: apiRoutes.formsGetByIdCreated,
        params: [id]
      });
      const {formsItems, ...cab} = <FormResponseDto>data;
      //currentForm.value = data;
      
      cab.endDate = Sh.toShortDate(cab.endDate,true);
      setCabForm(cab);
      if(data.formsItems && data.formsItems.length){
        setItems(formsItems.map(i => ( {...i, isValid: true})).sort((i1, i2) => i1.order - i2.order ));
       
      }else{
        setItems([]);
      }
      return true;
    }catch(e){
      console.log("ERROR ", e)
      return false;
    }finally{
      loading(false);
    }
  }
  const saveComponentForm = async (form: FormDto) => {
    try{
/*       const f = await updateForm(form.cab);
      if(f === false) return false; */
      for(const i of form.items){
        form.cab.fieldCounter+= 1;
        i.varNameId = "";
        i.formId = null
      }
      
      const data = await saveForm(form);
      return true;
  
    }catch(e){
      console.log("Error save component", e)
      return false;
    }
    

  }
  const getCompletedForm = async (formId: string, entityId: string):Promise<FormResponseCompletedDto> => {
    try{
      const data = await Sh.api<FormResponseCompletedDto>({
        method: 'GET',
        route: apiRoutes.formCompleted, 
        params: [formId, entityId]
      });
      return data;
    }
    catch(e){
      
      throw e;
    }

  }
  const saveCompleteForm =  async (formId: string, entityId: string, form:FormResponseCompletedDto ):Promise<FormResponseCompletedDto> => {
    try{
      const data = await Sh.api<FormResponseCompletedDto>({
        method: 'POST',
        route: apiRoutes.formCompleted,
        params: [formId, entityId],
        data: form
      });

      return data;
    }
    catch(e){
     console.log(e, 'traza')
     console.trace()
      throw e;
    }

  }
  const changeStatusCompleteForm = async ( formId: string, newStatus: form_state): Promise<boolean> => {
    try{
      const data = await Sh.api<boolean>({
        method: 'GET',
        route: apiRoutes.formChangeStatus,
        params: [formId, newStatus]
      });
      return true;
    }catch(e){
      console.log(e, 'traza')
      console.trace()
      return false;
    }

  }

  const changePos = (origin: number, target: number) => {
    const items = [...currentForm.value.items];
    const or = items.find(i => i.order == origin);
    const de = items.find(i => i.order == target);
    if(or == null || de == null) return;
    or.order = target;
    de.order = origin;

    return currentForm.value.items = items.sort((i1, i2) => i1.order - i2.order );
  }
  const removeItem = (item: FormItemDto | number)  => {
    let x = 0;
    currentForm.value.items = currentForm.value.items.filter(i => i.order !== (typeof item ==='number' ? item : item.order )).sort((i1,i2) => i1.order - i2.order  ).map(i => ({...i, order: x++}));


  }
  const chageItemsPos = (item: FormItemDto | number, to: number) => {
    if(typeof item === 'number'){
      item = currentForm.value.items.find(i => i.order === item);
      if(!item) return;
    }
    const items = changePos(item.order, to);
    if(items) setItems(items);
  }
  const getNewItem = (type?: Item_type) => {
    const item = new FormItemDto();
    item.formId = currentForm.value.cab.id;
    item.order = currentForm.value.items.length + 1;
    if(type) item.itemType = type;
    return item;
  }
  const addNewItem = async  (type?: Item_type | 'COMPONENT', id?: string) => {
    if(type === 'COMPONENT'){
      const items = await Sh.api<FormItemDto[]>({
        method: 'GET',
        route: apiRoutes.componentsItems,
        params: [id]
      });
      if(!items.length) return;
      for(const i of items){
        addItem(i);
      }

    }else{
      const item = getNewItem(type);
      addItem(item);
    }
    

  }
  const updateForm = async (cabForm?: FormCabPostDto) =>{
    try{
      loading(true);
      const clientId = Sh.getCurrentClient();
      if(!clientId) throw "Error, no hay cliente seleccionado";
      currentForm.value.cab.clientId = clientId;
      const data = await Sh.api<FormCabPostDto>(
        {
          method: 'POST',
          route: apiRoutes.formsUpdateCab,
          data: cabForm ? cabForm :  currentForm.value.cab
        }
      );

      if(!cabForm) setCabForm(data);
      return data;
    }catch{
      return false;
    }finally{
      loading(false);
    }
  }
  
  const clearFormData = (datos: FormDto) => {
    const items =  datos.items.map(i => {
      if(!Sh.toNull(i.id)) delete i.id;
      if(!Sh.toNull(i.finhavaId)) delete i.finhavaId;
      if(!Sh.toNull(i.parentItemId)) delete i.parentItemId;
      return i;
    })
    return {...datos, formItem: items }
  }
  const saveForm = async (form?: FormDto) => {
    try{
      const data = await Sh.api(
        {
          method: 'POST',
          route: apiRoutes.formsUpdate,
          data: clearFormData(form ? form : currentForm.value)
        }
      )
      if(data) return true;
    }catch{
      throw "Error al guardar el formulario";
      return false;
    }
  }

  const checkFormulare = () => {
    const items = currentForm.value.items;
    const names = items.map(i => i.varNameId);
    const formulas = items.filter(i => i.itemType === Item_type.FORM_FORMULE);
    const errors: string[] = [];
    
    for(const formula of formulas){
      const missing = checkReferences(names, formula.value);
      if(missing){
        errors.push(`La variable ${missing} no existe en la fórmula ${formula.value}`)
      }
    }
    return errors;
  }
  const checkReferences = (namesArray, string) => {
    const references = string.match(/{([^{}]+)}/g);
  
    if (!references) {
      // Si no hay referencias, entonces no hay problemas
      return null;
    }
  
    for (const reference of references) {
      const referenceName = reference.replace(/[{}]/g, '').replace('.value', '');
  
      if (!namesArray.includes(referenceName)) {
        return referenceName; // Devuelve el nombre de la variable que falta
      }
    }
  
    return null; // No se encontraron variables faltantes
  }

  const getComponents = async () => {
    try{
      const clientId= Sh.getParentUser();
      if(!clientId) throw "No hay nada que mostrar"
      loading(true);
      const data = await Sh.api<{id: string, name: string}[]>({
        method: 'GET',
        route: apiRoutes.componentsList,
        params: [clientId]
      });
      return data;
    }catch(e){
      console.log("Error get components", e );
      throw e;
    }finally{
      loading(false)
    }

  }

  const getMetrics = () => {
    Sh.finhavaApi("Metrics", []).then((data) => {
      console.log(data);
      if(data.value && data.value.length){
        setMetrics(data.value.map((d) => ({id: d.Metric, title: d.Description})));
      }
    }).catch(r => console)
  }

  const listFormsCreated = async () => {
    
    try{
      const clientId= Sh.getParentUser();
      if(!clientId) throw "No hay nada que mostrar"
      loading(true);
      const data = await Sh.api<FormCreatedItemDto[]>({
        method: 'GET',
        route: apiRoutes.formsListCreated,
        params: [clientId]
      });
      setForms(data);

    }catch(e){
      setForms([]);
      throw e;
    }finally{
      loading(false)
    }
  }
  return {
    currentForm, 
    forms,
    isEditing,
    currentFormItem,
    selectedEntitiesToForm,
    metrics,
    chageItemsPos,
    getNewItem,
    addNewItem,
    selectItem,
    listFormsCreated,
    updateForm,
    removeItem, 
    saveCompleteForm,   
    saveForm,
    resetCurrent,
    getForm,
    setCurFormItem,
    getEntitiesForms,
    setEntitiesForms,
    newForm,
    getCompletedForm,
    changeStatusCompleteForm,
    checkFormulare,
    saveComponentForm,
    getComponents,
    canDelete,
    deleteForm,
    getMetrics
    
  }
}