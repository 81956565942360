import tinymce, {Editor, Ui} from 'tinymce/tinymce';
import Survey from '@/core/store/survey/surveyStore';
import { SurveySystemVar } from '@/core/store/survey/surveyDesignDtos';
//const {currentDesign } = Survey();
export const getItemsSystemVars = (editor: Editor): Ui.Menu.NestedMenuItemContents[] => {
  const itemsSystemVars: Ui.Menu.NestedMenuItemContents[] = [
    {
      text: "Datos del formuario",
      type: 'nestedmenuitem',
      getSubmenuItems: () => [

        {
          type: 'menuitem',
          text: 'Nombre del formulario',
          onAction: (_) => editor.insertContent(SurveySystemVar.FORMNAME)
        },
        {
          type: 'menuitem',
          text: 'Decripción del formulario',
          onAction: (_) => editor.insertContent(SurveySystemVar.FORMDESCRIPTION)
        },
        {
          type: 'menuitem',
          text: 'fecha inicio',
          onAction: (_) => editor.insertContent(SurveySystemVar.FORMINIT)
        },
        {
          type: 'menuitem',
          text: 'fecha fin',
          onAction: (_) => editor.insertContent(SurveySystemVar.FORMEND)
        },
        {
          type: 'menuitem',
          text: 'Fecha de cierre',
          onAction: (_) => editor.insertContent(SurveySystemVar.FORMCLOSE)
        },
        {
          type: 'menuitem',
          text: 'Fecha de emisión',
          onAction: (_) => editor.insertContent(SurveySystemVar.FORMEMIT)
        },
        {
          type: 'menuitem',
          text: 'Usuario',
          onAction: (_) => editor.insertContent(SurveySystemVar.USERNAME)
        },

      ]
      
    },
    {
      text: "Datos de la Entidad",
      type: 'nestedmenuitem',
      getSubmenuItems: () => [

        {
          type: 'menuitem',
          text: 'Nombre del grupo',
          onAction: (_) => editor.insertContent(SurveySystemVar.GROUPNAME)
        },
        {
          type: 'menuitem',
          text: 'CIF/NIF del trupo',
          onAction: (_) => editor.insertContent(SurveySystemVar.GROUPCIF)
        },
        {
          type: 'menuitem',
          text: 'Direccion del trupo',
          onAction: (_) => editor.insertContent(SurveySystemVar.GROUPADDRESS)
        },
        {
          type: 'menuitem',
          text: 'Nombre de la entidad',
          onAction: (_) => editor.insertContent(SurveySystemVar.ENTITYNAME)
        },
        {
          type: 'menuitem',
          text: 'CIF/NIF de endidad',
          onAction: (_) => editor.insertContent(SurveySystemVar.ENTITYCIF)
        },
        {
          type: 'menuitem',
          text: 'Direccion del trupo',
          onAction: (_) => editor.insertContent(SurveySystemVar.ENTITYADDRESS)
        },
      ]
      
    },
    {
      text: "Imágenes",
      type: 'nestedmenuitem',
      getSubmenuItems: () => [
        {
          type: 'menuitem',
          text: 'Logo Loopo',
          onAction: (_) => editor.insertContent(SurveySystemVar.LOOPOLOGO)
        },
        {
          type: 'menuitem',
          text: 'Logo cliente',
          onAction: (_) => editor.insertContent(SurveySystemVar.CLIENTLOGO)
        },
        {
          type: 'menuitem',
          text: 'Logo Grupo',
          onAction: (_) => editor.insertContent(SurveySystemVar.GROUPLOGO)
        },
        {
          type: 'menuitem',
          text: 'Logo Entidad',
          onAction: (_) => editor.insertContent(SurveySystemVar.ENTITYLOGO)
        },
      ]
    },
    {
      text: "Paginación",
      type: 'nestedmenuitem',
      getSubmenuItems: () => [
        {
          type: 'menuitem',
          text: 'Nº de página',
          onAction: (_) => {
            const {currentDesign } = Survey();
            if(currentDesign.value && currentDesign.value.cab && currentDesign.value.cab.config && currentDesign.value.cab.config.pageNumber){
              editor.insertContent(SurveySystemVar.PAGE);
            }else{
              alert("No tiene activado el número de pagina en la configuarción del formulario");
            }
            
          } 
        },
        {
          type: 'menuitem',
          text: 'Total páginas',
          onAction: (_) => {
            const {currentDesign } = Survey();
            if(currentDesign.value && currentDesign.value.cab && currentDesign.value.cab.config && currentDesign.value.cab.config.pageNumber){
              editor.insertContent(SurveySystemVar.PAGES);
            }else{
              alert("No tiene activado el número de pagina en la configuarción del formulario");
            }
            
          } 
        },
        
      ]
    },
  ];
  return itemsSystemVars;
}
