import { ResponseString, entityTypes, roles } from '@/shared/globaEnums';
import axios, { type AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';
import type { Pinia, Store } from 'pinia';
import { useRouter, type Router, useRoute, RouteLocationNormalizedLoaded } from 'vue-router';
import { apiRoutes, hasMocData } from './apiRouters';
import { Entity } from './store/entities/dtos';
import { useEntity } from './store/entities/useEntity';
import { GlobalState, type Context } from './store/global/gobalState';
import type { LoginResponseDto } from './store/user/dtos';
import useUsers from './store/user/useUsers';
import { UserStore } from './store/user/userState';
import { RouterFacade } from './store/router/routesState';
import { UseFormListStore } from '@/views/forms/formStore';
import environment from '@/environments/environments';
import { useForms } from './store/forms/useForms';
import { Metrics } from './store/forms/dtos';
import { parse } from 'mathjs';
import { ref } from 'vue';

export interface ApiInterface{
    route: string;
    data?: any;
    method: 'POST'|'GET'|'DELETE'|'PUT';
    type?: 'json'|'multipart' ;
    headers?: any ;
    auth?: boolean;
    sendLang?: boolean;
    external?: boolean;
    isBlob?: boolean;
    params?: string[] 
  }
  export interface ApiParamsInterface{
    key: string,
    value: string
  }
  export interface TagObject {
    tag: string; // nombre de la variable
    value: boolean; // si aparece .value dentro de las llaves
  }
  export interface TagCalculateItem {
    tag: string; // nombre de la variable
    tags: TagObject[]; // si aparece .value dentro de las llaves
  }
  interface DialogOptions {
    title: string;
    message: string;
    confirmOnly?: boolean;
    confirmText?: string;
    cancelText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
  }

export class SharedService{
    static singleton: SharedService; 
    static router: Router;
    static pinia: Pinia;

    static get shared(): SharedService{
        if(!SharedService.singleton){
            SharedService.singleton = new SharedService();
        
        }
        return SharedService.singleton;
    }
    static _routeState: RouterFacade
    static _context: Store<"context", any>;
    static _userStore: Store<"user", any>;
    static loadingObserver: (param: boolean)=> void
    static getMetricsFinhava: () => Metrics[]  = () => {
        const {metrics} = useForms();
        
        return metrics.value.sort((m1, m2) => m1.title < m2.title ? -1 : 1);

    }
    
    get basicUrl(): string {
        return environment.VITE_API_URL;
/*         if(window.location.href.includes("localhost")){
            return environment.VITE_API_URL_LOCAL;
        }else {
            return environment.VITE_API_URL;
        }
 */        
    } 
    
    http: AxiosInstance;
    httpFinhava: AxiosInstance;
    toast;
    genericDialogVisible = ref<boolean>(false);
    genericDialogOptions = ref<DialogOptions|null>(null);
    genericDialogPromise: ((value: boolean) => void)  | null = null;

    get router(): Router{
        if(!SharedService.router){
            SharedService.router = useRouter();
        }
        return SharedService.router;
    }
    set router(value: Router){
         SharedService.router = value;
    }
    get pinia(): Pinia{
        return SharedService.pinia;
    }
    set pinia(value: Pinia){
        SharedService.pinia = value;
    }
    
    get context(): Store<"context", any> {
        if(!SharedService._context)
            SharedService._context = GlobalState();
        return SharedService._context;

    }
    get userStore(): Store<"user", any> {
        if(!SharedService._userStore)
            SharedService._userStore = UserStore();
        return SharedService._userStore;
    }
    get user() : LoginResponseDto{
        return this.userStore.user;
    }
    _isLoading: boolean = false;
    get isLoading(): boolean{
        return this._isLoading;
    }
    set isLoading(value: boolean){
        this._isLoading = value;
        if(SharedService.loadingObserver){
            SharedService.loadingObserver(value);
        }
        
        
    }
    //moc = import('../moc/users.json')
    constructor(){
        this.http = axios.create({
            baseURL: this.basicUrl
        });
        this.httpFinhava = axios.create({
            baseURL: environment.VITE_FINHAVA
        })

        
    }
    get urlBase(){
        return environment.VITE_API_URL;
/*         if(window.location.href.includes("localhost")){
            return environment.VITE_API_URL_LOCAL;
        }else {
            return environment.VITE_API_URL;
        }
 */        
    }
    get routeState(): RouterFacade{
        if(!SharedService._routeState){
            SharedService._routeState = new RouterFacade();
        }
        return SharedService._routeState;
    }
    token: string ="";

    navigate(path: string){
        this.router.replace(path);
        //this.routeState.navigate(path);
    }
    logout(){

        const {reset} = useEntity();
        reset();
        
        const {resetGlobal} = GlobalState();
        resetGlobal();
        const {release} = UseFormListStore();
        release();
        const {clear} = useUsers();
        clear();
        this.navigate("/login")
        //this.router.replace("/login");
        
        this.routeState.reset();
        
        localStorage.clear();

    }
    getParentEntity(entity: Entity): string{
        switch(entity.entityType){
            case entityTypes.GROUP:
                return entity.clientId!;
            case entityTypes.ENTITY:
                return entity.groupId!;
            case entityTypes.CLIENT:
            default:
                return "";
        }
    }
    getCurrentClient() : string{
        const {currentContext} = this.context;
        
        if(!currentContext) return null;
        const context: Context = currentContext;
        let clientId;
        if(context.entity.entityType === entityTypes.CLIENT){
            clientId = context.entity.id;
        }else{
            clientId = context.entity.clientId && context.entity.clientId.trim().length ? context.entity.clientId : null;
        }
        return clientId;

    }
    getParentUser(user?: LoginResponseDto): string {
        if(!user) user = this.user;
        switch(user.role){
            case roles.CLIENT:
                return user.clientId!;
            case roles.GROUP:
                return user.groupId!;
            case roles.ENTITY:
            case roles.USER:
                return user.entityId!;
            case roles.ROOT: 
            default:
                return "";
        }
    }
    isNullId(id: any){
        if(id === null || id === undefined || !id.trim().length) return true;
        return false;
    }
    toNull(id: any){
        if(this.isNullId(id)) return null;
        return id;
    }
    isNullOrEmptyOrZero(obj: any){
        if(obj === null  || obj === undefined) return true;
        switch (typeof obj){
            case 'bigint':
            case 'number':
                return obj === 0 || obj === 0.00;
            case 'object':
                return Object.keys(obj).length ===0;
            case 'string':
                return obj.trim().length === 0;
        }
    }
    isNullOrZeroString(obj:string) {
        const int: boolean = !isNaN(parseFloat(obj));
        
        if(int) return parseFloat(obj) === 0.00;
        if(obj === null) return true;
        return !obj.trim().length
    }
    clearObject(obj: any){
        for(const k of Object.keys(obj)){
            if(obj[k] === null || obj[k] === undefined){
                delete obj[k]
            }
            if(typeof obj[k]  === "object"){
                this.clearObject(obj[k]);
            }
        }
        return obj;
    }
    round(num: number){
        if (isNaN(num)) return 0;
        const numb = Math.round(num*100);
        return numb/100;
    }
    goDefaultPath(){
        const { user, loged } = useUsers();
        let url = "";
        if(loged && user.value.id.trim().length){
            switch(user.value.role){
                case roles.ROOT: 
                    url = "/clients";
                    break;
                case roles.CLIENT:
                    url = "/groups" ;
                    break;
                case roles.GROUP: 
                    url = "/entities";
                    break;
                case roles.ENTITY:
                case roles.USER:
                    url = "/entity" ;
                    break;
            }
            this.router.push(url);
        }
    }
    async getEntitiesToSelect(parentId: string|null ): Promise<{id: string, name: string}[]> {
            return await  this.api<{id: string, name: string}[]>({
                method: 'GET',
                route: parentId ? apiRoutes.entitySimpleList : apiRoutes.entitySimpleListClients,
                params: parentId ? [parentId] : []
            });
    
    }

    async finhavaApi(route: string, params: string[]){
        this.isLoading = true;
        ///return {};
        const getparams = (params: string)=>{
            const pattern = /\{\d+\}/g;
            return params.match(pattern);
        }

        try{
            const user = environment.VITE_FINHAVA_USER;
            const password = environment.VITE_FINHAVA_PASSWORD;
            let url = route.startsWith('/') ? route : "/"+route;
            
            if(params && params.length){
                const paramsA = getparams(url);
                for(let x=0;x<params.length; x++){
                    url = url.replace(`{${x.toString()}}`, params[x])
                }
                
                if(paramsA && paramsA.length !== params.length){
                    for(let x=params.length; x<paramsA.length; x++){
                        url = url.replace(`/${paramsA[x]}`, "")
                    }
                }
                
            }
            const response = await this.httpFinhava.get<any>(url, {auth: {
                username: user,
                password: password
            }} );
            if(response.data) return response.data;
            return false;

        }catch(e){
            console.log("Error finhava api", e);
            return false;
        }finally{
            this.isLoading = false;
        }
    }
    async api<T> (options: ApiInterface): Promise<T>{1
        this.isLoading = true;
        try{
            const moc = hasMocData(options.route);
            const http: AxiosInstance = options.external ? axios.create() : this.http;

            if(moc){
                this.isLoading = false;
                return new Promise<T>((res, rej) => {
                    const data = moc(options.data);
                    res(data);
                    
                })
            }
            const getparams = (params: string)=>{
                const pattern = /\{\d+\}/g;
                return params.match(pattern);
            }
            options.auth = options.auth!==undefined ? options.auth : true;
            options.type = options.type!== undefined ? options.type : 'json';
            options.headers = options.headers !== undefined ? options.headers : {};
            const {user} = useUsers();

            if(options.type === 'json'){
                options.headers = {
                ...options.headers,
                'Content-type': 'application/json',
                'Accept': 'application/json'
                }
            }
            if(options.auth && user && user.value && user.value.token){
                options.headers = {
                ...options.headers,
                'Authorization': `Bearer ${user.value.token}`
            }
            }

            const httpOptions =  {headers: options.headers};
            if(options.isBlob){
                httpOptions["responseType"] = "blob";
            }
            let request: Promise<AxiosResponse<T, any>> | null = null;
            let url = (options.route.startsWith('/') || options.external  ? options.route : "/"+options.route);
            
            if(options.params && options.params.length){
                const params = getparams(url);
                for(let x=0;x<options.params.length; x++){
                    url = url.replace(`{${x.toString()}}`, options.params[x])
                }
                
                if(params && params.length !== options.params.length){
                    for(let x=options.params.length; x<params.length; x++){
                        url = url.replace(`/${params[x]}`, "")
                    }
                }
                
            }
            
            switch(options.method){
                case 'GET':
                    request = http.get<any>(url, httpOptions );
                    break;
                case 'POST':
                    request = http.post<any>(url, options.data, httpOptions);
                    break;
                case 'PUT':
                    request = http.put<any>(url, options.data, httpOptions);
                    break;
                case 'DELETE':
                    request = http.delete<any>(url, httpOptions);
                    break;
                default:
                    request = http.get<any>(url, httpOptions );
                    break;

            }

            const data = await request;
                const result: T = data.data;
                return result as T;
        }catch(err:any){
            this.isLoading = false;
            if(err.response && err.response.status && err.response.status === 401){
                await Sh.router.push("/login")
            }
            throw err;
        }finally{
            this.isLoading = false;
        }

        
    }
    hasResponseError(res: ResponseString){
        return res.startsWith("E_");
    }
    t(key: string ){
        return "";

    }
    fileToJson = async (file: File)  => {
        return {
            
            fileName: file.name,
            fileType: file.type,
            fileContent: await this.fileToBase64(file),
      }
       
    }
    fileToBase64 = async (file: File): Promise<string> => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      };
    deepCopy = (obj: any): any  => {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        const copy:any = Array.isArray(obj) ? [] : {};
      
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = this.deepCopy(obj[key]);
          }
        }
        return copy;
      }
    replaceAll(str, search, replacement) {
        // Crea una expresión regular que busque todas las ocurrencias de la palabra (g = global)
        return str.replace(new RegExp(search, 'g'), replacement);
    }
    convertDateStringEng(str: string){
        str = this.replaceAll(str, "/", "-");
        str = str.substring(0,10 )
        
        const strSplitter = str.split("-");

        if(strSplitter[0].length>2) return str;
        return strSplitter[2]+"-" + strSplitter[1] + "-" + strSplitter[0];
    }
    toShortDate(value: any, toEng: boolean = false, separator: string = "-"){
        if(this.isNullOrEmptyOrZero(value)) return "";
        if(typeof value ==="string"){
            value = new Date(this.convertDateStringEng(value));
        }
        const dia = value.getDate();
        const mes = value.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que se suma 1
        const anio = value.getFullYear();
        
        // Asegúrate de que el día y el mes tengan dos dígitos
        const diaFormateado = dia < 10 ? `0${dia}` : dia;
        const mesFormateado = mes < 10 ? `0${mes}` : mes;
        
        // Crea la cadena con el formato deseado
        let fechaFormateada = ``;
        if(toEng){
            fechaFormateada = `${anio}${separator}${mesFormateado}${separator}${diaFormateado}`
        }else{
            fechaFormateada = `${diaFormateado}${separator}${mesFormateado}${separator}${anio}`;
        }
        
        
        return fechaFormateada; 

    }
    toDateTime(value: any, toEng: boolean = false, separator: string = "-"){
        if(typeof value ==="string"){
            value = new Date(value);
        }
        const dia = value.getDate();
        const mes = value.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que se suma 1
        const anio = value.getFullYear();
        const min = value.getMinutes();
        const hour = value.getHours();

        const diaFormateado = dia < 10 ? `0${dia}` : dia;
        const mesFormateado = mes < 10 ? `0${mes}` : mes;
        const hourFormateado = hour < 10 ? `0${hour}` : hour;
        const minFormateado = min < 10 ? `0${min}` : min;
        let fechaFormateada = ``;
        if(toEng){
            fechaFormateada = `${anio}${separator}${mesFormateado}${separator}${diaFormateado} ${hourFormateado}:${minFormateado}`;
        }else{
            fechaFormateada = `${diaFormateado}${separator}${mesFormateado}${separator}${anio} ${hourFormateado}:${minFormateado}`;
        }
        
        
        return fechaFormateada; 
    }
    selectCase(value: any, compare: any[][]){
        for(const v of compare){
            if(value === v[0]) return v[1]
        }
        return null;
    }
    formatNumber = (value: number | number[] | string): string =>{
        let numbers: number[];
      
        // Si el valor es un número, lo convierte a un array con un solo elemento
        if (typeof value === 'number') {
          numbers = [value];
        }
        // Si el valor es una cadena, intenta convertirlo a un número
        else if (typeof value === 'string') {
          const parsedNumber = parseFloat(value.replace(',', '.'));
          numbers = isNaN(parsedNumber) ? [0] : [parsedNumber];
        }
        // Si el valor es un array, intenta convertir cada elemento a un número
        else if (Array.isArray(value)) {
          numbers = value.map((num) => typeof num === 'number' ? num : 0);
        }
        // En caso contrario, devuelve [0]
        else {
          numbers = [0];
        }
      
        // Formatea cada número del array utilizando la opción "es-ES" para el separador de miles y el separador decimal
        const formattedNumbers = numbers.map((num) => num.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      
        // Si el valor original era un array, devuelve el array formateado, de lo contrario, devuelve el primer elemento formateado
        return Array.isArray(value) ? formattedNumbers.join(', ') : formattedNumbers[0];
      }
    validateEmail(email: string) {
        return email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }
    validatePhone(phone: string){
        const without_spaces = phone.replace(/[\s-]+/g, '');
        return  /^-?\d+(\.\d+)?$/.test(without_spaces);
    }

    parseStringToObjects = (input: string, weight: number): { title: string; value: number }[] => {
        const lines = input.split(/\n/);
        const objects: { title: string; value: number }[] = [];
        for (const line of lines) {
          const elements = line.split(',');
          for (const element of elements) {
            const titleMatch = element.match(/^(.*?)\s*\{/);
            const valueMatch = element.match(/\{(\d+)\}/);
            if (titleMatch && valueMatch) {
              const title = titleMatch[1].trim();
              const value = parseInt(valueMatch[1]);
              objects.push({ title, value });
            }else{
                const title = element.trim();
                const value = weight;
                objects.push({ title, value });
            }
          }
        }
      
        return objects;
    }
    evaluateFormulaold = (formula: string, items: {name: string, weight: number, value: number}[]): number => {
        const itemMap: { [key: string]: {name: string, weight: number, value: number} } = {};
      
        for (const item of items) {
          itemMap[item.name] = item;
        }
      
        const pattern = /\{(\w+)\.?(weight|value)?\}/g;
        const evaluatedFormula = formula.replace(pattern, (_, itemName, property) => {
          const item = itemMap[itemName];
          if (!item) {
            throw new Error(`Referencia inválida a '${itemName}' en la fórmula.`);
          }
      
          if (property === 'value') {
            return item.value.toString();
          } else {
            return item.weight.toString();
          }
        });
      
        return Function("return "  + evaluatedFormula)();
      }

       evaluateFormula = (formula: string, items: any[]): number => {
        const variableRegex = /{(\w+)(\.value)?}/g;
        const evaluatedFormula = formula.replace(variableRegex, (match, varName, isValue) => {
          const item = items.find((item) => item.name === varName);
          if (!item) return "0"; // Default value if variable is not found
      
          if (isValue) {
            const value = Array.isArray(item.value) ? item.value.reduce((a:number, b:number) => a + b, 0) : item.value;
            return value.toString();
          } else {
            return item.weight.toString();
          }
        });
                return Function("return "  + evaluatedFormula)(); // Using eval to evaluate the formula
      }

       getVarsFromFormula(formula: string): string[] {
        const regex = /\{(\w+)\}/g; // Expresión regular para encontrar variables entre llaves sin puntos
        const matches = formula.match(regex); // Buscar todas las coincidencias
      
        if (matches) {
          // Procesar las coincidencias y extraer las variables
          const variables = matches.map(match => match.substring(1, match.length - 1));
          return [...new Set(variables)]; // Eliminar duplicados usando un conjunto y convertirlo nuevamente a un array
        }
      
        return [];
      }
        
        showToast(message, seconds?: number, danger?: boolean) {
            if  (!this.toast){
                this.toast = document.createElement('div');
                this.toast.id = "toast";
                this.toast.classList.add('toast');
                this.toast.textContent = message;
                if(danger) this.toast.classList.add('toastDanger');
                if(danger!=null && !danger){
                    this.toast.classList.add('toastOk');
                }
                document.body.appendChild(this.toast);
            }
            this.toast.classList.remove("toastHiden");
            if(seconds){
                setTimeout(() => {
                    this.hideToast();
                }, seconds * 1000)
            }
        }
        hideToast(){

            if(this.toast){
                this.toast.classList.remove("toastOk");
                this.toast.classList.remove("toastDanger");
                this.toast.classList.add("toastHiden");
                document.body.removeChild(this.toast);
                this.toast = null;
            }
        }

    getFontSize(value: any, def?: any) {
        if(!value) return `${def.toString()}px`;
        value = value.toString().replace("px", "");
        return value.toString() + "px";
    }
    toJson(value: any){
        if(typeof value == "string") return value;
        if(typeof value == "number") return value.toString();
        if(typeof value == "boolean") return value ? "true" : "false";
        if(typeof value == "bigint") return value.toString();
        if(typeof value == "undefined") return "";
        if(value == null) return null;
        if(value instanceof Date) return this.toDateTime(value, true, "-");
        return JSON.stringify(value);
    }
    

    //#region  gestión de fórmulas


        extractTags(input: string): TagObject[] {
            // Expresión regular para encontrar los patrones dentro de llaves
            const regex = /{([^}]+)}/g;
            const matches = input.matchAll(regex);
        
            // Array para almacenar los objetos resultantes
            const result: TagObject[] = [];
        
            for (const match of matches) {
                const fullMatch = match[1]; // Contenido dentro de las llaves
        
                // Verificar si el contenido tiene ".value"
                const hasValue = fullMatch.includes('.value');
                
                // Si tiene ".value", tomar el texto antes de él
                // Si no, tomar el contenido completo
                const tag = hasValue ? fullMatch.split('.value')[0] : fullMatch;
        
                result.push({
                    tag: tag,
                    value: hasValue
                });
            }
        
            return result;
        }



        isFormulaValid(input: string): boolean | number {
            // Primero, validamos la sintaxis básica de la expresión
            if (!this.validateSyntax(input)) {
                return false;
            }
        
            // Reemplazamos los tags por números ficticios
            const formulaWithNumbers = this.replaceTagsWithNumbers(input);
        
            // Intentamos evaluar la expresión
            try {
                // Intentamos analizar la expresión utilizando math.js
                const parsedExpression = parse(formulaWithNumbers);
        
                // Verificamos que la expresión parseada no contenga ningún error
                if (parsedExpression) {
                    // Evaluamos la expresión parseada
                    const result = parsedExpression.evaluate();
        
                    // Verificamos que el resultado sea un número válido
                    return typeof result === 'number' && !isNaN(result);
                } else {
                    return false;
                }
            } catch (error) {
                // En caso de error en el análisis o evaluación
                return false;
            }
        }
        evaluateForm(input: string) : boolean | number {
            if (!this.validateSyntax(input)) {
                return false;
            }
            try {
                // Intentamos analizar la expresión utilizando math.js
                const parsedExpression = parse(input);
        
                // Verificamos que la expresión parseada no contenga ningún error
                if (parsedExpression) {
                    // Evaluamos la expresión parseada
                    const result = parsedExpression.evaluate();
        
                    // Verificamos que el resultado sea un número válido
                    return typeof result === 'number' && !isNaN(result) ? result : false;
                } else {
                    return false;
                }
            } catch (error) {
                // En caso de error en el análisis o evaluación
                return false;
            }
        }
        validateSyntax(input: string): boolean {
            const cleanInput = input.replace(/\s+/g, '');

            // Contador de paréntesis
            let parenthesisCount = 0;
        
            // Expresión regular para caracteres válidos
            const validChars = /^[0-9+\-*/().{}a-zA-Z\d\-\s.value]+$/;
        
            // Verificar si el input contiene algún caracter inválido
            if (!validChars.test(cleanInput)) {
                return false;
            }
        
            // Verificar balance y posición de paréntesis y operadores
            for (let i = 0; i < cleanInput.length; i++) {
                const char = cleanInput[i];
        
                if (char === '(') {
                    parenthesisCount++;
                } else if (char === ')') {
                    parenthesisCount--;
                    if (parenthesisCount < 0) {
                        return false;
                    }
                }
        
                // Verificar que no haya operadores consecutivos o al inicio o final
                if (/([*/]{2,})/.test(cleanInput) || /^[*/]/.test(cleanInput) || /[*/]$/.test(cleanInput)) {
                    return false;
                }
            }
        
            // Verificar si los paréntesis están equilibrados
            return parenthesisCount === 0;
        }
        
        replaceTagsWithNumbers(input: string): string {
            return input.replace(/{([^}]+)}/g, '1');
        }


        topologicalSortFormulas(items: TagCalculateItem[]): TagCalculateItem[] {
            try {
            const allTags = new Set<string>();
            for (const item of items){
                allTags.add(item.tag);
                for(const tagObject of item.tags){
                    allTags.add(tagObject.tag);
                }
            }

            // Inicializar el grafo y los grados de entrada
            const graph = new Map<string, Set<string>>(); // Mapa de dependencias
            const indegree = new Map<string, number>(); // Grado de entrada
            
            // Inicializar el grafo y los grados de entrada
            for (const item of items) {
                graph.set(item.tag, new Set());
                indegree.set(item.tag, 0);
            }

            // Construir el grafo de dependencias
            for (const item of items){
                for (const tagObject of item.tags) {
                    const dependencyTag = tagObject.tag;
                    if (dependencyTag != item.tag) {
                        if (!graph.has(dependencyTag)) {
                            // Inicializamos si no está el elemento por dependencia
                            graph.set(dependencyTag, new Set());
                            indegree.set(dependencyTag, 0);
                        }
                        // Añadir la dependencia al grafo
                        graph.get(dependencyTag)!.add(item.tag);
                        // Incrementar el grado de entrada de la fórmula dependiente
                        indegree.set(item.tag, indegree.get(item.tag)! + 1);
                    }
                }
            }

            // Cola para procesar los ítems con grado de entrada 0
            const queue: string[] = [];
            const sortedItems: TagCalculateItem[] = [];
        
            // Agregar ítems con grado de entrada 0 a la cola
            for (const [tag, count] of indegree.entries()) {
                if (count === 0) {
                    queue.push(tag);
                }
            }
            
            // Procesar los ítems en orden topológico
            while (queue.length > 0) {
                const tag = queue.shift()!;
                const item = items.find(f => f.tag === tag)!;
                if (item){
                    sortedItems.push(item);
                }

                // Reducir el grado de entrada de los ítems dependientes
                const dependents = graph.get(tag) || new Set();
                for (const dependentTag of dependents) {
                    indegree.set(dependentTag, indegree.get(dependentTag)! - 1);
                    if (indegree.get(dependentTag) === 0) {
                        queue.push(dependentTag);
                    }
                }
            }

            if (sortedItems.length !== items.length) {
                throw new Error("No se pueden ordenar los ítems debido a dependencias cíclicas.");
            }

            return sortedItems;
        } catch (e) {
            console.log(e);
            throw new Error("No se pueden ordenar los ítems debido a dependencias cíclicas.");
        }
            /*
            // Mapear cada fórmula a su lista de dependencias
        
        
            // Construir el grafo de dependencias
            for (const item of items) {
                for (const tagObject of item.tags) {
                    const dependencyTag = tagObject.tag;
                    if (dependencyTag !== item.tag && graph.has(dependencyTag)) {
                        // Añadir la dependencia al grafo
                        if (!graph.has(item.tag)) {
                            graph.set(item.tag, new Set());
                        }
                        graph.get(dependencyTag)!.add(item.tag);
                        // Incrementar el grado de entrada de la fórmula dependiente
                        indegree.set(item.tag, (indegree.get(item.tag) || 0) + 1);
                    }
                }
            }
        
            // Cola para procesar los ítems con grado de entrada 0
            const queue: string[] = [];
            const sortedItems: TagCalculateItem[] = [];
        
            // Agregar ítems con grado de entrada 0 a la cola
            for (const [tag, count] of indegree.entries()) {
                if (count === 0) {
                    queue.push(tag);
                }
            }
        
            // Procesar los ítems en orden topológico
            while (queue.length > 0) {
                const tag = queue.shift()!;
                const item = items.find(f => f.tag === tag)!;
                sortedItems.push(item);
        
                // Reducir el grado de entrada de los ítems dependientes
                const dependents = graph.get(tag) || new Set();
                for (const dependentTag of dependents) {
                    indegree.set(dependentTag, indegree.get(dependentTag)! - 1);
                    if (indegree.get(dependentTag) === 0) {
                        queue.push(dependentTag);
                    }
                }
            }
        
            // Verificar si hay ítems que no se pueden ordenar debido a dependencias cíclicas
            if (sortedItems.length !== items.length) {
                throw new Error("No se pueden ordenar los ítems debido a dependencias cíclicas.");
            }
        
            return sortedItems;*/
        }
    //#endregion gestión de fórmulas
  showGenericDialog = (options: DialogOptions): Promise<boolean>  => {
    this.genericDialogOptions.value = options;
    this.genericDialogVisible.value = true;
    return new Promise((resolve) => {
        this.genericDialogPromise = resolve;
    });
  }

  closeGenericDialog = (confirmed: boolean) => {
    this.genericDialogVisible.value = false;
    this.genericDialogOptions.value = null;

    if(this.genericDialogPromise){
        this.genericDialogPromise(confirmed);
        this.genericDialogPromise = null;
    }
  }
  getObjectFromStringKeyValueOld =(keyPar: string ): {} => {
    const lines = keyPar.split('\n');
      const resultado: { [key: string]: string } = {};
  
      lines.forEach(linea => {
          if (linea.trim() !== '') {
              // Elimina comas al final de la línea
              linea = linea.replace(/,$/, '');
              // Divide la línea en clave y valor, ignorando espacios alrededor del igual
              const [clave, valor] = linea.split('=').map(part => part.trim());
              // Elimina comillas alrededor del valor
              const valorLimpio = valor.replace(/^["']|["']$/g, '');
              // Elimina corchetes de la clave
              const claveLimpia = clave.replace('[', '').replace(']', '');
              if (claveLimpia && valorLimpio) {
                  resultado[claveLimpia] = valorLimpio;
              }
          }
      });

      return resultado;
  }
  getObjectFromStringKeyValue = (keyPar: string): {} => {
    keyPar =  keyPar.replace(/\r/g, '');
    const lines = keyPar.split('\n');
    const resultado: { [key: string]: string } = {};
  
    lines.forEach(linea => {
      if (linea.trim() !== '') {
        // Elimina la coma al final de la línea, si existe
        linea = linea.endsWith(',') ? linea.slice(0, -1) : linea;
        // Divide la línea en clave y valor, ignorando espacios alrededor del igual
        const partes = linea.match(/^([^=]+)\s*=\s*(.*)$/);
        if (partes) {
          const clave = partes[1].trim();
          const valor = partes[2].trim();
          // Elimina comillas alrededor del valor
          const valorLimpio = valor.replace(/^["']|["']$/g, '');
          const claveLimpia = clave.replace('[', '').replace(']', '');
          resultado[claveLimpia] = valorLimpio;
        }
      }
    });
  
    return resultado;
  }
}
export const Sh: SharedService = SharedService.shared;