<script setup lang="ts">

import { Sh } from '@/core/sharedService';
import { FormCreatedItemDto, Form_type } from '@/core/store/forms/dtos';
import { useForms } from '@/core/store/forms/useForms';
import useUsers from '@/core/store/user/useUsers';
import DataTablesCore from 'datatables.net';
import DataTable from 'datatables.net-vue3';
import { computed, ref } from 'vue';
import { optionsTable } from './../../common';
import assignForm from '@/views/forms/assignForm/assignForm.vue';
import { storeToRefs } from 'pinia';
import { GlobalState } from '@/core/store/global/gobalState';
import { entityTypes, form_type, roles } from '@/shared/globaEnums';
import BadgeComponent from "../../components/badge.vue";
import formsIcon from "/assets/forms-icon.svg";
import plusButton from "../../components/plusButton.vue";
import Survey from '@/core/store/survey/surveyStore';
import environment from '@/environments/environments';


DataTable.use(DataTablesCore);
const { listFormsCreated, forms: formsC, setCurFormItem, getEntitiesForms, setEntitiesForms, selectedEntitiesToForm, getForm } = useForms();
const { addDesign, setDesign, isDesign, loadDesign, canRemoveDesign, removeDesign, currentResponse, currentDesign } = Survey()
//const route = useRoute();
const { user, list, userList } = useUsers();
const { currentContext } = storeToRefs(GlobalState())
const title = computed<string>(() => {
  if(user.value.role === roles.CLIENT) return "Edición de formularios";
  return "Asignación de formularios";
});
const isForm = ref<boolean>(true);
let formTipo = "FORM";
const forms = computed(() => {

  return formsC.value.filter(f => f.type === (isForm.value ? Form_type.FROM : Form_type.COMPONENT))
});
const load = async () => {
  await listFormsCreated();
}
load();
const isDialog = ref<boolean>(false)


const newEntity = () => {
  // New survey desing
  addDesign();
  isDesign.value = true;
  Sh.router.push("/forms/view/design")
  // old design
  //Sh.router.push("/forms/new")
}
const canNew = ref<boolean>(user.value.role === roles.CLIENT);
const asign = async (id: string) => {

  setCurFormItem(id);
  if (currentContext.value.entity.entityType === entityTypes.CLIENT) {
    await getEntitiesForms(entityTypes.GROUP);
  } else {
    await getEntitiesForms(entityTypes.ENTITY, currentContext.value.entity.id);
  }
  isDialog.value = true;

}
const changeTipo = () => {
  isForm.value = formTipo === "FORM";
}
const viewItem = async (id: string, edit: boolean = false) => {
  //await getForm(id);
  await loadDesign(id);

  Sh.router.push('/forms/view/design');
}

const onCloseDialog = () => {
  isDialog.value = false;
}

const onSaveDialog = async () => {
  isDialog.value = false;
  //    if(selectedEntitiesToForm.value.length){
  if (currentContext.value.entity.entityType === entityTypes.CLIENT) {
    await setEntitiesForms(entityTypes.GROUP, selectedEntitiesToForm.value);
  } else {
    await setEntitiesForms(entityTypes.ENTITY, selectedEntitiesToForm.value, currentContext.value.entity.id);
  }
  // }
}

const options = {
  ...optionsTable,
  pageLength: 5,
  drawCallback: function (settings: any) {
    const rows = settings.aoData;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].nTr;
      const cellAssign = row.querySelector('.assign');
      const viewSelect = row.querySelector('.viewSelect');
      const editSelect = row.querySelector('.editSelect');
      const deleteSelect = row.querySelector('.deleteSelect');
      const pdfSelect = row.querySelector('.pdfSelect');

      if (cellAssign) {
        cellAssign.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          asign(data.id);
        });
      }

      if (viewSelect) {
        viewSelect.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          viewItem(data.id, false);
        });
      }

      if (editSelect) {
        editSelect.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          viewItem(data.id, true);
        });
      }

      if (deleteSelect) {
        deleteSelect.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          deleteItem(data.id);
        });
      }

      if (pdfSelect) {
        pdfSelect.addEventListener('click', () => {
          const data = settings.aoData[i]._aData;
          const pdfUrl = getUrlPrint(data.id, data.entityId); // Generar la URL
          window.open(pdfUrl, '_blank'); // Abre el PDF en una nueva pestaña
        });
      }
    }
  }
};


const deleteItem = async (id) => {
  try {
    const canDeleteResponse = await canRemoveDesign(id); 
    
    if (canDeleteResponse === true) {
      const confirmDelete = await Sh.showGenericDialog({
        title: 'Eliminar formulario',
        message: '¿Estás seguro de que deseas eliminar este formulario?',
      });
      
      if (confirmDelete) {
        const deleteResponse = await removeDesign(id);
       
        if (deleteResponse) {
          Sh.showToast('Formulario eliminado con éxito');
          await listFormsCreated();
        } else {
          Sh.showToast('Error al eliminar el formulario');
        }
      }
    } else if (canDeleteResponse === false) {
      Sh.showToast('El formulario no puede ser eliminado porque está en uso o tiene dependencias.');
    } else {
      Sh.showToast('Error al verificar si el formulario puede ser eliminado.');
    }
  } catch (error) {
    console.error('Error al eliminar el formulario:', error);
    Sh.showToast('Hubo un problema al eliminar el formulario.');
  }
};


const getUrlPrint = (id: string, entityId: string | null) => {
  let url = environment.VITE_API_URL;
  if (entityId) {
    url += `/survey/response/print/${id}/${entityId}`;
  } else {
    url += `/survey/design/print/${id}`;
  }
  return url;
};


const actions = (data) => {
  let html = "";

  // ASIGNAR
  if (data["type"] == "FORM") {
    html += `<img class="actionIcon assign" src="/assets/add_action_icon.png" alt="add_icon">`;
  }
  // VER (todos pueden ver)
  html = `
    <img class="actionIcon viewSelect" src="/assets/eye_action_icon.png" alt="eye_icon">
    ${html}
  `;
  // PDF (todos pueden imprimir)
  html += `
    <img class="actionIcon pdfSelect" src="/assets/pdf_action_icon.png" alt="pdf_icon">
  `;
  // ELIMINAR (solo ROOT o CLIENT pueden eliminar)
  if (user.value.role === roles.ROOT || user.value.role === roles.CLIENT) {
    html += `
      <img class="actionIcon deleteSelect" src="/assets/delete_action_icon.png" alt="trash_icon">
    `;
  }

  return html;
};




const getType = (type: Form_type) => {
  if (!type) return "";
  switch (type) {
    case Form_type.FROM:
      return "Formulario";
    case Form_type.COMPONENT:
      return "Componente";
  }
}
const columns = [
  { data: 'create', width: '10%', render: (param1: any, param2: any, param3: FormCreatedItemDto) => Sh.toShortDate(param3.create) },
  { data: 'name', width: '32%' },
  { data: 'description', width: '35%' },
  { data: 'endDate', width: '10%', render: (param1: any, param2: any, param3: FormCreatedItemDto) => Sh.toShortDate(param3.endDate) },
  { data: null, render: actions, orderable: false, width: '11%' },
]

</script>

<template>
  <div class="container-client">
    <div class="header-container">
      <assignForm :isOpen="isDialog" :onClose="onCloseDialog" :onSave="onSaveDialog" />

      <BadgeComponent :icon="formsIcon" :label="title" />
      <plusButton  v-if="user.role === roles.CLIENT" label="Nuevo Formulario" @click="newEntity()" />
    </div>
    <div class="row radioContainer" v-if="user.role === roles.CLIENT">
      <div class="radioItem1">
        <label class="radio-button" for="formulario2">
          <input id="formulario2" type="radio" name="formulario" value="FORM" v-model="formTipo" @change="changeTipo">
          <span class="radio"></span>
          Formularios
        </label>
      </div>
      <div class="radioItem2">
        <label class="radio-button" for="formulario1">
          <input type="radio" id="formulario1" name="formulario" value="COMPONENT" v-model="formTipo"
            @change="changeTipo">
          <span class="radio"></span>
          Componentes
        </label>
      </div>
    </div>
    <div class="table-responsive">
      <DataTable :options="options" :data="forms" :columns="columns" width="100%">
        <thead>
          <tr>
            <th>Creación</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Fecha fin</th>
            <!-- <th>tipo</th> -->
            <th class="actionsTh"></th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>



<style scoped>
.dataTables_length {
  display: none;
}

* {
  background-color: #fff;
}

.container-client {
  padding: 50px;
  border-radius: 22px;
  max-width: 80%;
  width: 100%;
  margin: -56px auto;
  box-shadow: 0px 0px 15px #0000002e;
}

.header-container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  width: 100%;
  text-align: left;
  margin-top: -30px;
}

.table-responsive th {
  padding: 5px;
  text-align: left;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  background-color: transparent !important;
}

.radioItem1 {
  position: absolute;
  top: 275px;
  left: 630px;
  width: fit-content;
  background-color: transparent !important;
}

.radioItem2 {
  position: absolute;
  top: 275px;
  left: 800px;
  width: fit-content;
  background-color: transparent !important;
}

@media (max-width: 1400px) {

  .header-container {
    margin-top: -15px;
    margin-bottom: 70px;
  }

  .radioItem1 {
    top: 495px;
    left: 185px;
  }

  .radioItem2 {
    top: 495px;
    left: 330px;
  }
}

@media (min-width: 2200px) {

  .header-container {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .radioItem1 {
    top: 275px;
    left: 685px;
  }

  .radioItem2 {
    top: 275px;
    left: 830px;
  }
}

.radio-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--primary-color);
  margin-right: 20px;
  background-color: transparent !important;
}

.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ccc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transform: translateZ(-25px);
  transition: all 0.3s ease-in-out;
}

.radio::before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.radio-button input[type="radio"]:checked+.radio {
  border-color: var(--primary-color);
  background-color: transparent !important;
}

.radio-button input[type="radio"]:checked+.radio::before {
  opacity: 1;
}

.actionsTh {
  display: flex;
  flex-direction: row;
  /* SUBIR ANCHURA CREARA SCROLL  HORIZONTAL */
  min-width: 94%;
}

@media (max-width: 1190px) {
  .actionsTh {
    height: 56px !important;
  }
}
</style>
