<template>
    <div class="badge">
      <div class="icon-container">
        <img :src="icon" alt="Icon" />
      </div>
      <div class="text-container">
        <span>{{ label }}</span>
      </div>
    </div>
  </template>
  
  <script setup lang='ts'>
    const props = defineProps({
      icon: String,
      label: String
    });


  </script>
  
  <style scoped>
  .badge {
    display: flex;
    align-items: center;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 50%; 
    width: 62px;
    height: 62px;
    margin-right: 10px;
    box-shadow: 5px 5px 10px #0000002e;
    z-index: 1;
  }
  
  .icon-container img {
    width: 35px;
    height: 38px;
  }

  .text-container {
    background-color: var(--secondary-color);
    padding: 10px 15px;
    margin-left: -50px;
    border-radius: 32px;
    box-shadow: 0px 0px 15px #0000002e;
  }

  .text-container span {
    padding-left: 40px;
    color: var(--white);
    font-size: 25px;
    font-weight: 500;
  }
  </style>
  <script lang="ts">
      export default {
        name: "BadgeComponent",
      }
  </script>