<script setup lang="ts">
 import { ComponentsDefinitions, type Item_type } from "@/core/store/forms/dtos";
import { type PropType, ref, onBeforeMount } from "vue";
import { useForms } from '@/core/store/forms/useForms';
 const showStatics = ref(true);
 const showForms = ref(true);
 const showOthers = ref(false);
 const comps = ref<{id: string, name: string}[]>([]);
  const {getComponents} = useForms();
  const props = defineProps({
    externalAction: {
      type: Function as PropType<(item: Item_type | 'COMPONENT', componentId?: string) => void>,
    },
  });

  const action = (option: Item_type | 'COMPONENT', id?: string) => {
    props.externalAction(option, id);
  };
  onBeforeMount(async () => {
    try{
      const comp = await getComponents();
      comps.value = comp;
    }
    catch(e){
     console.log(e, 'traza')
     console.trace()
      throw e;
    }
  });
</script>

<template>
  <section class="sidebar pt-3 d-inline-grid col-sm-2 border shadow">
    <h5 class="pt-2 pb-2 text-center">Agregar al formulario</h5>
    <div class="dropdown">
      <button
        @click="() => showStatics = !showStatics"
        class="sidebar-text-img-btn btn dropdown-toggle mx-4 my-2"
        type="button"
        aria-expanded="false">
          {{ComponentsDefinitions.texts.label}}
      </button>
      <transition name="accordion">
        <div v-if="showStatics">
          <ul class="dropdown-menu-text-images list-style-type-none col-10 border-0">
            <li v-for="item in ComponentsDefinitions.texts.components" :key="item.type" @click="() => action(item.type)">
              <label class="form-start-label form-start-dropleft-item my-2" >{{ item.labelMenu }}</label>
            </li>
        </ul>
      </div>
    </transition>
    </div>
    <div class="dropdown">
      <button
        @click="() =>{
          if(showForms) {
          showForms = false;
          showOthers = true;
        } else {
          showForms = true;
          showOthers = false;
        } }"
        class="sidebar-text-img-btn btn dropdown-toggle mx-4 my-2"
        type="button"
        aria-expanded="false">
          {{ ComponentsDefinitions.form.label }}
      </button>
      <div v-if="showForms">
        <ul class="dropdown-menu-text-images list-style-type-none col-10 border-0">
          <li v-for="item in ComponentsDefinitions.form.components" :key="item.type" @click="() => action(item.type)">
            <label class="form-start-label form-start-dropleft-item my-2">{{ item.labelMenu }}</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="dropdown">
      <button
        @click="() => { 
          if(showOthers) {
          showOthers = false;
          showForms = true;
        } else {
          showOthers = true;
          showForms = false;
        }  }"
        class="sidebar-text-img-btn btn dropdown-toggle mx-4 my-2"
        type="button"
        aria-expanded="false">
          {{ ComponentsDefinitions.components.label }}
      </button>
      <div v-if="showOthers" class="otherItems">
        <ul class="dropdown-menu-text-images list-style-type-none col-10 border-0">
          <li v-for="item in comps" :key="item.id" @click="() => action('COMPONENT', item.id)">
            <label class="form-start-label form-start-dropleft-item my-2">{{ item.name }}</label>
          </li>
        </ul>
      </div>
      <div class="pt-3 pb-4 mx-4 text-center">
        <button class="save-form-button btn text-white border-0" type="submit">
          Guardar formulario
        </button>
      </div>
    </div>
  </section>
</template>

<style>
.dropdown button{
  padding: 0px !important;
}
.accordion-enter-active,
.accordion-leave-active {
  transition: height 0.3s;
}

.accordion-enter,
.accordion-leave-to {
  height: 0;
  overflow: hidden;
}

input[type="checkbox"] {
  border-radius: 50%;
}
 
.sidebar { /**/
  width: 18em;
  
}

.sidebar-text-img-btn { /** */
  color: #005060;
  font-weight: bold;
}

.dropdown-menu-text-images {/** */
  display: block;
  margin-left: 2em;
  list-style-type: none;
  color: #005060;
  max-height: 350px;
  overflow-y: scroll;
}


.form-start-dropleft-item {/** */
  width: 10%;
}

.form-start-label {/** */
  cursor: pointer;
  width: 90%;
}

</style>
<script lang="ts">
  export default {
    name: 'formOptions',
  }
</script>