<script setup lang="ts">
  import { FormItemDto, FormResponseCompletedItemsDto   } from "@/core/store/forms/dtos";
  import { type PropType,  ref } from 'vue';
import {  type FormItem } from "@/views/components/formItemsDto";
import { labelTipos } from '../../core/store/forms/dtos';
import { Sh } from '@/core/sharedService';
import { Item_type } from "@/core/store/forms/dtos";
import DPDialog from '@/views/components/dialog.vue';
import { apiRoutes } from '@/core/apiRouters';
import { UseFormListStore } from '../forms/formStore';
import { useEntity } from '@/core/store/entities/useEntity';

  const props = defineProps({
    data: {type: Object as () => FormResponseCompletedItemsDto},
    extraValid: Function as PropType<(item: FormItemDto, value: FormItem ) => boolean>,
    canEdit: Boolean
  });
  const canEdit:boolean = props.canEdit == undefined ? false: props.canEdit;
  const template = ref<FormItemDto>(props.data.itemTemplate);
  const label: string = labelTipos.find(l => l.type == props.data.itemTemplate.itemType)?.labelMenu
  const hasError = ref<boolean>(false);
  const options = ref<{title: string, value: number}[]>(Sh.parseStringToObjects(template.value.value, template.value.weight))
  const selectedValues = ref<number[]>([])
  const selectedIntValue = ref<number>(-1)
  const imgWidth = ref<string>(template.value.weight ? template.value.weight.toString() + "px" : "175px");
  const imgPos = ref<string>(template.value.weightEmpty && template.value.weightEmpty < 2 ? "left" : (template.value.weightEmpty == 2 ? "center" : "right"));
  const dialogShow=ref<boolean>(false);
  const inputFile = ref(null);
  const hasDelay = ref<boolean>(false)
  if(template.value.itemType === Item_type.FORM_COMBOBOX_MULTIPLE){
    selectedValues.value = props.data.valueObj;
  }
  if(template.value.itemType === Item_type.FORM_COMBOBOX){
    selectedIntValue.value = props.data.valueObj.value;
  }
  if(template.value.itemType === Item_type.FORM_FINHAVA && !props.data.valueObj.value){
    hasDelay.value = true;
    console.log("hay retraso", hasDelay.value);
    const {curEntity} = useEntity();
    const {formToComplete} = UseFormListStore();
      const params = [
      `${template.value.finhavaYear.toString()}`,
      `${template.value.finhavaMonth.toString()}`,
      `${curEntity.value.finhavaId}`,
      `${template.value.finhavaMetric}`,
      ]
      const result = Sh.finhavaApi(apiRoutes.finhavaMetricValue, params ).then((data) => {
        hasDelay.value = false
        if(data.value && data.value.length){
          props.data.valueObj.value = data.value[0].Value;
        }
        console.log("hay retraso", hasDelay.value);
      }).catch(() => hasDelay.value = false)
      .finally(() =>hasDelay.value = false );
  }
  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file)
    try{
      const result = await Sh.api<{id: string, url: string, originalName: string}>({
      method: 'POST',
      route: apiRoutes.files,
      data:formData, 
      type: "multipart"
    });
    props.data.valueObj.id = result.id;
    props.data.valueObj.url = result.url;
    props.data.valueObj.originalName = result.originalName;
    }catch(e){
      console.log("ERror", e)
    }
    
  }
  const changeFile = async (value: any) =>{
      const input = value.target as HTMLInputElement;

      if(input.files?.length){
        uploadFile(input.files[0]);
        return;
      }
  }
  const onTrash = async () => {
    try{
      const result = await Sh.api<{result: boolean}>({
      method: 'DELETE',
      route: "/files/"+props.data.valueObj.id,
    });

    
    }catch (e){
      console.log("Error", e)
    }finally{
      dialogShow.value = false;
    }
    props.data.valueObj.id = "";
      props.data.valueObj.url = "";
      props.data.valueObj.originalName = "";
      inputFile.value.value = "";
  }
  const onSelectChange = () => {
    switch(template.value.itemType){
        case Item_type.FORM_COMBOBOX_MULTIPLE:
        props.data.valueObj = selectedValues;
        props.data.value = JSON.stringify(props.data.valueObj)
        break;

      case Item_type.FORM_COMBOBOX:

        props.data.valueObj.value = selectedIntValue.value;
        props.data.value = JSON.stringify(props.data.valueObj);   
        
        break;
    }
     
  }
  const valid = ():boolean => {
    if(template.value.itemType.includes("STATIC")) return true;
    hasError.value = false;
    if(template.value.required){
      switch(template.value.itemType){
        case Item_type.FORM_COMBOBOX_MULTIPLE:
          if(!selectedValues.value.length) hasError.value = true;
          break;
        case Item_type.FORM_DATE_RANGE:
          if(Sh.isNullOrEmptyOrZero(props.data.valueObj.init) || Sh.isNullOrEmptyOrZero(props.data.valueObj.end  )) hasError.value = true;
          break;
        case Item_type.FROM_IMAGE:
          if(!props.data.valueObj.id.trim().length) hasError.value = true;
          break;
        default:
          if ( ![Item_type.FORM_YES_NO, Item_type.FROM_IMAGE].includes(template.value.itemType) && Sh.isNullOrEmptyOrZero(props.data.valueObj.value)) hasError.value = true;
          break;       
      }
    }
    if(!hasError.value){
      if(template.value.itemType === Item_type.FORM_DATE_RANGE){
        const init = new Date(props.data.valueObj.init).getTime();
        const end  = new Date(props.data.valueObj.end).getTime();
        if(end<init) {
          hasError.value = true;
          return !hasError.value;
        }
      }
      if(template.value.itemType === Item_type.FORM_COMBOBOX_MULTIPLE && selectedValues.value.length){
        props.data.value = JSON.stringify(selectedValues.value.map(v =>options[v]))
      } else if(template.value.itemType === Item_type.FORM_COMBOBOX ){
        const idx = Array.isArray(selectedValues.value) ? 0 : selectedValues.value;
        props.data.value = JSON.stringify(options[idx])
      }else{
        props.data.value = JSON.stringify(props.data.valueObj);
      }
    }

    return !hasError.value;
  }
defineExpose({
  valid
}

)
</script>
<template>
  <div v-if="template.itemType ===Item_type.STATIC_TITLE">
      <h2> {{ template.value }} </h2>
  </div>

  <div v-if="template.itemType ===Item_type.STATIC_SUBTITLE">
      <h5> {{ template.value }} </h5>
  </div>

  <div v-if="template.itemType ===Item_type.STATIC_IMAGE" :style="{display: 'flex', justifyContent: imgPos}">
      <img :src="template.text" v-bind:style="{width: imgWidth}">
  </div>

  <div class="text" v-if="template.itemType ===Item_type.STATIC_TEXT && !template.required">
      <p v-html="template.value.split('\n').join('<br>')"></p>
  </div>

  <div v-if="template.itemType ===Item_type.FORM_NUMBER">
      <label>
        {{ template.label }} {{ template.required ? '*' : "" }}
      </label>
      <input  type="number" 
              v-model="data.valueObj.value" 
              :disabled="!canEdit"
              :class="[hasError ? 'input-with-error' : '']">
  </div>
  <div v-if="(template.itemType ===Item_type.FORM_FORMULE || template.itemType ===Item_type.FORM_FINHAVA) && template.required">
      <label>
        {{ template.label }} 
      </label>
      <div v-if="hasDelay" >
        
        <img src="@/assets/waiting.gif" alt="" style="width: 25px">
      </div>
      
      <label v-if="!hasDelay">
        {{ data.valueObj.value ?  Sh.round(data.valueObj.value) : "" }}
      </label>
      <br>
  </div>
<!--   <div v-if="template.itemType ===Item_type.FORM_FINHAVA">
      <label>
        {{ template.label }} 
      </label>
      <label>
        {{ data.valueObj.value ?  Sh.round(data.valueObj.value) : "" }}
      </label>

  </div> -->
  <div v-if="template.itemType ===Item_type.FORM_TEXT">
    <label>
        {{ template.label }} {{ template.required ? '*' : "" }}
    </label>
    <textarea 
        :disabled="!canEdit"
        v-model="data.valueObj.value"  
        class="w-100" 
        :class="[hasError ? 'input-with-error' : '']">
    </textarea> 
  </div>

  <div class="checkbox" v-if="template.itemType ===Item_type.FORM_YES_NO">
    <label :for="template.label">
        {{ template.label }} {{ template.required ? '*' : "" }}
    </label>
    <br>
    <label :for="template.label">
      <input
        type="radio"
        v-model="data.valueObj.value"
        :value="true"
        :name="data.formItemId"
      >
      Sí
    </label>
    <label :for="template.label">
      <input
        type="radio"
        v-model="data.valueObj.value"
        :value="false"
        :name="data.formItemId"
      >
      No 
    </label>
  </div>

  <div v-if="template.itemType ===Item_type.FORM_COMBOBOX">
      <label>
        {{ template.label }} {{ template.required ? '*' : "" }}
      </label>
      <select :disabled="!canEdit" v-model="selectedIntValue" :class="[hasError ? 'input-with-error' : '']" @change="onSelectChange">
        <option v-for="(item,index) in options" 
          :key="index" 
          :value="index">
            {{ item.title }}
        </option>
      </select>
  </div>

  <div v-if="template.itemType ===Item_type.FORM_COMBOBOX_MULTIPLE">
      <label>
        {{ template.label }} {{ template.required ? '*' : "" }}
      </label>
      <select multiple :disabled="!canEdit" v-model="selectedValues" :class="[hasError ? 'input-with-error' : '']" @change="onSelectChange">
        <option v-for="(item,index) in options" 
          :key="index" 
          :value="index">
            {{ item.title }}
        </option>
      </select>
  </div>

  <div v-if="template.itemType ===Item_type.FORM_DATE">
    <label>
      {{ template.label }} {{ template.required ? '*' : "" }}
    </label>
      <input  
        :disabled="!canEdit"
        type="date" 
        v-model="data.valueObj.value" 
        :class="[hasError ? 'input-with-error' : '']">
  </div>

  <div class="startToFinish" v-if="template.itemType ===Item_type.FORM_DATE_RANGE">
    <label>
      {{ template.label }} {{ template.required ? '*' : "" }}
    </label>
    <div>
      <small>
        Inicio
      </small>
      <input  
        :disabled="!canEdit"
        type="date" 
        v-model="data.valueObj.init" 
        :class="[hasError ? 'input-with-error' : '']">
    </div>
    <div>
      <small>
        Fin
      </small>
      <input  
        type="date" 
        :disabled="!canEdit"
        v-model="data.valueObj.end" 
        :class="[hasError ? 'input-with-error' : '']">
    </div>
  </div>

  <div v-if="template.itemType ===Item_type.FROM_IMAGE">

    <label>
      {{ template.label }} {{ template.required ? '*' : "" }}
    </label>

    <input :disabled="!canEdit" type="file" ref="inputFile" v-bind:hidden="(!!data.valueObj.id && data.valueObj.id.trim().length!==0)" @change="changeFile($event)">

    <template v-if="!!data.valueObj.url && data.valueObj.url.trim().length !==0">
      <a :href="data.valueObj.url"  target="_blank">
          {{data.valueObj.originalName ? data.valueObj.originalName : "Fichero adjunto" }}
      </a> 
      <i v-if="canEdit" class="bi bi-trash3 cp" @click="() => dialogShow = true"></i>

      <DPDialog :on-close="() => dialogShow = false" :show="dialogShow"
              :on-confirm="() => onTrash()">
          ¿Seguro que quiere eliminar el archivo {{data.valueObj.originalName ? data.valueObj.originalName : "Fichero adjunto" }}?
      </DPDialog>
    </template>

  </div>

</template>

<style scope>
.input-with-error{
  border: 1px solid red;
}
.small-error{
  color: red;
}
.small-warning{
  color:orange
}
select,
textarea,
input {
  position: relative;
  border-radius: 5px;
  border: 1px solid #81b4ab;
  width: 100%;
  margin-bottom: 25px;
  padding: 6px 15px;

}

.contentForm .row.itemForm {
  padding: 10px 0px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.cp.bi-trash3 {
  color: #ff0000;
  font-size: 18px;
}

.cp{
  cursor: pointer;
  font-size: 18px;
}
.formInput{
  margin-bottom: 10px;
}

.itemForm{
  margin-bottom: 20px;
  box-shadow: 0px 0px 15px #0000002b;
}
.itemForm h5{
  display: inline-block;
  color: #005060;
  
  font-weight: 200;
  width: 80%;
}
.itemVarNameId{
  float:right;
}
.panelItemLeft{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.description-input {
  margin-bottom: -3em;
}

.description-trash {
  
  margin-bottom: 2em;
}

.description-component {
  margin-top: 2em;
  color: #005060;
}

.form-control {
  margin-top: 1em;
  border: 0px;
  border-left: 1px solid #81b4ab;
  border-width: 2px;
  margin-left: 5px;
  width: 98%;
}

.description-item {
  margin-bottom: 1em;
  width: 4em;
  margin-right: -1em;
}
.w100{
  width: 100%;
}
.fr{
  float: right
}
</style>
<script lang="ts">
export default {
  name: 'formItemToComplete'
}
</script>
