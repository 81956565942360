<script setup lang="ts">

  import SurveyPresentationPanel from '@/views/components/survey/surveyPresentationPanel.vue';
  import { DisplayType } from '@/core/store/survey/surveyDesignDtos';
  import Survey from '@/core/store/survey/surveyStore';
import { SharedService } from '@/core/sharedService';
import { LocalStorageService } from '@/core/localStorage.service';

  const {loadDesign, setDesignMode, currentDesign, isDesign, addDesign} = Survey();
    let lastDesign = LocalStorageService.getValue("lastDesign");
    if(!lastDesign) {
      addDesign();
      const lastDesing = {
      id: currentDesign.value.cab.id,
      isNew: true,
    }
      LocalStorageService.setValue("lastDesign", lastDesing);
      lastDesign = LocalStorageService.getValue("lastDesign");
    }
    if(!lastDesign.isNew) loadDesign(lastDesign.id);
  
  setDesignMode(true);

  const createDesign = () => {
    addDesign();
    const lastDesing = {
      id: currentDesign.value.cab.id,
      isNew: true,
    }
    LocalStorageService.setValue("lastDesign", lastDesing);
  }
</script>


<template>
  <div>
    <button v-on:click="createDesign">Nuevo diseño</button>
  <input type="checkbox" :checked="isDesign" v-on:change="
                  ($event: Event) => { 
                     setDesignMode((($event.target) as HTMLInputElement).checked)}" style="display: inline-block"><span>Modo diseño</span> 
                     </div>
  <!-- <surveyPresentationPanel :mode="'input'"/> -->
</template>
