<template>
  <DPDialog :show="show" :closeLostFocus="true" :onClose="closeDialog">
    <div class="selectValuesContainer">
      <!-- HEADER -->
      <div class="selectValuesModalHeaderContainer">
        <h3 class="modalFormulaTitle">Valores Personalizados</h3>
        <button class="close-button" @click="closeDialog">
          <span class="x-mark">&#10005;</span>
        </button>
      </div>

      <!-- LISTA DE VARIABLES PERSONALIZADAS EN UNA TABLA -->
      <div v-if="apiVariables" class="variables-container">
        <div class="variable-table">
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(variable, index) in apiVariables.custom" :key="variable.var" class="variable-item">
                <td class="nameColumn">{{ variable.var }}</td>
                <td class="descriptionColumn">{{ variable.description }}</td>
                <td>
                  <input v-model="variable.value" type="text" class="variable-input" @input="checkCompletion" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- BOTÓN DE CONFIRMACIÓN -->
      <button class="confirm-button" @click="confirmVariables" :disabled="!allVariablesCompleted">
        Confirmar Valores
      </button>
    </div>
  </DPDialog>
</template>

<script setup lang="ts">
import { ref, toRef, watch } from 'vue';
import DPDialog from '@/views/components/dialog.vue';
import Survey from '@/core/store/survey/surveyStore';
import { Sh } from '@/core/sharedService';
import { SurveyApiParms } from '@/core/store/survey/surveyApiDtos';

const { getApi, getVarsApi, currentItem } = Survey();

const props = defineProps<{
  show: boolean,
  
}>();

const emit = defineEmits(['close', 'variables-confirmed']);



const apiVariables = ref<SurveyApiParms>(null);
const variableValues = ref([]);  // Para almacenar los valores introducidos por el usuario
const allVariablesCompleted = ref(false);  // Para habilitar o deshabilitar el botón de confirmación

const closeDialog = () => {
  emit('close');
};

// Procesar la API seleccionada y obtener las variables
/* const processApi = async (apiId: string) => {
  if (!apiId) {
    Sh.showToast('No hay una API seleccionada.', 8, true);
    return;
  }

  const api = await getApi(apiId);
  
  if (api && typeof api !== 'boolean') {
    const varsApi = getVarsApi(api); 
    console.log("Variables:", varsApi);
    if (varsApi) {
      apiVariables.value = varsApi; 
      variableValues.value = varsApi.custom.map(() => '');  // Inicializar con strings vacíos
      checkCompletion();  // Actualizar el estado del botón de confirmación
    }
  }
}; */

// Comprobar si todas las variables tienen un valor
const checkCompletion = () => {
  allVariablesCompleted.value = variableValues.value.every(value => value.trim() !== '');
};

// Confirmar los valores seleccionados
const confirmVariables = () => {
  emit('variables-confirmed', variableValues.value);  // Emitir los valores introducidos
  currentItem.value.content = JSON.stringify(apiVariables.value);
  closeDialog();
};

// Verificar si el ID de la API cambia para actualizar las variables

watch(() => props.show, (newVal) => {
  if(newVal){
    apiVariables.value = JSON.parse(currentItem.value.content);
  }
})

</script>

<style scoped lang="scss">
@import url(./survey.scss);

.selectValuesContainer {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 95vi;
  padding: 20px;
  background-color: var(--lightBlue-bg);
  border-radius: 10px;
}

.selectValuesModalHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalFormulaTitle {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color);
}

.variables-container {
  background-color: var(--secondary-color);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.variable-table {
  width: 100%;
  border-collapse: collapse;
}

.nameColumn {
  width: 10%;
}

.descriptionColumn {
  width: 50%;
}

th {
  color: var(--compositor-text);
  font-weight: 600;
  font-size: 18px;
}


.variable-table th, .variable-table td {
  padding: 10px 15px; 
  text-align: center;
  border-bottom: 4px solid var(--secondary-color) !important;
  border-top: 4px solid var(--secondary-color) !important;
  border-collapse: collapse;
}

.variable-table tr {
  border-radius: 999px !important;
}

.variable-input {
  width: 100%;
  text-align: right;
  padding: 4px 10px;
  border-radius: 999px;
  border: 1px solid var(--compositor-text);
  color: var(--compositor-text);
}

.variable-input:focus {
  border: 2px solid var(--compositor-text) !important;
}

.confirm-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 99px;
  align-self: flex-end;
}

.confirm-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
