<script setup lang="ts">
  import useUsers from '@/core/store/user/useUsers';
  import { ref } from 'vue';
  const {error, loged, login, user} = useUsers();
  const email = ref('');
  const password = ref('');
  const loader = ref(false);  

  const log_in = () => {
    login({email: email.value, password: password.value});
    loader.value = true;
    error.value = '';

    login({ email: email.value, password: password.value })
      .then(() => {
        loader.value = false;
      })
      .catch(err => {
        loader.value = false;
      });
    
  };
  localStorage.clear();

</script>

<template>
  <!--   FORMULARIO LOGIN-->
  <div class="content-login">
    <div class="container-login">

      <img class="logo" src="@/assets/loopo-logo.png" alt="logo">
      <div class="login">
        <div v-if="error!==''" class="alert alert-danger" role="alert">
          {{error}}
        </div>
        <div v-if="loader" class="loader"></div>
        <input type="text" placeholder="Usuario" v-model="email"> <br>
        <input type="password" placeholder="Contraseña" v-model="password" class="password-input">
        <router-link to="/recovery">¿Has olvidado la contraseña?</router-link>
        <button class="btn-orange" type="submit" @click="log_in()">Iniciar sesión</button>
      </div>
    </div>
  </div>
  
</template>

<style scoped src="./login-styles.css">
/* FORMULARIO LOGIN */
</style>