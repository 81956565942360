import { default } from './surveyItem.vue';
<script setup lang="ts">
  import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
  import Survey from '@/core/store/survey/surveyStore';
import { computed, CSSProperties, PropType, ref, watch } from 'vue';
import DpWysywig from '../../dp/dpWysywig.vue';
   const props  = defineProps({
    item: {
      type: Object as () => FormDesignItemDto,
      required: true
    },
/*     response: {
      type: Object as () => SurveyResponseItemDto,
      default: null,
      required: false
    }, */
    onChange: {
      type: Function as PropType<( value: any) => void>,
      default: null,
      required: false,
    },
  });
  const currentStyles = computed<PropertyDisplayType>(()=>{
      switch(displayMode.value){
        case DisplayType.DISPLAY: 
          return  props.item.properties.screen;
        case DisplayType.EDIT: 
          return  props.item.properties.input;
        case DisplayType.PDF: 
          return  props.item.properties.print;
      }
  })
  const {
      displayMode, 
      selectedItemId,
      isDesign,
      } = Survey();

const value = ref<string>(props.item.content);
const isEditing = ref<boolean>(false);
watch(value, (newValue) => {
      props.item.content = newValue;
      props.onChange(newValue);
})
  

const isCurrentVisible = computed(() => {
  const displayProp: PropertyDisplayType=  currentStyles.value;
  return displayProp.visible || isDesign;
});

const isSelected = computed<boolean>(() => {
  const isSel = selectedItemId.value == props.item.id;
  if(!isSel) isEditing.value = false;
  return isSel;
})
</script>

<template>

  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible">
    <div v-show="!isEditing" v-html="item.content && item.content.trim() ? item.content : 'Pulse aquí para introducir un texto'" v-on:click="() => {if(isDesign) isEditing = true}">
    </div>
    <div  v-show="isDesign && isEditing && isSelected" v-on:click="() => isEditing = false">
      <DpWysywig  :html="item.content ?? ''" :onChange="(val) => value = val" v-on:blur="() => isEditing = false"/>
    </div>
  </div>  
  <div v-if="displayMode == DisplayType.DISPLAY && isCurrentVisible" v-html="item.content ?? ''" ></div>  
  <div v-if="displayMode == DisplayType.PDF && isCurrentVisible"  v-html="item.content ?? ''" ></div>  
  
</template>

<style lang="scss">
  @import url(../survey.scss);
</style>

<script lang="ts">
  export default {
    name: "SurveyItemFreeText"
  }
</script>