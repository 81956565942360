<template>
  <div class="surveyBTNContainer">
    <a href="#" v-if="isNotDesign" class="pdf-button" @click="handlePdfClick" style="vertical-align: middle;">
      <i class="bi bi-filetype-pdf pdf-icon"></i>
    </a>
    <div class="surveyDesignBTNContainer" v-if="['design', 'input'].includes(mode)">
      <button class="btn-orange" v-on:click="save()">Guardar</button>
      <div class="space10px"></div>
      <div class="dropdown" v-if="props.mode == 'design'">
        <button class="round-button" @click="toggleDropdown">
          <img :src="gearIcon" alt="Gear Icon" class="gear-icon" />
        </button>
        <div v-if="isDropdownOpen" class="dropdown-mode-menu">
          <a :href="i.hRef ? i.hRef : '#'" :target="i.hRef ? '_blank' : undefined" v-for="(i, index) in menuOptions"
            :key="index" v-on:click="() => i.action()" :class="{ 'active-option': i.type === curAction }">{{ i.title
            }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="survey-container">
    <div class="statusLabelContainer" v-if="['input', 'display'].includes(mode)">
      <div>
        <span class="statusLabel"><span>Entidad:</span> {{ entityName }}, <span>Formulario:</span> {{ formName }}</span>
      </div>
      <div>
        <span class="statusLabel"><span>Estado</span>: Completadas <span>{{ responseStatus.response }}</span> de
          <span>{{
            responseStatus.requireds }}</span> requeridas.</span>
      </div>
    </div>

    <div class="survey-content-container">
      <div class="buttonsBar" v-if="props.mode == 'design' && selectedType != SelectedItemType.SURVEY && isDesign">
        <SurveyButtons />
      </div>
      <div class="survey-content"
        v-if="(selectedType != SelectedItemType.SURVEY || currentDesign.cab.formType == Form_type.COMPONENT)">
        <div class="survey-headerFooter" v-if="props.mode !== 'input'">
          <SurveyHeaderFooter :type="'header'" v-if="currentDesign.cab.formType == Form_type.FROM" />
        </div>
        <div class="survey-content-body">
          <div class="bodyTagAndCollapsContainer">
            <span v-if="displayMode === DisplayType.EDIT" class="tittle-componentBody2"
              v-html="isDesign ? 'Cuerpo' : ''">

            </span>
            <div v-if="displayMode === DisplayType.DISPLAY" class="toPositionObjectBox"></div>
            <div v-if="displayMode != DisplayType.PDF"
              :class="['collapseBTNContainer', { 'collapseBTNContainer-inputMode': props.mode === 'input' }]">
              <button class="collapse-button" v-on:click="toggleDeploy" :class="{ active: isActive }">
                <img :src="bannerIcon" alt="banner Icon" class="bannerIcon" />
              </button>
            </div>
          </div>
          <div v-if="isDesign && displayMode === DisplayType.EDIT" class="h10px">
          </div>
          <SurveyContent />
          <div class="statusBTNContainer">
            <div v-if="isNotDesign && canClose" class="closeFormBTNContainer">
              <button class="status-BTN" v-on:click="() => close()"> <i class="bi bi-send-plus"></i> Cerrar y
                Enviar</button>
            </div>
            <div v-if="isNotDesign && canAcept" class="closeFormBTNContainer">
              <button class="status-BTN validateBTN" v-on:click="() => validate()"> <i class="bi bi-check2-all"></i>
                Validar </button>
            </div>
            <div v-if="isNotDesign && canAcept" class="closeFormBTNContainer">
              <button class="status-BTN noValidateBTN" v-on:click="() => reopen()"> <i class="bi bi-backspace fill"></i>
                Devolver a borrador</button>
            </div>
          </div>
        </div>
        <div class="survey-headerFooter" v-if="props.mode !== 'input'">
          <SurveyHeaderFooter :type="'footer'" v-if="currentDesign.cab.formType == Form_type.FROM" />
        </div>
      </div>
      <div class="survey-content"
        v-if="(selectedType == SelectedItemType.SURVEY && currentDesign.cab.formType == Form_type.FROM)">

        <DpWysywig :html="currentDesign.cab.config.firstPage" :onChange="changeFirstPage" :style="surveyStyles.page" />

      </div>
      <div class="survey-propertiesBar"
        v-if="props.mode == 'design' && SelectedItemType && selectedType && ![SelectedItemType.HEADER, SelectedItemType.FOOTER].includes(selectedType) && isDesign">
        <SurveyProperties />
      </div>
    </div>
  </div>
  <SurveySelectorOrigein v-if="isDesign" />


</template>

<script lang="ts">
export default {
  name: "SurveyPresentationPanel"
}
</script>

<script setup lang="ts">

import Survey from '@/core/store/survey/surveyStore';
import SurveyHeaderFooter from './surveyHeaderFooter.vue';
import SurveyContent from './surveyContent.vue';
import SurveyButtons from './surveyButtons.vue';
import SurveyProperties from './surveyProperties.vue';
import { DisplayType, SelectedItemType } from '@/core/store/survey/surveyDesignDtos';
import DpWysywig from '../dp/dpWysywig.vue';
import { surveyStyles } from './surveyCss';
import SurveySelectorOrigein from './surveySelectorOrigin.vue';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';
import gearIcon from '/assets/gear-icon.svg';
import bannerIcon from '/assets/bannerIcon.svg';
import { Form_status, Form_type } from '@/core/store/forms/dtos';
import 'vue-pdf-embed/dist/styles/annotationLayer.css';
import 'vue-pdf-embed/dist/styles/textLayer.css';
import environment from '@/environments/environments';
import { Sh } from '@/core/sharedService';
import { roles } from '@/shared/globaEnums';
import { GlobalState } from '@/core/store/global/gobalState';
import { _UnwrapAll, storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

interface ConfigOptions {
  title: string,
  action: () => void,
  hRef?: string,
  type: ConfigActions
}

enum ConfigActions {
  CONFIG,
  NONE,
  EDIT = "EDIT",
  DISPLAY = "DISPLAY",
  PDF = "PDF",
}

const { currentDesign,
  setMode,
  saveCab,
  selectedType,
  currentResponse,
  setSelectedType,
  bindCab,
  deployAllSections,
  isDesign,
  displayMode,
  saveResponse,
  init,
  responseStatus,

  changeState

} = Survey();

init();


const deployAll = (dep: boolean) => {
  deployAllSections(dep)
};
const props = defineProps({
  mode: {
    type: String as () => 'design' | 'input' | 'display' | 'pdf',
    required: true,
  },
});


const changeFirstPage = (newValue: string) => {
  bindCab("config", { ...currentDesign.value.cab.config, firstPage: newValue })
}

const { currentContext } = storeToRefs(GlobalState());
const entityName = currentContext.value.entity.name;
const formName = currentDesign.value.cab.name;
const isPressed = ref(false);
const isActive = ref(false);
const isDropdownOpen = ref(false);
const curDisplayMode = ref<DisplayType>(displayMode.value);
const curIsDesign = ref<boolean>(isDesign.value);
const curAction = ref<ConfigActions>(ConfigActions.NONE);
const isNotDesign = computed<boolean>(() => currentResponse.value && !!currentResponse.value.entityId);
const router = useRouter();

const currentUrlPrint = computed(() => {
  let url = environment.VITE_API_URL;
  if (currentResponse.value && currentResponse.value.entityId) {
    url += `/survey/response/print/${currentDesign.value.cab.id}/${currentResponse.value.entityId}`;
  } else {
    url += `/survey/design/print/${currentDesign.value.cab.id}`;
  }
  return url;
})

const handlePdfClick = async (event: Event) => {
  event.preventDefault();
  try {
    await saveResponse();
    window.open(currentUrlPrint.value, '_blank');
  } catch (error) {
    console.error('Error al guardar o abrir PDF:', error);
    Sh.showGenericDialog({
          title: "Error al generar el reporte PDF",
          message: "Se ha producido un error al generar el reporte PDF. Por favor, intente nuevamente o contacte al administrador de la plataforma.",
          confirmOnly: true,
          confirmText: "Cerrar"
        })
  }
};

const close = () => {
  currentResponse.value.state = Form_status.CLOSED;
  saveResponse();
  setTimeout(() => {
    Sh.showToast("Formulario cerrado correctamente");
    router.push('/forms/listAssigneds/complete')
  }, 800);
  //changeState(Form_status.CLOSED);

}
const validate = () => {
  currentResponse.value.state = Form_status.VALIDATED;
  saveResponse();
  //changeState(Form_status.VALIDATED);
}
const reopen = () => {
  currentResponse.value.state = Form_status.DRAFT;
  saveResponse();
  //changeState(Form_status.DRAFT);

}

const canClose = computed<boolean>(() => {
  if (!currentResponse || !currentResponse.value || !currentResponse.value.items || currentResponse.value.items.length == 0) return false;
  if (![Form_status.DRAFT, Form_status.ASSIGN].includes(currentResponse.value.state)) return false;
  if (Sh.user.role == roles.ENTITY && responseStatus.value.requireds == responseStatus.value.response && [Form_status.DRAFT, Form_status.ASSIGN].includes(currentResponse.value.state)) return true;
  return false;
});

const canAcept = computed<boolean>(() => {
  if (!currentResponse || !currentResponse.value || !currentResponse.value.items || currentResponse.value.items.length == 0) return false;
  if (Sh.user.role == roles.CLIENT && [Form_status.CLOSED].includes(currentResponse.value.state)) return true;
  return false;
});

watch(curAction, (newValue, olderValue) => {


  if (newValue == ConfigActions.CONFIG) {
    setSelectedType(SelectedItemType.SURVEY);
    isDesign.value = curIsDesign.value;;
    isDropdownOpen.value = false;
    return;
  }
  if (olderValue == ConfigActions.CONFIG) {
    setSelectedType(SelectedItemType.NONE);
  }
  //if(selectedType.value == SelectedItemType.SURVEY ) setSelectedType(SelectedItemType.NONE);

  if (newValue == ConfigActions.NONE) {
    displayMode.value = curDisplayMode.value;
    isDesign.value = props.mode == 'design';
  } else {
    curIsDesign.value = isDesign.value;
    displayMode.value = newValue == ConfigActions.DISPLAY ? DisplayType.DISPLAY : (newValue == ConfigActions.EDIT ? DisplayType.EDIT : DisplayType.PDF);
    isDesign.value = false;
    if (displayMode.value == DisplayType.PDF) {
      deployAll(true);
    }
  }
  isDropdownOpen.value = false;

})

const menuOptions: ComputedRef<ConfigOptions[]> = computed(() => [
  {
    title: 'Propiedades de formulario',
    action: () => curAction.value = curAction.value === ConfigActions.CONFIG ? ConfigActions.NONE : ConfigActions.CONFIG,
    type: ConfigActions.CONFIG
  },
  {
    title: 'Ver previsualización de entrada',
    action: () => curAction.value = curAction.value === ConfigActions.EDIT ? ConfigActions.NONE : ConfigActions.EDIT,
    type: ConfigActions.EDIT
  },
  {
    title: 'Ver previsualización de formulario',
    action: () => curAction.value = curAction.value === ConfigActions.DISPLAY ? ConfigActions.NONE : ConfigActions.DISPLAY,
    type: ConfigActions.DISPLAY
  },
  {
    title: 'Ver previsualización PDF',
    hRef: currentUrlPrint.value,
    action: () => { isDropdownOpen.value = false; },
    type: ConfigActions.PDF
  },
]);

const save = () => {
  if (props.mode == 'design') {
    saveCab();
    return;
  }
  saveResponse();
}


const toggleDropdown = () => {

  isDropdownOpen.value = !isDropdownOpen.value;
};


watch(() => selectedType.value, (newType, oldType) => {
  if (oldType == SelectedItemType.SURVEY) {
    curAction.value = ConfigActions.NONE;
    return;
  }
  isPressed.value = newType === SelectedItemType.SURVEY;
  if (newType == SelectedItemType.SURVEY && isPressed.value) {
    selectedType.value = SelectedItemType.SURVEY;
  }
});


const isDeployed = ref(true);


const toggleDeploy = () => {
  isDeployed.value = !isDeployed.value;
  isActive.value = !isActive.value;
  deployAll(isDeployed.value);

};


</script>
<style scoped lang="scss">
@import './survey.scss';

.survey-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: -56px auto 50px;
  border-radius: 22px;
  max-width: 80%;
  box-shadow: 0px 0px 15px #0000002e;

  @media screen and (max-width: 1900px) {
    max-width: 85%;
  }

  @media screen and (max-width: 1800px) {
    max-width: 90%;
  }

  @media screen and (max-width: 1685px) {
    max-width: 95%;
  }

  @media screen and (max-width: 1600px) {
    max-width: 98%;
  }

}

.statusLabelContainer {
  transform: translateY(70px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statusLabel {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-color);

  span {
    font-weight: 700;
  }
}

.surveyBTNContainer {
  position: absolute;
  top: 180px;
  right: 232px;

  @media screen and (max-width: 1900px) {
    right: 10%;
  }

  @media screen and (max-width: 1800px) {
    right: 6.5%;
  }

  @media screen and (max-width: 1660px) {
    right: 4%;
  }

  .dropdown {
    position: relative;
    display: inline-block;

    .dropdown-mode-menu {
      background-color: var(--compositor-color);
      min-width: 300px;
      border-radius: 5px;
      display: block;
      flex-direction: column;
      position: absolute;
      top: 55px;
      right: 20px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      z-index: 999;


      a {
        color: var(--compositor-text);
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        border-radius: 5px;

        &.active-option {
          background-color: var(--compositor-text);
          color: var(--compositor-color);
          border-radius: 0px;
        }

        &:hover {
          background-color: var(--secondary-color);
          border-radius: 5px;
          color: #FFF;
        }
      }
    }
  }

  .round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: #f0f0f0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s, transform 0.2s;
    cursor: pointer;
    padding: 0;

    &.pressed,
    &.active {
      box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.2), 0 0 10px #005061;
      transform: translateY(2px);
    }

    &:hover .gear-icon {
      animation: spin 3s infinite linear;
    }

    .gear-icon {
      width: 24px;
      height: 24px;
    }

    .pdf-icon {
      width: 30px;
      height: 30px;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pdf-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s, transform 0.2s;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;

  i {
    font-size: 23px;
    transform: translateY(3px);
  }
}

.collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  background-color: var(--compositor-color);
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s ease-in-out;
  cursor: pointer;
  padding: 0;
  margin: 0px 11px -10px 0;
  transform: translateY(-10px);
  border: 2px solid var(--compositor-text);

  &.active {
    transform: translateY(-10px) rotate(180deg);
  }

  .bannerIcon {
    transform: translateY(-2px) rotate(180deg);
    width: 18px;
    height: 18px;
  }
}

.surveyDesignBTNContainer {
  display: flex;
  flex-direction: row;
}

.statusBTNContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.status-BTN {
  background-color: var(--compositor-color);
  color: var(--compositor-text);
  border: 2px solid transparent;
  padding: 2px 20px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  i {
    font-size: 25px;
  }

  &:hover {
    background-color: var(--secondary-color);
  }
}

.noValidateBTN {
  background-color: var(--red);
  color: white;
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    background-color: white;
    color: var(--red);
    border: 2px solid var(--red);
  }
}

.validateBTN {
  background-color: green;
  color: white;
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    background-color: white;
    color: green;
    border: 2px solid green;
  }
}

.toPositionObjectBox {
  // width: 100%;
  height: 10px;
  background-color: #005061;
  margin-bottom: 25px;
}

@media screen and (min-width: 2200px) {
  .surveyBTNContainer {
    position: absolute;
    right: 290px;
  }
}

.closeFormBTNContainer {
  margin: 20px 0;
}
</style>