
<script setup lang="ts">
import Survey from '@/core/store/survey/surveyStore';
  import { computed, PropType, ref } from 'vue';
  import { propertiesConfigurationDisplay, PropertiesDisplayComponents, InputTypeDisplay, FieldType, DisplayType } from '@/core/store/survey/surveyDesignDtos';
  import dpColorPicker from '../dp/dpColorPicker.vue';
  import SurveyItemSelect from './itemsComponents/surveyItemSelect.vue';
  const props = defineProps({

    target: {
      type: String as PropType<DisplayType>,
      required: true
    },
    type: {
      type: String as PropType<"label"|'content'>,  // 
      reuired: true,
    }
  })

  const {currentItem, bindItem, getValueItem, targetToProperty} = Survey();
  const isDialogOpen = ref<boolean>(false)
  const type = computed(() => {
    if(props.type === 'label') return "labelStyle";
    return "contentStyle";
  })


  const hasLabel = computed(() => {
    return PropertiesDisplayComponents[currentItem.value.type].labelStyle!!
  })
  const hasContent= computed(() => {
    return PropertiesDisplayComponents[currentItem.value.type].contentStyle!!
  })
 

  const currentProps = computed(() => {
    if(!currentItem.value) return {};
    if(props.type == 'label' ) return PropertiesDisplayComponents[currentItem.value.type].labelStyle;
    return PropertiesDisplayComponents[currentItem.value.type].contentStyle;
  })
  const currentLabelDisplay = computed(() => {
    if(!currentItem.value) return "";
    if(props.type == 'label'){
      if(!hasContent) return "Configuración";
      return "Etiqueta"
    }else{
      if(!hasLabel) return "Configuración";
      return "Campo de entrada"
    }
  })
  const target = computed(()=> {
    return targetToProperty(props.target);
  })
  const prop = (k: string): InputTypeDisplay => {
    return propertiesConfigurationDisplay[k];
  }
  const isVisible = (k: string): boolean => {
    return !prop(k).editOnlyDesign || prop(k).editOnlyDesign.includes(props.target);
  }
  const getValue = (k: string) => {
    return getValueItem(prop(k).typeBind, props.target, type.value, k);
  }
  const setValue = (k: string, value) => {
    bindItem(prop(k).typeBind, props.target, type.value, k, value);
  }
  const setValueDisplay = (k: string, value) => {
    currentItem.value.properties[target.value][k] = value;
  }
  const openDialog = () => {
    isDialogOpen.value = true;

  }
  const closeDialog = () => {
    isDialogOpen.value = false;
    
  }

</script>

<template>
  <div class="component-Properties-Container" v-if="currentItem">
    <div class="currentItemTitleVisible">
      <div class="survey-property-section-title">{{ currentLabelDisplay }}</div>
<!--       <div>
        <div class="survey-property-section-Visible">
          <label class="checkboxLabel">Visible</label>
          <div class="survey-checkbox-container">
            <input type="checkbox" :checked="currentItem.properties[target].visible" v-on:change="($event: Event) => {
                setValueDisplay('visible', (($event.target) as HTMLInputElement).checked);
              }" class="customCheckbox1">
          </div>
        </div>
      </div> -->
    </div>
    <div class="propertiesItems" v-for="k in Object.keys(currentProps)" :key="k">
      <div class="survey-property-input-group" v-if="isVisible(k) && currentProps[k] == true">

        <div class="survey-property-section-label customCheckboxContainer2">
          <label>{{prop(k).label}}</label>
          <!--<div class="survey-checkbox-container" v-if="prop(k).type =='check'">
            <input type="checkbox" :checked="getValue(k)" v-on:change="
                  ($event: Event) => { 
                    setValue(k, (($event.target) as HTMLInputElement).checked);
                  }" class="customCheckbox2">
          </div>-->
        </div>
        
        <div class="survey-property-section-input">
        
          <template v-if="prop(k).type == 'check'">
            <input type="checkbox" :checked="getValue(k)" v-on:change="
                  ($event: Event) => { 
                    setValue(k, (($event.target) as HTMLInputElement).checked);
                  }" class="customCheckbox2">
          </template>

          <template v-if="prop(k).type =='number'">
            <input type="number" :value="getValue(k)" v-on:change="
                  ($event: Event) => { 
                    setValue(k, (($event.target) as HTMLInputElement).value);
                      }">
            <span>{{prop(k).sufix}}</span>
          </template>
          <template v-if="prop(k).type =='text'">
            <input type="text" :value="getValue(k)" v-on:change="
                  ($event: Event) => { 
                    setValue(k, (($event.target) as HTMLInputElement).value);
                  
                    }">
            <span>{{prop(k).sufix}}</span>
          </template>
          <template v-if="prop(k).type =='date'">
            <input type="date" :value="getValue(k)" v-on:change="
                  ($event: Event) => { 
                    setValue(k, (($event.target) as HTMLInputElement).value);
                      }">
            <span>{{prop(k).sufix}}</span>
          </template>

          <template v-if="prop(k).type =='color'">
            <dpColorPicker :onChange="(color) => setValue(k,  color)" :color="getValue(k)" />
          </template>
          <template v-if="prop(k).type =='select'">
            <select :value="getValue(k)" v-on:change="
                      ($event: Event) => { 
                        setValue(k, (($event.target) as HTMLInputElement).value);
                          }">
              <option v-for="i in prop(k).sufix" :value="i.value" :key="i.value" :selected="getValue(k) == i.value">{{
                i.label }}</option>
            </select>
          </template>
          <template v-if="prop(k).type =='format'">
            <select :value="getValue(k)" v-on:change="
                      ($event: Event) => { 
                        setValue(k, (($event.target) as HTMLInputElement).value);
                         
                          }">
              <option v-for="i in prop(k).sufix[currentItem.type.toString()]" :value="i" :key="i"
                :selected="getValue(k) == i">{{ i }}</option>
            </select>
          </template>
          <template v-if="prop(k).type =='items' && !(currentItem.inheritItemId!=null && currentItem.inheritItemId.trim().length!=0)">
            <button class="nItemsBTN" v-on:click="openDialog"><i class="bi bi-diagram-3"></i> Items ( {{ Array.isArray(getValue(k)) ? getValue(k).length : 0 }} )</button>
            <SurveyItemSelect :show="isDialogOpen" :onClose="closeDialog" />
          </template>

        </div>
      </div>
      <div>
      </div>
    </div>
    <div class="survey-property-section-label customCheckboxContainer3"
      v-if="FieldType.MULTYDROPDOWN == currentItem.type && [DisplayType.PDF, DisplayType.DISPLAY].includes(props.target) && !(currentItem.inheritItemId!=null && currentItem.inheritItemId.trim().length!=0)">
      <label class="single-line-text onlyCheckLabel">Solo marcados</label>
      <div class="survey-checkbox-container">
        <input type="checkbox" :checked="currentItem.properties[target].itemOnlySelected" v-on:change="
                  ($event: Event) => { 
                    setValueDisplay('itemOnlySelected', (($event.target) as HTMLInputElement).checked);
                    }" class="customCheckbox3">
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import url('./survey.scss');
</style>


<script lang="ts">
  export default {
    name: "SurveyPropertiesItems"
  }
</script>