export const optionsTable = {
  "language": {
    "search": '<img src="/assets/search.svg" alt="loupe_icon">',
    "searchPlaceholder": "Buscar",
    "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
    "paginate": {
      "first": "Primero",
      "last": "Último",
      "next": '<img src="/assets/next.svg" alt="next_icon">',
      "previous": '<img src="/assets/previus.svg" alt="previus_icon">',
    },
    //"url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
  }
}