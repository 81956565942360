import { default } from './surveyItem.vue';
<script setup lang="ts">
  import { Sh } from '@/core/sharedService';
import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
  import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import { computed, CSSProperties, PropType, ref, watch, onMounted, nextTick } from 'vue';
  const {
      displayMode, 
      isDesign,
      } = Survey();

   const props  = defineProps({
    item: {
      type: Object as () => FormDesignItemDto,
      required: true
    },
    response: {
      type: Object as () => SurveyResponseItemDto,
      default: null,
      required: false
    },
    onChange: {
      type: Function as PropType<( value: any) => void>,
      default: null,
      required: false,
    },
  });

  const currentStyles = computed<PropertyDisplayType>(()=>{
      switch(displayMode.value){
        case DisplayType.DISPLAY: 
          return  props.item.properties.screen;
        case DisplayType.EDIT: 
          return  props.item.properties.input;
        case DisplayType.PDF: 
          return  props.item.properties.print;
      }
  });

  const cssInput = computed<CSSProperties>(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return {
      backgroundColor:  displayProp.contentStyle.backgroundColor,
      borderRadius: isMultiline.value ? '5px' : '9999px',
      border: 'none',
      fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
      color: displayProp.contentStyle.color,
      width: isDesign.value && displayMode.value === DisplayType.EDIT ? '344px' : '100%',
      minHeight: `${lineHeight}px`,
      overflow: 'hidden',
      resize: 'none',
      padding: '2px 12px',
      lineHeight: `${lineHeight}px`,
    }
  });

  watch(() => props.response?.value, (newValue) => {
    if (newValue !== undefined) {
      value.value = newValue;
      nextTick(() => {
        autoResize();
      });
    }
  })

  const value = ref<string>(props.response && props.response.value ? props.response.value : '');

  
  watch(value, (newValue) => {
    if(props.response){
      if(newValue !== props.response.value){
        props.response.value = newValue;
        props.onChange(newValue);
      }
    }
  });
  watch(displayMode , (newState) => {
    if(newState == DisplayType.EDIT){
      console.log("textareaREf", textareaRef)
      nextTick(() => {
        autoResize();
      })
      
    }
  });
  const isCurrentVisible = computed(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return displayProp.visible || isDesign;
  });

  const lineHeight = 30;
  const isMultiline = ref(false);

  const textareaRef = ref(null);
  const autoResize = () => {
    const textarea = textareaRef.value;
    if (textarea) {
      textarea.style.height = `${lineHeight}px`;
      
      const numLines = Math.ceil((textarea.scrollHeight - parseInt(getComputedStyle(textarea).paddingTop) - parseInt(getComputedStyle(textarea).paddingBottom)) / lineHeight);
      
      if (numLines > 1) {
        isMultiline.value = true;
        textarea.style.height = `${numLines * lineHeight}px`;
      } else {
        isMultiline.value = false;
        textarea.style.height = `${lineHeight}px`;
      }
    }
  };


  onMounted(() => {
    nextTick(() => {
      autoResize();
    });
  });

  watch(value, () => {
    nextTick(() => {
      autoResize();
    });
  });

  watch(() => props.response?.value, (newValue) => {
      if (newValue !== undefined) {
        value.value = newValue;
        nextTick(() => {
          autoResize();
        });
      }
  });

</script>
<template>
  <div >
    <textarea v-model="value" @input="autoResize" ref="textareaRef" class="survey-item-input survey-input-number-buttons-none" :readonly="displayMode!=DisplayType.EDIT || (item.inheritItemId!=null && item.inheritItemId.trim().length!=0)" :tabindex="displayMode!=DisplayType.EDIT ? -1 : 1" :style="cssInput"></textarea>
  </div>  
  <!-- <div v-if="displayMode == DisplayType.PDF  && isCurrentVisible" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" v-html="value.replace(/\n/g, '<br>')">
  </div>   -->
</template>

<style lang="scss">
  @import url(../survey.scss);
</style>

<script lang="ts">
  export default {
    name: "SurveyItemText"
  }
</script>