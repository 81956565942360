import { default } from './surveyItem.vue';
<script setup lang="ts">
  import { Sh } from '@/core/sharedService';
import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
  import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import { computed, CSSProperties, PropType, ref, watch } from 'vue';
  const {
      displayMode, 
      isDesign,
      } = Survey();

   const props  = defineProps({
    item: {
      type: Object as () => FormDesignItemDto,
      required: true
    },
    response: {
      type: Object as () => SurveyResponseItemDto,
      default: null,
      required: false
    },
    onChange: {
      type: Function as PropType<( value: any) => void>,
      default: null,
      required: false,
    },
  });

  const currentStyles = computed<PropertyDisplayType>(()=>{
      switch(displayMode.value){
        case DisplayType.DISPLAY: 
          return  props.item.properties.screen;
        case DisplayType.EDIT: 
          return  props.item.properties.input;
        case DisplayType.PDF: 
          return  props.item.properties.print;
      }
  });

  const cssInput = computed<CSSProperties>(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return {
      backgroundColor:  displayProp.contentStyle.backgroundColor,
      borderRadius: '9999px',
      border: 'none',
      fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
      color: displayProp.contentStyle.color,
      width: '100%'
    }
  });

  const value = ref<boolean>(props.response && ![undefined, null].includes(props.response.value) ? props.response.value : false);
  
  watch(value, (newValue) => {
    if(props.response){
      if(newValue !==props.response.value){
        props.response.value = newValue;
        props.onChange(newValue);
      }
    }
  });

  const isCurrentVisible = computed(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return displayProp.visible || isDesign;
  });
  
</script>
<template>
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible" style="display: flex;">
    <label style="display: inline-flex; align-items: center; min-width: 50px; margin-right: 40px;">
      <input type="radio" v-model="value" :value="true" style="max-width: 15px; margin-right: 10px;" :readonly="item.inheritItemId!=null && item.inheritItemId.trim().length!=0"> Sí
    </label>
    <label style="display: inline-flex; align-items: center; min-width: 50px;">
      <input type="radio" v-model="value" :value="false" style="max-width: 15px; margin-right: 10px;" :readonly="item.inheritItemId!=null && item.inheritItemId.trim().length!=0"> No
    </label>
  </div>
  <div v-if="displayMode == DisplayType.DISPLAY  && isCurrentVisible" >
    <label style="display: inline-flex; align-items: normal; min-width: 50px;">
      <input type="radio" v-model="value" :value="true" readonly tabindex="-1" style="max-width: 15px;margin-right: 10px;"> Sí
    </label>
    <label style="display: inline-flex; align-items: normal; min-width: 50px;">
      <input type="radio" v-model="value" :value="false" readonly tabindex="-1" style="max-width: 15px;margin-right: 10px;"> No
    </label>
  </div>  
  <div v-if="displayMode == DisplayType.PDF  && isCurrentVisible">
    <label style="display: inline-flex; align-items: normal; min-width: 50px;">
      <input type="radio" v-model="value" :value="true" readonly tabindex="-1" style="max-width: 15px;margin-right: 10px;"> Sí
    </label>
    <label style="display: inline-flex; align-items: normal; min-width: 50px;">
      <input type="radio" v-model="value" :value="false" readonly tabindex="-1" style="max-width: 15px;margin-right: 10px;"> No
    </label>

  </div>  
</template>

<style lang="scss">
  @import url(../survey.scss);

  input[type="radio"] {

    accent-color: var(--compositor-text);
    
  }

</style>

<script lang="ts">
  export default {
    name: "SurveyItemYesNo"
  }
</script>