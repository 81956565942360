import { defineStore } from 'pinia';
import { ref } from 'vue';
import { FormCabPostDto, FormCreatedItemDto, FormDto, FormItemDto, Metrics } from './dtos';



export const FormState = defineStore('form', () =>{
  const currentForm = ref<FormDto>(new FormDto());
  const currentFormItem = ref<FormCreatedItemDto>(new FormCreatedItemDto())
  const forms = ref<FormCreatedItemDto[]>([]);
  const selecteItem = ref<FormItemDto|null>(null)
  const isEditing = ref<boolean>(false);
  const metrics = ref<Metrics[]>([]);
  const selectedEntitiesToForm= ref<string[]>([]);
  return {
    // Properties
    currentForm,
    forms,
    isEditing,
    currentFormItem,
    selectedEntitiesToForm,
    metrics,
    
    
      // Actions
    reset() {
      currentForm.value = new FormDto();
      forms.value = [];
      currentFormItem.value = new FormCreatedItemDto();
      isEditing.value = false;
      selecteItem.value = null;
      selectedEntitiesToForm.value = [];
    },
    newForm() {
      currentForm.value = new FormDto();
    },
    setForm(form: FormDto){
      currentForm.value=form;
    },
    setForms(frms: FormCreatedItemDto[]){
      forms.value = frms;
      
    },
    setCurrentFormItem(frm: FormCreatedItemDto){
      currentFormItem.value = frm;
    },
    setCabForm(cab: FormCabPostDto){
      currentForm.value.cab = cab;
    },
    addItem(item: FormItemDto){
      item.order = currentForm.value.items.length + 1;
      delete item.id;
      delete item.formId;
      currentForm.value.cab.fieldCounter+= 1;
      item.varNameId = "c" + currentForm.value.cab.fieldCounter;
      currentForm.value.items.push(item);
    },
    setItems(items: FormItemDto[]){
      currentForm.value.items = <FormItemDto[]>items;
    },
    selectItem (item: number|FormItemDto) {
      if(typeof item === 'number'){
        item = currentForm.value.items.find(i => i.order === item);
        if(!item) return;
      }
      selecteItem.value = item;
    },
    resetCurrent() {
      currentForm.value = new FormDto();
      selecteItem.value = null;
    },
    setSelectedEntitiesToForm(items: string[]){
      selectedEntitiesToForm.value = items;
    },
    setMetrics(mtrs: Metrics[]){
      metrics.value = mtrs;
    }


  }

}, {persist: true});